import styled from "styled-components";
import {Link} from "react-router-dom";
import {colors} from "./colors";

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;
export const FlexColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const FlexColumnBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;
export const FlexColumnStart = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
`;
export const FlexRowBetween = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;
export const FlexRowResponsive = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const FlexRowBetweenResponsive = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`;

interface StyledButtonProps {
    variant?: 'primary' | 'text';
    noRadius?: boolean
}

export const StyledButton = styled.button<StyledButtonProps>`
  border-radius: ${({noRadius}) => noRadius ? "var(--8, 0.5rem)" : "4.5rem"};
  border-style: none;
  cursor: pointer;
  background-color: ${props => props.variant === 'primary'
          ? colors.primary
          : 'transparent'};
  padding: ${props => props.variant === 'primary' && `0.71875rem 2rem`};
  color: ${props => props.variant === 'primary' ? colors.white : colors.primary};
  box-shadow: ${props => props.variant === 'primary' && `0px 0.25rem 0.375rem 0px rgba(0, 0, 0, 0.06)`};
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1rem;

  :hover {
    opacity: 0.8;
    transition: all 0.3s ease;
  }

  :disabled {
    background-color: ${colors.lightGray};
    color: ${colors.white};
  }
`;

export const StyledButtonGray = styled(StyledButton)`
  border-radius: var(--8, 0.5rem);
  border-style: none;
  cursor: pointer;
  background-color: ${colors.pageBg};
  padding: ${props => props.variant === 'primary' && `0.71875rem 2rem`};
  color: ${colors.secondary};
  box-shadow: ${props => props.variant === 'primary' && `0px 0.25rem 0.375rem 0px rgba(0, 0, 0, 0.06)`};
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1rem;

  :hover {
    opacity: 0.8;
    transition: all 0.3s ease;
  }

  :disabled {
    background-color: ${colors.lightGray};
    color: ${colors.white};
  }
`;


export const StyledRegisterButton = styled(StyledButton)`
  width: 300px;
  margin-top: 10px;
  border-radius: 8px;
  height: 50px;
`;

StyledButton.defaultProps = {
    variant: 'primary'
};

StyledRegisterButton.defaultProps = {
    variant: 'primary'
};

export const StyledLink = styled(Link)`
  color: ${colors.primary};
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.2rem;
  font-style: normal;

  :hover {
    opacity: 0.8;
    transition: all 0.3s ease;
  }
`;

export const LanguageSwitch = styled.span<{ active?: boolean }>`
  cursor: pointer;
  font-size: 1rem;
  line-height: 1rem;
  text-transform: capitalize;
  color: ${props => props.active ? colors.secondary : colors.lightGray};

  :hover {
    color: ${props => props.active === false && colors.secondary};
    transition: all 0.3s ease;
  }
`;

LanguageSwitch.defaultProps = {
    active: false
};

export const StyledButtonTransparent = styled(StyledButton)<{ mini?: boolean }>`
  background: none;
  border-radius: 10px;
  padding: ${props => props.mini ? "10px 14px" : "15px"};
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 8px;
  font-size: ${props => props.mini ? "13px" : "16px"};
  font-weight: ${props => props.mini ? "400" : "500"};
  border: 1px solid ${colors.primary};
  color: ${colors.primary};

  p {
    font-size: ${props => props.mini ? "13px" : "16px"};
    margin: 0;
    padding: 0;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;

export const WhiteExternalLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 13px;
  text-decoration: none;
  color: ${colors.secondary};
  background: #fff;
  padding: 10px 22px;
  border-radius: 12px;
  transition: 0.2s;
  width: 140px;

  &:hover {
    color: ${colors.buttonHover};
  }

  h1 {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    margin-top: 5px;
  }

  p {
    color: ${colors.buttonHover};
  }
`;

export const StyledLinkHeader = styled(StyledLink)`
  padding: 12px 15px;
  margin: 0;
  font-size: 15px;

  &:hover {
    background: ${colors.primaryActive};
  }
`;

export const StyledLinkTransparent = styled(Link)`
  background: none;
  color: #008C8C;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: none;
  margin: 8px;
  text-decoration: none;
`;

interface StyledInputProps {
    isFailed?: boolean;
    value?: string;
}

export const StyledInput = styled.input<StyledInputProps>`
  outline: none;
  font-size: 16px;
  padding: 18px 20px;
  padding-top: ${({value}) => value && "28px"};
  padding-bottom: ${({value}) => value && "8px"};
  margin: 0;
  border-radius: 8px;
  background: ${({isFailed}) => isFailed ? colors.redInput : "#ecf1f7"};
  border: 1px solid ${({isFailed}) => isFailed ? colors.redInput : "rgba(0, 0, 0, 0)"};

  &:focus {
    background: #fff;
    border: 1px solid ${({isFailed, value}) => isFailed ? colors.redFail : "rgb(118, 118, 118)"};
    padding-top: 28px;
    padding-bottom: 8px;
  }
`;

export const ConfirmIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  border-radius: 8px;
  border: none;
  width: 35px;
  height: 35px;
  cursor: pointer;

  img {
    width: 22px;
    height: 22px;
  }

  &:hover {
    opacity: 0.9;
  }
`;

export const Modal = styled(FlexColumn)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  justify-content: start;
  background: #fff;
  z-index: 999;
  overflow-y: scroll;

  * {
    box-sizing: border-box;
  }
`;
export const ModalWindow = styled(FlexColumn)`
  background: #fff;
  border-radius: 16px;
  padding: 10px;
  width: calc(100% - 20px);
  max-width: 400px;
`;

interface AppStyleProps {
    isLandingPage?: boolean;
    isArLanguage: boolean;
    isAuth: boolean;
}

export const AppStyle = styled(FlexColumn)<AppStyleProps>`
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 800px;
  background: ${({isAuth, isLandingPage}) =>
          (isAuth && !isLandingPage)
                  ? colors.white
                  : "#FCFBF5"};
  direction: ${({isArLanguage}) => isArLanguage && "rtl"};
`;

export const Page = styled(FlexColumn)`
  width: 100vw;
  overflow-x: hidden;
  height: 100%;
  min-height: 100vh;
`;
export const AuthContent = styled(FlexColumn)`
  width: 100vw;
  height: 100%;
  justify-content: center;
`;

export const AuthModal = styled(FlexColumn)`
  padding: 26px 20px;
  border-radius: 16px;
  background: #fff;
  border: 2px solid #E2E8F0;
`;
export const AuthTitle = styled.h1`
  font-weight: 500;
  font-size: 26px;
`;

export const PassIcon = styled.img<{ lng?: string }>`
  position: absolute;
  cursor: pointer;
  right: ${props => props.lng.toLowerCase() === 'ar' ? '' : '10px'};
  left: ${props => props.lng.toLowerCase() === 'ar' ? '10px' : ''};
  top: 50%;
  transform: translateY(-50%);
`;

PassIcon.defaultProps = {
    lng: ''
}

type IErrorTextProps = {
    hasError?: boolean
}
export const ErrorText = styled.p<IErrorTextProps>`
  color: ${colors.redFail};
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  min-height: 14px;
  visibility: ${props => props.hasError ? 'visible' : 'hidden'};
`;

ErrorText.defaultProps = {
    hasError: true
};

export const InfoText = styled.p`
  color: ${colors.buttonHover};
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  min-height: 14px;
  cursor: pointer;
`;


export const StatsWrapper = styled(FlexRowBetweenResponsive)`
  max-width: 1330px;
  margin-top: 20px;

  @media (min-width: 780px) and (max-width: 1300px) {
    gap: 2rem;
    h1 {
      font-size: 1.125rem;
    }

    p {
      font-size: 0.75rem;
    }
  }

  @media (min-width: 480px) and (max-width: 780px) {
    gap: 2rem;
    font-size: 1.25rem;
  }
  @media (max-width: 768px) {
    flex-direction: row;
  }

  @media (max-width: 480px) {
    gap: 1.25rem;
    width: 100%;
    flex-direction: column;
  }
`;
