import React from "react";
import {colors} from "../../style/colors";
import {Oval} from "react-loader-spinner";


export const PageLoader: React.FC = () => {

    return (
        <Oval
            height={30}
            width={30}
            color={colors.primary}
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor={colors.primaryActive}
            strokeWidth={2}
            strokeWidthSecondary={2}
        />    );
};
