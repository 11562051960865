import styled from "styled-components";
import {FlexColumn, Page} from "../../style/projectComponents";
import HeaderComponent from "../../components/global/HeaderComponent";
import {OperationsComponent} from "../../components/DashboardComponents/OperationsComponent";
import React, {useEffect, useState} from "react";
import {colors} from "../../style/colors";
import {useMutation} from "react-query";
import AdminApi from "../../service/admin-api/AdminApi";
import {
    IGetAllOperationsListAdminReq,
    IGetAllOperationsListAdminResp
} from "../../service/admin-api/models";
import {DataEmptyComponent} from "../../components/global/DataEmptyComponent";
import {useTranslation} from "react-i18next";
import {IStatusOperation} from "../../service/user-api/models";

const StyledBg = styled(FlexColumn)`
  background: ${colors.pageBg};
  width: 100vw;
  padding: 24px;
  height: 100%;
  margin-top: 110px;
  justify-content: flex-start;
`;

const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1100px;
  justify-content: start;
  background: #fff;
  padding: 26px 16px;
  border-radius: 8px;
  max-height: 740px;
  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`;

const ChartTitle = styled.p`
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-top: 18px;
  padding-bottom: 18px;
`;

export const AdminOperations = () => {
    const [operationsData, setOperationsData] = useState<IGetAllOperationsListAdminResp | null>(null);

    const getOperationsItemsQuery = useMutation(
        (data: IGetAllOperationsListAdminReq) => AdminApi.getAllOperationsList(data),
        {
            onSuccess: (data) => {
                setOperationsData(data);
            }
        });

    useEffect(() => {
        const req: IGetAllOperationsListAdminReq = {
            statuses: Object.values(IStatusOperation),
            skip: 0,
            take: 200
        };
        getOperationsItemsQuery.mutate(req);
    }, []);

    const {t, i18n} = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page>
            <HeaderComponent/>
            <StyledBg>
                <OperationsWrapper>
                    <ChartTitle>{t('adminOperations.operationHistory')}</ChartTitle>
                    {operationsData?.items.length > 0
                        ? <OperationsComponent
                            keys={['operationDate', 'walletOwnerEmail', 'balanceDiff', 'type', 'status']}
                            operations={operationsData.items}/>
                        : <DataEmptyComponent isLoading={getOperationsItemsQuery.isLoading}/>
                    }
                </OperationsWrapper>
            </StyledBg>
        </Page>
    );
};