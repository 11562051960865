import {action, autorun, computed, makeAutoObservable} from "mobx";
import {createContext} from "react";
import {IUserDetailsResponse, TWallet} from "../../service/user-api/models";

export const initialProfile: IUserDetailsResponse = {
    birthDate: "",
    email: "",
    firstName: "",
    id: "",
    lastName: "",
    middleName: "",
    phoneNumber: "",
    roles: [],
    wallets: []
}

class ProfileStorage {
    profile: IUserDetailsResponse = localStorage.profile ? JSON.parse(localStorage.profile) : initialProfile;

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        autorun(() => {
            localStorage.profile = JSON.stringify(this.profile);
        });
    }

    @action setProfile(newProfile: IUserDetailsResponse) {
        this.profile = newProfile;
    }

    @action updateBalanceUSDT(newBalance: number) {
        this.profile.wallets = this.profile.wallets
            .map(it => it.currency === 'USDT'
                ? {
                    ...it,
                    balance: newBalance
                }
                : it
            );
    }

    @action reduceBalanceUSDTFor(value: number) {
        const newWithdrawalBalance = this.getWithdrawalBalance() - value < 0 ? 0 : this.getWithdrawalBalance() - value;
        const newBalance = this.getBalance() - (this.getWithdrawalBalance() - value < 0 ? Math.abs(this.getWithdrawalBalance() - value) : 0);

        this.profile.wallets = this.profile.wallets
            .map(it => it.currency === 'USDT'
                ? {
                    ...it,
                    balance: newBalance,
                    withdrawalBalance: newWithdrawalBalance
                }
                : it
            );
    }

    @action updateWithdrawalBalanceUSDT(newBalance: number) {
        this.profile.wallets = this.profile.wallets
            .map(it => it.currency === 'USDT'
                ? {
                    ...it,
                    withdrawalBalance: newBalance < 0 ? 0 : newBalance
                }
                : it
            );
    }

    @computed getProfile(): IUserDetailsResponse {
        return this.profile;
    }

    @computed getWithdrawalBalance(): number {
        return this?.profile?.wallets?.find(it => it.currency === 'USDT')?.withdrawalBalance || 0;
    }

    @computed getBalance(): number {
        return this?.profile?.wallets?.find(it => it.currency === 'USDT')?.balance || 0;
    }

    @computed getUSDTWallet(): TWallet {
        return this?.profile?.wallets?.find(it => it.currency === 'USDT');
    }

    @action
    clear() {
        this.profile = initialProfile;
    }


}

export default createContext(new ProfileStorage());
