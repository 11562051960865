import {ErrorText, FlexColumnStart, FlexRow, PassIcon, StyledRegisterButton} from "../../style/projectComponents";
import {Controller, FieldValues, useForm} from "react-hook-form";
import {ValidationInputComponent} from "../global/ValidationInputComponent";
import React, {useContext, useState} from "react";
import styled from "styled-components";
import {colors} from "../../style/colors";
import {useTranslation} from "react-i18next";
import {settingsInputs} from "../../pages/SettingsPage/inputsModels";
import {useMutation} from "react-query";
import UserApi from "../../service/user-api/UserApi";
import ProfileStorage from "../../storage/ProfileStorage/ProfileStorage";
import GlobalModalStore from "../../storage/GlobalModalStore/GlobalModalStore";

const WithdrawBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 12px;
  margin-top: 30px;
  border-radius: 12px;
  width: 100%;
  max-width: 380px;
  border: 1px solid ${colors.blueGray};

  img {
    margin-bottom: 25px;
    margin-top: 25px;
    border-radius: 10px;
  }

  @media (max-width: 600px) {
    //max-width: calc(100vw - 32px);
    max-width: 330px;
  }
`;
const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 380px;
  margin-bottom: 5px;

  @media (max-width: 600px) {
    width: 100%;
    max-width: 330px;
  }
`;

const InputTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 8px;
  
`;


type IInputs = {
    phoneNumber: string,
    oldPassword: string,
    newPassword: string,
    badRequest: any
}
const StyledPassIcon = styled(PassIcon)`
  top: 0;
  transform: translateY(-25%);
`

const StyledAdvise = styled.p`
  margin-top: 10px;
  color: ${colors.gray};
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  min-height: 14px;
`
const InputBox = styled(FlexColumnStart)`
  margin-bottom: 5px;


  @media (max-width: 600px) {
    width: 100%;
    max-width: 330px;

    div {
      label {
        left: 20px;
      }
    }
  }
`
export const SettingsInputs = () => {
    const {t, i18n} = useTranslation();
    const ProfileStore = useContext(ProfileStorage);

    const {
        control,
        handleSubmit,
        watch,
        setError,
        clearErrors,
        setValue,
        formState: {isDirty, errors},
    } = useForm<IInputs>({
        defaultValues: {
            phoneNumber: ProfileStore.profile.phoneNumber,
            oldPassword: '',
            newPassword: ''
        }
    });

    const updatePasswordMutation = useMutation((data: { oldPassword: string, newPassword: string }) => UserApi.updatePassword(data.oldPassword, data.newPassword), {
        onError: error => {
            setError("badRequest", {
                type: "manual",
                message: "auth.errors.password.serverError"
            });
        }
    });

    const GlobalModalStorage = useContext(GlobalModalStore);

    const updatePhoneMutation = useMutation((phone: string) => UserApi.updateUserInfo({
        ...ProfileStore.profile,
        phoneNumber: phone
    }));


    const onSubmit = (data) => {
        if (data.oldPassword !== '') {
            updatePasswordMutation.mutate({
                oldPassword: data.oldPassword,
                newPassword: data.newPassword
            }, {
                onSuccess: data => {
                    GlobalModalStorage.makeVisible(true, t("common.success"));
                },
                onError: error => {
                    GlobalModalStorage.makeVisible(false, t("common.error"));
                }
            });
        }
        updatePhoneMutation.mutate(data.phoneNumber, {
            onSuccess: data1 => {
                ProfileStore.setProfile({...ProfileStore.profile, phoneNumber: data.phoneNumber});
                GlobalModalStorage.makeVisible(true, t("common.success"));
            },
            onError: error => {
                GlobalModalStorage.makeVisible(false, t("common.error"));
            }
        });
    }

    const inputTitles = [t("settings.contacts"), t("settings.inputs.password.title")]

    const [showPass, setShowPass] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);

    const handleCheckType = (name: string, typeDefault: any) => {
        switch (name) {
            case 'oldPassword':
                return showPass ? 'text' : 'password';
            case 'newPassword':
                return showNewPass ? 'text' : 'password';
            default:
                return typeDefault;
        }
    };

    const [advisePassword, setAdvisePassword] = useState('settings.inputs.password.errors.pattern');

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <WithdrawBlock>
                {settingsInputs.map((it, idx) =>
                    <InputBox>
                        {idx < 2 && <InputTitle>
                            {inputTitles[idx]}
                        </InputTitle>}
                        <InputWrapper>
                            <Controller
                                name={it.name}
                                control={control}
                                rules={it.name === 'newPassword'
                                    ? it.rules(watch)
                                    : it.rules
                                }
                                render={({field: {ref, ...field}}) =>
                                    <ValidationInputComponent
                                        {...field}
                                        onChange={e => {
                                            setValue(it.name, e.target.value.replace("‎\u200e", ""));

                                        }}
                                        value={it.name === 'phoneNumber' ? "‎\u200e" + field.value : field.value}
                                        type={handleCheckType(it.name, it.type)}
                                        isError={!!errors[it.name]}
                                        label={t(it.label)}/>}
                            />

                            {it.name === 'oldPassword' && <StyledPassIcon
                                lng={i18n.language}
                                onClick={() => setShowPass(prev => !prev)}
                                src={showPass ? '/images/show.svg' : '/images/hide.svg'}/>}

                            {it.name === 'newPassword' && <StyledPassIcon
                                lng={i18n.language}
                                onClick={() => setShowNewPass(prev => !prev)}
                                src={showNewPass ? '/images/show.svg' : '/images/hide.svg'}/>}

                        </InputWrapper>

                        {it.name === 'oldPassword' && !errors[it.name] &&
                            <StyledAdvise>
                                {t(advisePassword)}
                            </StyledAdvise>
                        }

                        <ErrorText hasError={!!errors[it.name]}>
                            {t(errors[it.name]?.message?.toString())}
                        </ErrorText>
                    </InputBox>
                )}

                <StyledRegisterButton
                    onClick={() => clearErrors()}
                    disabled={updatePasswordMutation.isLoading || updatePhoneMutation.isLoading}
                    type={'submit'}
                    noRadius
                    style={{width: '100%', maxWidth: '100%'}}>
                    {t('settings.save')}
                </StyledRegisterButton>

                <ErrorText style={{marginTop: 10}} hasError={!!errors.badRequest}>
                    {t(errors?.badRequest?.message.toString())}
                </ErrorText>
            </WithdrawBlock>
        </form>
    );
};
