import {FieldValues} from "react-hook-form";
import {watch} from "fs/promises";

type IPropsInput = {
    name: "phoneNumber" | "oldPassword" | "newPassword" | "badRequest",
    label: string,
    type: string,
    rules: any
}

export const isPhoneGood = {
    required: {
        value: true,
        message: 'settings.inputs.phone.errors.required'
    },
    pattern: /^\+?\d+$/,
    minLength: 11,
    maxLength: 18
};

export const isOldPasswordGood = {
    required: {
        value: false,
        // message: 'settings.inputs.password.errors.required'
    },
    minLength: {
        value: 8,
        message: 'settings.inputs.password.errors.minLength'
    },
    pattern: {
        value: /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}/g,
        message: 'settings.inputs.password.errors.pattern'
    },
};

export const isNewPasswordGood = (watch: any) => {
    return {
        required: false,
        validate: (val: string) => {
            if (watch('oldPassword') === val && watch('oldPassword') != '') {
                return 'Старый и новый пароль не должны совпадать';
            }
        },
    };
};

export const settingsInputs: IPropsInput[] = [
    {
        name: 'phoneNumber',
        label: 'settings.inputs.phone.title',
        type: 'phone',
        rules: isPhoneGood
    },
    {
        name: 'oldPassword',
        label: 'settings.inputs.password.title',
        type: 'password',
        rules: isOldPasswordGood
    },
    {
        name: 'newPassword',
        label: 'forgotPassword.newPass',
        type: 'password',
        rules: isNewPasswordGood
    },
]