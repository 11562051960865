import {Controller, useForm} from "react-hook-form";
import {ValidationInputComponent} from "../../global/ValidationInputComponent";
import {ErrorText, FlexRow, PassIcon, StyledButton} from "../../../style/projectComponents";
import React, {FC, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {isPasswordGood} from "../Register/SecUtils";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useMutation} from "react-query";
import AuthApi from "../../../service/auth-api/AuthApi";
import {IResetPasswordRequest} from "../../../service/auth-api/models";
import GlobalModalStore from "../../../storage/GlobalModalStore/GlobalModalStore";

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 300px;
  @media (max-width: 480px) {
    label {
      left: 20px;
    }
  }
`;

type IFormValues = {
    newPassword: string,
    badReset: any
}

type IInputs = {
    name: 'badReset' | 'newPassword',
    label: string,
    type: string,
    rules: any
}

const baseInputs: IInputs[] = [
    {
        name: 'newPassword',
        label: 'forgotPassword.newPass',
        type: 'password',
        rules: isPasswordGood
    }
]

const RecoveryContent: FC = (props) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const resetPassQuery = useMutation((data: IResetPasswordRequest) => AuthApi.resetPassword(data), {
        onSuccess: data => {
            navigate('/login');
        },
        onError: error => {
            setError("badReset", {
                type: "manual",
                message: "auth.errors.password.serverError"
            });
        }
    })

    const {control, register, watch, clearErrors, setError, handleSubmit, formState: {errors}} = useForm<IFormValues>();

    const [searchParams, setSearchParams] = useSearchParams();

    const GlobalModalStorage = useContext(GlobalModalStore);

    const onSubmit = (data: IFormValues) => {
        const req: IResetPasswordRequest = {
            newPassword: data.newPassword,
            resetToken: searchParams.get('token'),
            userId: searchParams.get('userId')

        };
        resetPassQuery.mutate(req, {
            onSuccess: data => {
                GlobalModalStorage.makeVisible(true, t("common.success"));
            },
            onError: error => {
                GlobalModalStorage.makeVisible(false, t("common.error"));
            },
        });
    }
    const [showPass, setShowPass] = useState(false);
    const handleCheckType = () => {
        return showPass ? 'text' : 'password';
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {baseInputs.map((it, idx) =>
                <>
                    <InputWrapper style={{marginBottom: 5, marginTop: idx !== 0 && 10}}>
                        <Controller
                            name={it.name}
                            control={control}
                            rules={it.rules}
                            render={({field: {ref, ...field}}) =>
                                <ValidationInputComponent
                                    {...field}
                                    type={handleCheckType()}
                                    isError={!!errors[it.name]}
                                    label={t(it.label)}/>
                            }
                        />
                        <PassIcon
                            lng={i18n.language}
                            onClick={() => setShowPass(prev => !prev)}
                            src={showPass ? '/images/show.svg' : '/images/hide.svg'}/>
                    </InputWrapper>
                    <ErrorText hasError={!!errors[it.name]} style={{maxWidth: 300}}>
                        {t(errors[it.name]?.message?.toString())}
                    </ErrorText>
                </>
            )}
            <StyledButton
                style={{width: '100%', height: 50}}
                disabled={resetPassQuery.isLoading}
                onClick={() => clearErrors()} type={'submit'}>
                {t('forgotPassword.update')}
            </StyledButton>

            <ErrorText style={{marginTop: 10}} hasError={!!errors.badReset}>
                {t(errors?.badReset?.message.toString())}
            </ErrorText>
        </form>
    );
};

export default observer(RecoveryContent);