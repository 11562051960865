import DashboardPage from "../pages/DashboardPage";
import DepositPage from "../pages/DepositPage";
import WithdrawPage from "../pages/WithdrawPage/WithdrawPage";
import SettingsPage from "../pages/SettingsPage/SettingsPage";
import {HomePage} from "../pages/HomePage";
import AuthPage from "../pages/AuthPage";
import {RegistrationPage} from "../pages/RegisterPage";
import {AdminAnalytics} from "../pages/AdminPages/AdminAnalytics";
import {AdminWithdraw} from "../pages/AdminPages/AdminWithdraw";
import {AdminOperations} from "../pages/AdminPages/AdminOperations";
import {ForgotPassword} from "../pages/ForgotPassword";
import {ResetPassword} from "../pages/ResetPassword";
import AdminUserDetail from "../pages/AdminPages/AdminUserDetail";
import {AdminWithdrawalHistory} from "../pages/AdminPages/AdminWithdrawalHistory";
import {AmlPage} from "../pages/DocumentsPages/Aml/AmlPage";
import {PrivacyPage} from "../pages/DocumentsPages/Privacy/PrivacyPage";
import {SubscriptionPage} from "../pages/DocumentsPages/Subscription/SubscriptionPage";
import {TermsPage} from "../pages/DocumentsPages/Terms/TermsPage";
import {WebsiteTerms} from "../pages/DocumentsPages/WebsiteTerms/WebsiteTerms";

export const publicRoutes = [
    {
        path: '/',
        component: HomePage
    },
    {
        path: '/aml',
        component: AmlPage
    },
    {
        path: '/privacy',
        component: PrivacyPage
    },
    {
        path: '/subscription',
        component: SubscriptionPage
    },
    {
        path: "/login",
        component: AuthPage
    },
    {
        path: "/register",
        component: RegistrationPage
    },
    {
        path: '/forgot',
        component: ForgotPassword
    },
    {
        path: '/reset',
        component: ResetPassword
    },
    {
        path: '/terms',
        component: TermsPage
    },
    {
        path: '/website-terms',
        component: WebsiteTerms
    },
]

export const privateRoutes = [
    {
        path: '/',
        component: HomePage
    },
    {
        path: "/dashboard",
        component: DashboardPage
    },
    {
        path: "/deposit",
        component: DepositPage
    },
    {
        path: "/withdraw",
        component: WithdrawPage
    },
    {
        path: "/settings",
        component: SettingsPage
    },
    {
        path: '/aml',
        component: AmlPage
    },
    {
        path: '/privacy',
        component: PrivacyPage
    },
    {
        path: '/subscription',
        component: SubscriptionPage
    },
    {
        path: '/terms',
        component: TermsPage
    },
    {
        path: '/website-terms',
        component: WebsiteTerms
    },
]

export const adminRoutes = [
    {
        path: "/admin/analytics",
        component: AdminAnalytics
    },
    {
        path: "/admin/withdraw",
        component: AdminWithdraw
    },
    {
        path: "/admin/withdraw-history",
        component: AdminWithdrawalHistory
    },
    {
        path: "/admin/operations",
        component: AdminOperations
    },
    {
        path: "/admin/:id?",
        component: AdminUserDetail
    },
]
