import {INetworkInterface, INetworkListResponse} from "./models";
import {apiClient} from "../../config/serverConstants";

class NetworkService implements INetworkInterface {
    async getNetworkList() {
        const response = await apiClient.get('/api/Network/getNetworksList');
        return response.data as INetworkListResponse;
    }

}

export default new NetworkService()