import React, {useEffect, useState} from "react";
import {FlexColumn, FlexRowResponsive, Page} from "../../style/projectComponents";
import HeaderComponent from "../../components/global/HeaderComponent";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {withdrawTypes} from "../../config/withdrawTypes";
import {DepositData} from "../../components/WithdrawComponents/DepositData";
import {useTranslation} from "react-i18next";
import {DepositMethodButton} from "../../components/DepositComponents/DepositMethodButton";


const StyledBg = styled(FlexColumn)`
  width: 100vw;
  padding: 24px;
  height: 100%;
  margin-top: 110px;
`;

const PayMethodTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const WithdrawPage: React.FC = () => {


    const [activeTypeId, setActiveTypeId] = useState(0);

    const {t} = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Page>
            <HeaderComponent/>
            <StyledBg>
                <PayMethodTitle>{t("withdraw.title")}</PayMethodTitle>
                <FlexRowResponsive>
                    {withdrawTypes.map((method, id) =>
                        <DepositMethodButton
                            icon={method.icon}
                            title={method.title}
                            isActive={id === activeTypeId}
                            setActiveTypeId={setActiveTypeId}
                            id={id}
                        />
                    )}
                </FlexRowResponsive>
                <DepositData activeTypeId={activeTypeId}/>
            </StyledBg>
        </Page>
    );
};

export default observer(WithdrawPage);
