import {FlexColumn, FlexColumnStart, FlexRow, StyledButton} from "../../../style/projectComponents";
import React, {ComponentPropsWithoutRef, FC, useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import styled from "styled-components";
import {ValidationInputComponent} from "../../global/ValidationInputComponent";
import {IUpdateUserInfo, IUserDetailsResponse} from "../../../service/user-api/models";
import {useMutation} from "react-query";
import UserApi from "../../../service/user-api/UserApi";
import {getValue} from "@testing-library/user-event/dist/utils";
import {useTranslation} from "react-i18next";
import {DatePicker} from "../../global/DatePicker";
import GlobalModalStore from "../../../storage/GlobalModalStore/GlobalModalStore";
import formatDateCustom from "../../../utils/formatDateCustom";
import {CheckComponent} from "../../auth/Register/CheckComponent";

type CommonInfoFormProps = {
    user: IUserDetailsResponse
}
type IFormType = {
    name: "name" | "email" | "phoneNumber" | "birthDate",
    rules: any,
    type: string,
}

const forms: IFormType[] = [
    {
        name: 'name',
        rules: {required: false},
        type: 'text',
    },
    {
        name: 'email',
        rules: {required: false},
        type: 'text',
    },
    {
        name: 'phoneNumber',
        rules: {required: false},
        type: 'text',
    },
    {
        name: 'birthDate',
        rules: {required: false},
        type: 'text',
    },
];

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 380px;

  @media (max-width: 480px) {
    width: 100%;
    max-width: 380px;
    min-width: 280px;
    label {
      left: 20px;
    }
  }
`;

const StyledForm = styled.form`
  flex-direction: column;
  display: flex;
  align-items: start;

  @media (max-width: 780px) {
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;

    h3 {
      align-self: start;
    }
  }
`

export const CommonInfoForm: FC<CommonInfoFormProps & ComponentPropsWithoutRef<'form'>> =
    (props) => {
        const {user, ...other} = props;

        const {
            register,
            setValue,
            getValues,
            watch,
            formState: {errors, defaultValues, isDirty},
            handleSubmit
        } = useForm<{
            name: string,
            email: string,
            phoneNumber: string,
            birthDate: string,
        }>();
        const {t, i18n} = useTranslation();

        useEffect(() => {
            console.log(user, getValues());
            setValue('name', `${user.lastName} ${user.firstName} ${user.middleName}`);
            setValue('email', user.email)
            setValue('phoneNumber', user.phoneNumber)
            setValue('birthDate', formatDateCustom(user.birthDate) || "")
        }, [user]);


        const updUserQuery = useMutation((data: IUpdateUserInfo) => UserApi.updateUserInfo(data));

        const GlobalModalStorage = useContext(GlobalModalStore);
        const onSubmit = (data: any) => {
            const fullname = data.name.trim().split(' ');
            const newData: IUpdateUserInfo = {
                birthDate: data.birthDate,
                firstName: fullname[1],
                id: user.id,
                lastName: fullname[0],
                middleName: fullname[2],
                phoneNumber: data.phoneNumber
            };
            newData.birthDate = formatDateCustom(data.birthDate)
            updUserQuery.mutate(newData, {
                onSuccess: data => {
                    GlobalModalStorage.makeVisible(true, t("common.success"));
                }, onError: error => {
                    GlobalModalStorage.makeVisible(false, t("common.error"));
                }
            });
        };

        const [openBirth, setOpenBirth] = useState(false);

        return (
            <StyledForm
                onSubmit={handleSubmit(onSubmit)}
                {...other}>
                <h3 style={{marginBottom: '1rem', fontSize: '1.5rem', fontWeight: 400}}>
                    {t('adminUserDetail.commonInfo')}
                </h3>
                <FlexColumn style={{gap: '0.81rem', marginBottom: '0.81rem'}}>
                    {forms.map((it, idx) =>
                        <InputWrapper data-phone={it.name === 'phoneNumber'}>
                            <ValidationInputComponent
                                {...register(it.name, {...it.rules})}
                                value={"‎\u200e" + watch(it.name)}
                                onChange={(e) => setValue(it.name, e.target.value.replace("‎\u200e", ""))}
                                isError={!!errors[it.name]}
                                onClick={() => {
                                    if (it.name === 'birthDate') {
                                        setOpenBirth(prev => !prev)
                                    }
                                }}
                                onBlur={() => {
                                    if (it.name === 'birthDate') {
                                        setOpenBirth(false)
                                    }
                                }}
                                label={t(`adminUserDetail.${it.name}.label`)}
                                placeholder={t(`adminUserDetail.${it.name}.placeholder`)}
                                type={it.type}
                            />
                        </InputWrapper>
                    )}
                </FlexColumn>

                {openBirth && <DatePicker
                    open={openBirth}
                    setOpen={setOpenBirth}
                    date={watch('birthDate')}
                    setDate={setValue}/>
                }

                <StyledButton
                    type={'submit'}
                    style={{width: '100%', padding: 'var(--16, 1rem)'}}
                    disabled={updUserQuery.isLoading}
                    noRadius
                >
                    {t('adminUserDetail.save')}
                </StyledButton>

            </StyledForm>
        );
    };
