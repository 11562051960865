import React, {useContext, useState} from "react";
import styled from "styled-components";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {FlexRow, FlexRowBetween, StyledButton} from "../../style/projectComponents";
import {observer} from "mobx-react-lite";
import {colors} from "../../style/colors";
import MenuModal from "./modal/MenuModal";
import UserStorage from "../../storage/UserStorage/UserStorage";
import {ChangeLanguage} from "./ChangeLanguage";
import ProfileStorage from "../../storage/ProfileStorage/ProfileStorage";
import {useTranslation} from "react-i18next";
import {Link as ScrollLink} from 'react-scroll';

interface HeaderComponentProps {
    searchValue?: string;
    onChangeValue?: (e: any) => void;
}

const Header = styled.div<{ isAuth?: boolean }>`
  position: ${props => props.isAuth ? 'fixed' : 'fixed'};
  top: 0;
  left: 0;
  background: ${props => props.isAuth ? colors.white : '#FCFBF5'};
  z-index: 20;
  width: calc(100vw - 20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  border-bottom: 0.0625rem solid ${colors.lightGrayBg};
  transition: all 0.3s ease-in-out;
`;

interface ContentProps {
    isLanding: boolean;
}

const Content = styled.div<ContentProps>`
  max-width: ${({isLanding}) => isLanding && "81.25rem"};
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  align-items: center;
  a {
    height: min-content;
    margin-right: 10px;
  }
`;

const Logo = styled.img`
  width: 210px;
  height: 40px;
  margin: 0 10px 0;
`;

const MenuButton = styled(StyledButton)`
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const LanguageWrapper = styled(FlexRowBetween)`
  //width: 70px;
  column-gap: 2rem;
  @media (max-width: 600px) {
    display: none;
  }
`;

const RightWrapper = styled(FlexRow)`
  //width: 200px;
  align-items: center;
  margin-right: 20px;
  column-gap: 2rem;

  @media (max-width: 600px) {
    width: auto;
  }
`;

const LangWrapper = styled(FlexRow)`
  @media (max-width: 600px) {
    display: none;
  }
`;

const BtnWrapper = styled(FlexRow)`
  column-gap: 2rem;
  width: max-content;

  @media (max-width: 768px) {
    display: none;
  }
`;
const AppMenu = styled(FlexRowBetween)<{ menuOpen: boolean }>`
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid ${colors.lightGray};
  width: 84px;
  height: 48px;
  gap: 10px;
  cursor: pointer;
  box-shadow: ${props => props.menuOpen ? "1px 4px 8px 1px rgba(0,0,0,0.25)" : "none"};
  user-select: none;

  &:hover {
    box-shadow: 1px 4px 8px 1px rgba(0, 0, 0, 0.25);
  }
`;
const UserAvatar = styled(FlexRow)`
  background: ${colors.primary};
  border-radius: 4px;
  width: 30px;
  height: 30px;

  p {
    color: #fff;
    padding: 6px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }
`;
const MenuBurgerWrapper = styled(FlexRow)`
  width: 30px;
  height: 30px;
`

const useOnWindowScroll = callback => {
    const listener = React.useRef<null | any>(null);

    React.useEffect(() => {
        if (listener.current)
            window.removeEventListener('scroll', listener.current);
        listener.current = window.addEventListener('scroll', callback);
        return () => {
            window.removeEventListener('scroll', listener.current);
        };
    }, [callback]);
};

const HeaderComponent: React.FC<HeaderComponentProps> = (props) => {
    const {onChangeValue, searchValue} = props;

    const UserStore = useContext(UserStorage);
    const [isScrolled, setIsScrolled] = useState(false);

    useOnWindowScroll(() => {
        if (isScrolled && window.scrollY < 100) {
            setIsScrolled(false);
        } else if (!isScrolled && window.scrollY > 100) {
            setIsScrolled(true);
        }
    });

    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();
    const showMenu = location.pathname === "/dashboard"
        || location.pathname === "/deposit"
        || location.pathname === "/withdraw"
        || location.pathname === "/settings"
        || location.pathname === "/"
        || location.pathname.includes("admin");

    const isLanding = location.pathname === "/";

    const navigate = useNavigate();

    const isAuth = !!UserStore.accessToken;
    const ProfileStore = useContext(ProfileStorage);
    const firstName = ProfileStore.profile?.firstName?.charAt(0) || "";
    const lastName = ProfileStore.profile?.lastName?.charAt(0) || "";
    const isLandingPage = location.pathname === "/"

    const {t, i18n} = useTranslation();

    return (
        <Header
            className={'header'}
            isAuth={isAuth}
            style={{
                background: location.pathname === "/"
                    ? '#FCFBF5'
                    : !showMenu && "none",
                borderBottom: !showMenu && 'none',
                paddingTop: isScrolled && "0.875rem",
                paddingBottom: isScrolled && "0.875rem",
            }}>
            <Content isLanding={isLanding}>
                {isLandingPage
                    ? <ScrollLink
                        to={'main'}
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={500}
                        style={{display: "flex", cursor: 'pointer'}}>
                        <Logo src="/images/kanna-logo.svg" alt=""/>
                    </ScrollLink>
                    : <Link
                        to={isAuth ? "/dashboard" : '/'}
                        style={{display: "flex"}}>
                        <Logo src="/images/kanna-logo.svg" alt=""/>
                    </Link>
                }

                <RightWrapper>

                    <LangWrapper>
                        <ChangeLanguage/>
                    </LangWrapper>

                    {!isAuth && showMenu &&
                        <BtnWrapper>
                            <StyledButton
                                onClick={() => navigate('/login')}
                                variant={'text'}>
                                {t("auth.login")}
                            </StyledButton>
                            <StyledButton
                                onClick={() => navigate('/register')}>
                                {t("auth.reg_title")}
                            </StyledButton>
                        </BtnWrapper>
                    }

                    {!isLanding && showMenu &&
                        <AppMenu onClick={() => setMenuOpen(prev => !menuOpen)} menuOpen={menuOpen}>
                            <img width={18} height={18} src={menuOpen ? '/images/close.svg' : '/images/burger.svg'}/>
                            <UserAvatar>
                                <p>{firstName}{lastName}</p>
                            </UserAvatar>
                        </AppMenu>
                    }
                    {isLanding && showMenu &&
                        <MenuButton
                            onClick={() => setMenuOpen(prev => !menuOpen)}
                            variant={'text'}>
                            <MenuBurgerWrapper>
                                <img src={menuOpen ? '/images/close.svg' : '/images/burger.svg'}
                                     style={{width: menuOpen ? 21 : 30, height: menuOpen ? 21 : 30}}
                                />
                            </MenuBurgerWrapper>
                        </MenuButton>
                    }
                </RightWrapper>
            </Content>
            <MenuModal
                isScrolled={isScrolled}
                isAuth={isAuth}
                open={menuOpen}
                handleMenu={() => setMenuOpen(prev => !prev)}/>
        </Header>
    );
};

export default observer(HeaderComponent);
