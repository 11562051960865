import {FlexColumnStart, FlexRow, StyledButton, StyledButtonGray} from "../../../style/projectComponents";
import styled from "styled-components";
import React, {FC, useContext} from "react";
import {useTranslation} from "react-i18next";
import UserStorage from "../../../storage/UserStorage/UserStorage";

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 380px;

  @media (max-width: 480px) {
    width: 320px;
    label {
      left: 20px;
    }
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 130;
`;

const Content = styled(FlexColumnStart)`
  background-color: white;
  border-radius: var(--8, 0.5rem);
  padding: 2.37rem 3.5rem;
  position: relative;
  width: 450px;
  align-items: center;

  @media (max-width: 480px) {
    padding: 2.5rem 1rem;
    width: 100%;
    align-items: center;
    border-radius: 0.75rem;
  }
`;

const InnerWrapper = styled(FlexColumnStart)`
  row-gap: 0.5rem;
  width: calc(100% - 100px);

  h3 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    width: 100%;
  }
  p {
    font-size: 1.25rem;
    text-align: center;
    width: 100%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const CloseButton = styled(StyledButton)`
  align-self: end;
  position: absolute;
  right: 1.6rem;
  top: 1.31rem;
`;

export const LogoutModal: FC<{ setIsOpen?: any }> = ({setIsOpen}) => {

    const {t} = useTranslation();
    const UserStore = useContext(UserStorage);

    return (
        <StyledForm
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    setIsOpen(false);
                }
            }}>
            <Content>
                <CloseButton
                    onClick={() => setIsOpen(false)}
                    variant={'text'}>
                    <img src={'/images/ic_cancel.svg'}/>
                </CloseButton>

                <InnerWrapper>
                    <h3>
                        {t('menu.exit')}
                    </h3>
                    <p>
                        {t('menu.exitConfirm')}
                    </p>
                    <StyledButton
                        onClick={() => UserStore.logout()}
                        noRadius
                        style={{width: '100%', height: 50, marginTop: "1.5rem"}}>
                        {t('menu.logout')}
                    </StyledButton>
                    <StyledButtonGray
                        onClick={() => setIsOpen(false)}
                        style={{width: '100%', height: 50}}>
                        {t('menu.cancel')}
                    </StyledButtonGray>
                </InnerWrapper>
            </Content>
        </StyledForm>
    );
};