import {action, autorun, makeAutoObservable} from "mobx";
import {INetworkListResponse} from "../../service/network-api/models";
import {createContext} from "react";

const initialState: INetworkListResponse = {
    items: []
};

class NetworkStorage {
    networkList: INetworkListResponse = localStorage.networkList
        ? JSON.parse(localStorage.networkList)
        : initialState;

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        autorun(() => {
            localStorage.networkList = JSON.stringify(this.networkList);
        });
    }

    @action
    setNetworkList(data: INetworkListResponse) {
        this.networkList = data;
    }
}

export default createContext(new NetworkStorage());