import {Controller, FieldValues, useForm} from "react-hook-form";
import {ValidationInputComponent} from "../../global/ValidationInputComponent";
import {ErrorText, FlexRow, StyledRegisterButton} from "../../../style/projectComponents";
import React, {FC, useContext, useState} from "react";
import styled from "styled-components";
import {RegisterContext, STAGE} from "../../../pages/RegisterPage";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {baseInputs} from "./FirstUtils";
import {DatePicker} from "../../global/DatePicker";

const InputWrapper = styled(FlexRow)<{ lng?: string }>`
  position: relative;
  align-items: start;
  width: 300px;
  @media (max-width: 480px) {
    label {
      left: ${props => props.lng.toLowerCase() === 'ar' ? '' : '20px'};
      right: ${props => props.lng.toLowerCase() === 'ar' ? '20px' : ''};
    }
  }
`;

export type IFirstStepProps = {
    setRegStage: (e: STAGE) => void;
}

type IFormValues = {
    firstName: string,
    lastName: string,
    middleName: string,
    birthDate: string
}

export type IStartInput = {
    name: "firstName" | "lastName" | "middleName" | "birthDate",
    label: string,
    type: string,
    rules: FieldValues
}

const FirstStep: FC<IFirstStepProps> = (props) => {
    const {setRegStage} = props;

    const {formUser, setFormUser} = useContext(RegisterContext);

    const {control, setValue, register, watch, handleSubmit, formState: {errors}} = useForm<IFormValues>({
        defaultValues: {
            firstName: formUser.firstName,
            lastName: formUser.lastName,
            middleName: formUser.middleName,
            birthDate: formUser.birthDate
        }
    });

    const onSubmit = (data) => {
        setFormUser({...formUser, ...data});
        setRegStage(STAGE.FINAL);
    }

    const [openBirthdate, setOpenBirthdate] = useState(false);

    const {t, i18n} = useTranslation();

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}>
            {openBirthdate &&
                <DatePicker
                    onFocus={(e) => {
                        setOpenBirthdate(true);
                    }}
                    onBlur={(e) => {
                        setOpenBirthdate(false);
                    }}
                    open={openBirthdate}
                    setOpen={setOpenBirthdate}
                    date={watch('birthDate')}
                    setDate={setValue}
                />}

            {baseInputs.map((it, idx) =>
                <>
                    <InputWrapper
                        lng={i18n.language}
                        style={{marginBottom: 5, marginTop: idx !== 0 && 10}}>
                        <Controller
                            name={it.name}
                            control={control}
                            rules={it.rules}
                            render={({field: {ref, ...field}}) =>
                                <ValidationInputComponent
                                    {...field}
                                    onFocus={(e) => {
                                        it.name === 'birthDate' && setOpenBirthdate(true);
                                    }}
                                    type={it.type}
                                    isError={!!errors[it.name]}
                                    label={t(it.label)}/>
                            }
                        />
                    </InputWrapper>
                    <ErrorText hasError={!!errors[it.name]} style={{maxWidth: 300}}>
                        {t(errors[it.name]?.message?.toString())}
                    </ErrorText>
                </>
            )}
            <StyledRegisterButton type={'submit'}>
                {t("auth.continue")}
            </StyledRegisterButton>
        </form>
    );
};

export default observer(FirstStep);
