export const headerAuthLinks = [
    {
        to: '/dashboard',
        text: 'menu.dashboard'
    },
    {
        to: '/deposit',
        text: 'menu.deposit'
    },
    {
        to: '/withdraw',
        text: 'menu.withdraw'
    },
    {
        to: '/settings',
        text: 'menu.settings'
    },
    {
        to: '/',
        text: 'menu.about'
    },
];

export const headerNotAuthLinks = [
    {
        to: 'profit',
        text: 'menu.profit'
    },
    {
        to: 'principles',
        text: 'menu.principles'
    },
    {
        to: 'plans',
        text: 'menu.plans'
    },
    {
        to: 'cooperation',
        text: 'menu.cooperation'
    },
];

export const adminLinks = [
    {
        to: "/admin/analytics",
        text: 'menu.dashboard'
    },
    {
        to: "/admin/withdraw",
        text: 'menu.withdrawRequests'
    },
    {
        to: "/admin/withdraw-history",
        text: 'menu.withdrawHistory'
    },
    {
        to: "/admin/operations",
        text: 'menu.historyOperations'
    }
];