import styled from "styled-components";
import {StyledSection} from "../../../global/StyledSection";
import {FlexColumn, FlexColumnStart} from "../../../../style/projectComponents";
import {colors} from "../../../../style/colors";
import {First} from "./First";
import {Second} from "./Second";
import {Third} from "./Third";

const ActualSectionPage = styled(StyledSection)`
  max-width: 100vw;
  align-items: center;
  width: 100vw;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  border-top: 0.0625rem solid ${colors.lightGrayBg};
  border-bottom: 0.0625rem solid ${colors.lightGrayBg};

`

const BordersContent = styled(FlexColumn)`
  max-width: 120rem;
  align-items: center;
  position: relative;
  border: 0.0625rem solid ${colors.lightGrayBg};
  border-top-style: none;
  border-bottom-style: none;
  width: 100%;
  box-sizing: border-box;
`

const ActualContent = styled(FlexColumnStart)`
  width: 100%;
  max-width: 81.25rem;
  box-sizing: content-box;
  margin: 0 auto;
  position: relative;
  padding: 0rem 1.25rem;
  row-gap: 7.1875rem;
`


export const ActualSection = () => {
    return (
        <ActualSectionPage id={'actual'}>
            <BordersContent>
                <ActualContent>
                    <First/>
                    <Second/>
                    <Third/>
                </ActualContent>
            </BordersContent>
        </ActualSectionPage>
    );
};

