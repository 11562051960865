import styled from "styled-components";
import {FlexColumn, FlexRowBetweenResponsive} from "../../style/projectComponents";
import HeaderComponent from "../../components/global/HeaderComponent";
import {StatisticsComponent} from "../../components/DashboardComponents/StatisticsComponent";
import toLocaleNumber from "../../utils/toLocaleNumber";
import React, {useContext, useEffect, useState} from "react";
import {colors} from "../../style/colors";
import {useTranslation} from "react-i18next";
import ProfileStorage from "../../storage/ProfileStorage/ProfileStorage";
import {useMutation, useQuery} from "react-query";
import AdminApi from "../../service/admin-api/AdminApi";
import {
    GetFullStatisticAdmin,
    IGetAllOperationsListAdminReq,
    IGetAllOperationsListAdminResp
} from "../../service/admin-api/models";
import {AdminAnalyticsBase} from "../../components/AdminComponents/AdminAnalytics/AdminAnalyticsBase";
import UserApi from "../../service/user-api/UserApi";
import {IUserListResponse} from "../../service/user-api/models";

const Page = styled(FlexColumn)`
  width: 100%;
  background-color: ${colors.pageBg};
`;

const StyledBg = styled(FlexColumn)`
  background-color: ${colors.pageBg};
  width: 100vw;
  padding: 24px;
  height: auto;
  box-sizing: border-box;
  min-height: 100vh;
  justify-content: flex-start;
  margin-top: 110px;
  @media (min-width: 780px) and (max-width: 1100px) {
    padding: 3.5rem;
  }
`;
const BalanceChartWrapper = styled(FlexColumn)`
  width: 100%;
  max-height: 380px;
  max-width: 1100px;
  justify-content: start;
  background: #fff;
  padding: 26px 16px;
  margin: 24px;
  border-radius: 8px;
  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`;
const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1100px;
  justify-content: start;
  background: #fff;
  padding: 26px 16px;
  border-radius: 8px;
  max-height: 740px;
  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`;

const ChartTitle = styled.p`
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-top: 18px;
  padding-bottom: 18px;
  @media (min-width: 780px) and (max-width: 1100px) {
    font-size: 1.25rem;
  }
`;

const StatsWrapper = styled(FlexRowBetweenResponsive)`
  max-width: 1130px;
  margin-bottom: 3.75rem;
  margin-top: 20px;
  @media (min-width: 780px) and (max-width: 1100px) {
    gap: 2rem;
    h1 {
      font-size: 1.125rem;
    }

    p {
      font-size: 0.75rem;
    }
  }

  @media (min-width: 480px) and (max-width: 780px) {
    gap: 2rem;
    font-size: 1.25rem;
  }
  @media (max-width: 768px) {
    flex-direction: row;
  }

  @media (max-width: 480px) {
    gap: 1.25rem;
    width: 100%;
    flex-direction: column;
  }
`

export const AdminAnalytics = () => {
    const {t} = useTranslation();
    const ProfileStore = useContext(ProfileStorage);
    const [dashboardData, setDashboardData] = useState<GetFullStatisticAdmin | null>(null);
    const [usersData, setUsersData] = useState<IUserListResponse | null>(null);
    const [bybitBal, setBybitBal] = useState(0);

    const getDashboardItemsQuery = useQuery(['dashboard_items'], () => AdminApi.getFullStatistic(), {
        onSuccess: (data) => {
            setDashboardData(data);
        },
        retry: 1
    });
    const getUsersItemsQuery = useQuery(['users_list'], () =>
        UserApi.getAllUsersList(), {
        onSuccess: data => {
            setUsersData(data)
        }
    });
    const getBybitBalanceQuery = useQuery(['bybit_balance'], () => AdminApi.getBybitBalance(), {
        onSuccess: (data) => {
            setBybitBal(data?.balance);
        },
        retry: 1
    });
    const totalBalance = dashboardData?.balancesStatistics[0]?.summaryBalance || 0

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page>
            <HeaderComponent/>
            <StyledBg>
                <StatsWrapper>
                    <StatisticsComponent
                        title={usersData?.users?.length.toString() || "0"}
                        description={t("adminAnalytics.totalUsers")}/>
                    <StatisticsComponent
                        title={`$ ${toLocaleNumber(totalBalance)}`}
                        description={t("adminAnalytics.totalDeposits")}/>
                    <StatisticsComponent
                        title={`$ ${toLocaleNumber(bybitBal)}`}
                        description={t("adminAnalytics.tvl")}/>
                </StatsWrapper>

                <AdminAnalyticsBase
                    isDashboardLoading={getDashboardItemsQuery.isLoading}
                    isUsersLoading={getUsersItemsQuery.isLoading}
                    dashboardData={dashboardData}
                    usersData={usersData}/>
            </StyledBg>
        </Page>
    );
};