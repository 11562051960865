import React, {ComponentPropsWithoutRef, useContext, useState} from "react";
import styled, {css} from "styled-components";
import {FlexRow, FlexRowBetween} from "../../style/projectComponents";
import {colors} from "../../style/colors";
import {useTranslation} from "react-i18next";
import {IStatusOperation} from "../../service/user-api/models";
import {format} from "date-fns";
import {ru, enGB} from "date-fns/locale";
import LanguageStorage from "../../storage/LanguageStorage/LanguageStorage";
import {IWithdrawalStatus} from "../../service/withdrawal-api/models";


type OperationItemComponentProps = {
    keys: string[];
    operation: any;
    openErrorMessageId: number;
    setOpenErrorMessageId: (e: any) => void;
    isReport?: boolean;
    openErrorMessage: boolean;
    setOpenErrorMessage: (e: any) => void;
}

const Block = styled(FlexRowBetween)`
  position: relative;
  width: 100%;
  padding: 0 12px;
  height: 45px;
  border: 1px solid ${colors.pageBg};
  align-items: center;
  border-radius: 4px;
  margin: 3px;

  @media (max-width: 768px) {
    width: auto;
    min-width: 100%;
    gap: 30px;
  }

  p {
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  :hover {
    p {
      color: ${colors.primary};
      transition: all 0.3s ease;
    }
  }
`

type DeclinedWrapperProps = {
    color: string,
    status: IStatusOperation | IWithdrawalStatus
}

const DeclinedWrapper = styled(FlexRow)<DeclinedWrapperProps>`
  cursor: pointer;
  width: 150px;
  text-align: right;
  justify-content: flex-end;
  color: ${props => props.color};

  img {
    width: 1rem;
    height: 1rem;
  }
`

const ErrorNotification = styled(FlexRow)<{ status?: IStatusOperation, lng?: string }>`
  position: absolute;
  background-color: white;
  padding: 10px;
  top: 45px;
  z-index: 10;
  color: ${props => props.status === IStatusOperation.approved ? 'black' : colors.redFail};
  border-radius: 0.15169rem;
  border: 1px solid ${colors.pageBg};

  ${({lng}) => lng === 'ar'
          ? css`
            left: 5px;
          `
          : css`
            right: 5px;
          `}
  img {
    width: 1rem;
    height: 1rem;
    margin-right: 5px;
  }

  p {
    color: ${props => props.status === IStatusOperation.approved ? 'black' : colors.redFail};
  }
`

const switchStatus = (status: IStatusOperation) => {
    switch (status) {
        case IStatusOperation.approved:
            return 'black';
        case IStatusOperation.notApproved:
            return 'gray';
        case IStatusOperation.rejected:
            return colors.redFail;
        case IStatusOperation.cancelled:
            return colors.redFail;
        case IStatusOperation.undefined:
            return '#6893DB';
    }
}
export const OperationItem: React.FC<ComponentPropsWithoutRef<'div'> & OperationItemComponentProps> = (
    props
) => {
    const {
        operation,
        openErrorMessageId,
        setOpenErrorMessageId,
        openErrorMessage,
        setOpenErrorMessage,
        isReport,
        keys,
        ...other
    } = props;

    const LanguageStore = useContext(LanguageStorage);
    const isRuLanguage = LanguageStore.language === "ru"
    const getDate = (date: string) => format(new Date(date), 'dd MMM yy. HH:mm', {locale: isRuLanguage ? ru : enGB})
    const isDateBlock = (it) => it.toLowerCase().includes('date') || it.toLowerCase().includes('createdon')

    const prices = ['balance', 'commission', 'profit', 'withdraw', 'deposit'];
    const blockText = (it) => isDateBlock(it)
        ? getDate(operation[it])
        : it.toLowerCase().includes('type')
            ? t(`common.${operation[it]}`)
            : (prices.find(name => it.toLowerCase().includes(name))) && !it.toLowerCase().includes('percent')
                ? `$ ${operation[it].toFixed(2)}`
                : it.toLowerCase().includes('percent')
                    ? `${operation[it].toFixed(2)}%`
                    : operation[it]


    const {t, i18n} = useTranslation();

    const handleSwitch = () => {
        setOpenErrorMessage(prevState => {
            return Number(other.id) === openErrorMessageId
                ? !prevState
                : openErrorMessage
                    ? prevState
                    : !prevState;
        });
        setOpenErrorMessageId(() => other.id);
    };

    return (
        <Block
            {...other}>
            {keys
                .filter(it => !it.toLowerCase().includes('status'))
                .map((it, idx) =>
                    <p style={{
                        width: isReport ? 120 : 160,
                        textAlign: idx === keys.length - 1 ? 'right' : 'left',
                        color: it === 'balanceDiff'
                            ? operation[it] >= 0
                                ? colors.greenSuccess
                                : colors.redFail
                            : it === 'balance'
                                ? colors.primary
                                : 'black'
                    }}>
                        {blockText(it)}
                    </p>
                )}
            {keys.find(it => it === 'status') &&
                <DeclinedWrapper
                    color={switchStatus(operation.status)}
                    status={operation.status}>
                    <p style={{
                        marginRight: 5
                    }}>{t(`common.${operation.status}`)}</p>
                    {
                        operation.comment?.length > 0 &&
                        <img onClick={handleSwitch}
                             src={'/images/info.svg'}/>
                    }
                </DeclinedWrapper>}

            {openErrorMessage &&
                Number(other.id) === openErrorMessageId &&
                <ErrorNotification lng={i18n.language} status={operation.status}>
                    <img src={'/images/info.svg'} alt={'info ico'}/>
                    <p>{operation.comment}</p>
                </ErrorNotification>
            }
        </Block>
    );
};
