
export const bitcoinAddressRegex = /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/;
export const ethereumAddressRegex = /^0x[a-fA-F0-9]{40}$/;
export const tronAddressRegex = /^[T][a-zA-Z0-9]{33}$/;

export const isEthWallet = (address: string) => {
    return ethereumAddressRegex.test(address);
}
export const isBtcWallet = (address: string) => {
    return bitcoinAddressRegex.test(address);
}