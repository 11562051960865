import React, {useContext, useEffect, useState} from "react";
import {FlexColumn, Page, StatsWrapper} from "../style/projectComponents";
import HeaderComponent from "../components/global/HeaderComponent";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {colors} from "../style/colors";
import {ChartComponent} from "../components/DashboardComponents/ChartComponent";
import {StatisticsComponent} from "../components/DashboardComponents/StatisticsComponent";
import toLocaleNumber from "../utils/toLocaleNumber";
import {useTranslation} from "react-i18next";
import {useMutation} from "react-query";
import UserApi from "../service/user-api/UserApi";
import {
    IDashboardItemsRequest,
    IDashboardItemsResponse,
    IGetUserOperationsRequest,
    IGetUserOperationsResponse,
    IGetUserReportRequest,
    IStatusOperation,
} from "../service/user-api/models";
import ProfileStorage from "../storage/ProfileStorage/ProfileStorage";
import {DataEmptyComponent} from "../components/global/DataEmptyComponent";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import {UserOperationsComponent} from "../components/DashboardComponents/UserOperationsComponent";
import {UserReportsComponent} from "../components/DashboardComponents/UserReportsComponent";
import {Spacer} from "../components/global/Spacer";


const StyledBg = styled(FlexColumn)`
  background: ${colors.pageBg};
  box-sizing: border-box;
  width: 100vw;
  padding: 24px;
  height: auto;
  min-height: 100vh;
  justify-content: flex-start;
  margin-top: 110px;
  padding-bottom: 100px;
  @media (min-width: 780px) and (max-width: 1100px) {
    padding: 3.5rem;
  }

`;
const BalanceChartWrapper = styled(FlexColumn)`
  width: 100%;
  max-height: 420px;
  max-width: 1300px;
  justify-content: start;
  background: #fff;
  padding: 26px 16px;
  margin: 3.12rem;
  margin-bottom: 0;
  border-radius: 8px;
`;

const ChartTitle = styled.p`
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-top: 18px;
  padding-bottom: 18px;
  @media (min-width: 780px) and (max-width: 1100px) {
    font-size: 1.25rem;
  }
`;


const DashboardPage: React.FC = () => {

    const {t} = useTranslation();
    const getDate = (date: string) => format(new Date(date), 'dd.MM.yy', {locale: ru});
    const ProfileStore = useContext(ProfileStorage);
    const walletId = ProfileStore.profile?.wallets?.find(it => it.currency === 'USDT')?.id;
    const profileID = ProfileStore.profile?.id;

    const getAccrualOfInterestDashboardItemsQuery = useMutation((data: IDashboardItemsRequest) => UserApi.getAccrualOfInterestDashboardItems(data));
    const getDashboardItemsQuery = useMutation((data: IDashboardItemsRequest) => UserApi.getDashboardItems(data));
    const getOperationsItemsQuery = useMutation((data: IGetUserOperationsRequest) => UserApi.getUserOperationsList(data));
    const getReportsItemsQuery = useMutation((data: IGetUserReportRequest) => UserApi.getUsersReport(data));

    const [dashboardData, setDashboardData] = useState<IDashboardItemsResponse | { items?: { balanceDiff?: number }[] }>({
        items: []
    });
    const [dividendsDashboardData, setDividendsDashboardData] = useState({
        items: []
    });
    const [operationsData, setOperationsData] = useState<IGetUserOperationsResponse>({
        items: [],
        totalCount: 0
    });
    const [reportsData, setReportsData] = useState({
        items: []
    });

    useEffect(() => {
        if (!!walletId) {
            const dashboardReq: IDashboardItemsRequest = {
                takeLast: 200,
                walletId: walletId
            };
            getDashboardItemsQuery.mutate(dashboardReq, {
                onSuccess: (data) => {
                    setDashboardData({
                        ...data,
                        items: data.items.map((it, idx) => ({
                            ...it,
                            date: it.date,
                            balance: it.balance + it.withdrawalBalance,
                            balanceDiff: idx > 0 ? Math.abs(it.balance - data.items.at(idx - 1).balance).toFixed(2) : it.balance
                        }))
                    });
                }
            });
            getAccrualOfInterestDashboardItemsQuery.mutate(dashboardReq, {
                onSuccess: (data) => {
                    setDividendsDashboardData(data);
                }
            });

            const getLastDayPrevMonth = () => {
                const currentDate: Date = new Date();
                const firstDayOfCurrentMonth: Date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                const lastDayOfPreviousMonth: Date = new Date(firstDayOfCurrentMonth);
                const formattedDate: string = lastDayOfPreviousMonth.toISOString().split('T')[0];
                return formattedDate
            }

            const reportsReq: IGetUserReportRequest = {
                userId: profileID,
                walletId: walletId,
                dateFrom: "2024-02-01",
                dateTo: getLastDayPrevMonth()
            };
            getReportsItemsQuery.mutate(reportsReq, {
                onSuccess: data => {
                    setReportsData(data);
                }
            });
        }
    }, [walletId]);

    useEffect(() => {
        if (!!profileID) {
            const operationsReq: IGetUserOperationsRequest = {
                statuses: Object.values(IStatusOperation),
                skip: 0,
                take: 200,
                userId: profileID
            };
            getOperationsItemsQuery.mutate(operationsReq, {
                onSuccess: data => {
                    setOperationsData(data);
                }
            });
        }
    }, [profileID]);

    const userBalance = ProfileStore?.profile?.wallets?.find(it => it.currency === 'USDT')?.balance || 0;
    const withdrawalBalance = ProfileStore?.profile?.wallets?.find(it => it.currency === 'USDT')?.withdrawalBalance || 0;
    const efficientUserBalance = ProfileStore?.profile?.wallets?.find(it => it.currency === 'USDT')?.efficientBalance || 0;
    const prevDividendsUSD = ProfileStore?.profile?.wallets?.find(it => it.currency === 'USDT')?.lastAccrualOfInterest || 0;
    const prevDividendsPercent = ProfileStore?.profile?.wallets?.find(it => it.currency === 'USDT')?.lastAccrualOfInterestPercents?.toFixed(2) || 0
    const userTotalProfit = ProfileStore?.profile?.wallets?.find(it => it.currency === 'USDT')?.totalAccrualOfInterest || 0

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page>
            <HeaderComponent/>
            <StyledBg>
                <StatsWrapper>
                    <StatisticsComponent
                        title={`$${toLocaleNumber(efficientUserBalance)} / $${toLocaleNumber(userBalance)}`}
                        description={t("dashboard.balance")}/>
                    <StatisticsComponent
                        title={`$${toLocaleNumber(withdrawalBalance)} / $${toLocaleNumber(userTotalProfit)}`}
                        description={`${t("dashboard.withdrawProfit")} / ${t("dashboard.total_profit")}`}/>
                    <StatisticsComponent
                        title={`$${toLocaleNumber(prevDividendsUSD)} (${prevDividendsPercent}%)`}
                        description={t("dashboard.prev_dividends")}/>
                </StatsWrapper>
                <BalanceChartWrapper>
                    <ChartTitle>{t("dashboard.chart_title")}</ChartTitle>
                    {dashboardData?.items?.length > 0
                        ? <ChartComponent
                            titleKey={[t("common.balanceDiff"), 'operationType', t('common.startingBalance')]}
                            valueKey={['balance', "efficientBalance", '']}
                            xAxisKey={'date'}
                            yAxisKey={'balance'}
                            data={dashboardData.items
                                .map(it => ({...it, date: getDate(it.date)}))
                                .flatMap((it, idx, array) => {
                                    return {
                                        ...it,
                                        amountDiff: idx > 0 ? it.balance - array[idx - 1].balance : 0
                                    }
                                })
                        }/>
                        : <DataEmptyComponent isLoading={getDashboardItemsQuery.isLoading} isChart/>
                    }
                </BalanceChartWrapper>
                <BalanceChartWrapper>
                    <ChartTitle>{t("dashboard.divHistory")}</ChartTitle>
                    {dividendsDashboardData?.items?.length > 0
                        ? <ChartComponent
                            titleKey={[t("common.balanceDiffPercent"), t("dashboard.filter.dividends")]}
                            valueKey={['balanceDiffPercent', 'balanceDiff']}
                            xAxisKey={'date'}
                            yAxisKey={'balanceDiffPercent'}
                            variant={'linear'}
                            data={dividendsDashboardData.items
                                .map(it => ({...it, date: getDate(it.date)}))
                                .flatMap((it, idx, array) => {
                                    return {
                                        ...it,
                                        amountDiff: idx > 0 ? it.balanceDiffPercent - array[idx - 1].balanceDiffPercent : 0
                                    }
                                })
                        }/>
                        : <DataEmptyComponent isLoading={getDashboardItemsQuery.isLoading} isChart/>
                    }
                </BalanceChartWrapper>
                <UserOperationsComponent operations={operationsData} isLoading={getOperationsItemsQuery.isLoading}/>
                <Spacer />
                <Spacer />
                <UserReportsComponent operations={reportsData} isLoading={getReportsItemsQuery.isLoading}/>
            </StyledBg>
        </Page>
    );
};

export default observer(DashboardPage);
