import React, {FC} from "react";
import {OperationsComponent} from "./OperationsComponent";
import {DataEmptyComponent} from "../global/DataEmptyComponent";
import {FlexColumn} from "../../style/projectComponents";
import styled from "styled-components";
import {colors} from "../../style/colors";
import {useTranslation} from "react-i18next";
import {IGetUserReportResponse} from "../../service/user-api/models";

const StyledBg = styled(FlexColumn)`
  background: ${colors.pageBg};
  width: 100vw;
  padding: 24px;
  height: 100%;
  margin-top: 110px;
  justify-content: flex-start;
`;

const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1300px;
  justify-content: start;
  background: #fff;
  padding: 26px 16px;
  border-radius: 8px;
  max-height: 740px;
  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`;

const ChartTitle = styled.p`
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-top: 18px;
  padding-bottom: 18px;
`;

type UserReportsComponentProps = {
    operations?: IGetUserReportResponse;
    isLoading?: boolean;
}
export const UserReportsComponent: FC<UserReportsComponentProps> = (
    {operations, isLoading}
) => {
    const {t, i18n} = useTranslation();

    const operationsItems = operations?.items || []
    const reverseOperationsItems = [...operationsItems].reverse()

    return (
        <OperationsWrapper>
            <ChartTitle>{t('dashboard.reports')}</ChartTitle>
            {operations.items.length > 0 ? <OperationsComponent
                    isReport
                    scrollAllSizes={true}
                    keys={[
                        "periodStart",
                        "periodEnd",
                        "effectiveBalanceStart",
                        "balanceStart",
                        "balanceEnd",
                        "balanceDifference",
                        "balanceDifferencePercent",
                        "drawdownPercent",
                        "commission",
                        "balanceEndNoCommission",
                        "totalProfit",
                        "depositsMinusWithdrawalsTotal",
                    ]}
                    operations={reverseOperationsItems}/>
                : <DataEmptyComponent isLoading={isLoading}/>
            }
        </OperationsWrapper>
    );
};
