import {FlexColumnStart, FlexRow, FlexRowBetween} from "../../../../style/projectComponents";
import styled from "styled-components";
import {colors} from "../../../../style/colors";
import {useTranslation} from "react-i18next";
import {useRef} from "react";
import {useIsVisible} from "../../../../hooks/useIsVisible";

const ForWhoContent = styled(FlexColumnStart)<{ lng?: string }>`
  max-width: 53.75rem;
  width: 100%;
  padding-top: 7.5rem;
  margin-left: ${props => props.lng.toLowerCase() === 'ar' ? '' : 'auto'};
  margin-right: ${props => props.lng.toLowerCase() === 'ar' ? 'auto' : ''};
  position: relative;
  align-items: flex-start;
  padding-bottom: 7.5rem;

  @media (max-width: 480px) {
    flex-direction: column-reverse;
    gap: 3rem;
  }
`

const LeftFigureWrapper = styled.div<{ lng?: string }>`
  position: absolute;
  left: ${props => props.lng.toLowerCase() === 'ar' ? '' : '-46.875rem'};
  right: ${props => props.lng.toLowerCase() === 'ar' ? '-46.875rem' : ''};
  bottom: 0;
  width: 39.375rem;
  height: 26rem;

  @media (max-width: 480px) {
    position: static;
  }
`

const LeftTopFigure = (
    <svg className="about-block__bottom-decor-pc" width="630" height="416" viewBox="0 0 630 416" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0.5H630" stroke="#DEDDD8"></path>
        <path d="M0 208.5H630" stroke="#DEDDD8"></path>
        <path d="M629.5 0V416" stroke="#DEDDD8"></path>
        <circle cx="422" cy="208" r="207.5" stroke="#DEDDD8"></circle>
    </svg>
)

const Circle = (
    <svg height="20" width="20">
        <circle cx="10" cy="10" r="5" stroke={colors.primary} stroke-width="1" fill={'rgba(0, 0, 0, 0)'}/>
    </svg>
)

const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 5rem;
  text-align: left;
  font-size: 2.125rem;
  font-weight: 300;
  line-height: 105%;
  width: 13.875rem;
  min-width: 226px;

`

const Inner = styled(FlexRow)`
  column-gap: 5.375rem;
  align-items: flex-start;

  .desc {
    row-gap: 0.625rem;

    span {
      font-size: 1rem;
      color: #AAB0B8;
    }

    p {
      font-size: 1.125rem;
      text-align: start;
      padding-right: 40px;
      width: 100%;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`

const PointWrapper = styled(FlexRow)`
  gap: 1rem;
  align-items: flex-start;
  margin-bottom: 5px;

  .circleWrapper {
    position: relative;
    width: 20px;
    height: 20px;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
`

const items = [
    {
        title: 'landing.eith.3.items.1.title',
    },
    {
        title: 'landing.eith.3.items.2.title',
    },
    {
        title: 'landing.eith.3.items.3.title',
    },
    {
        title: 'landing.eith.3.items.4.title',
    },
    {
        title: 'landing.eith.3.items.5.title',
    },
];

export const Third = () => {

    const {t, i18n} = useTranslation();

    const ref = useRef();
    const isVisible = useIsVisible(ref);
    return (
        <ForWhoContent lng={i18n.language}>
            <LeftFigureWrapper
                lng={i18n.language}
                ref={ref}
                className={isVisible ? "element-show" : ""}>
                {LeftTopFigure}
            </LeftFigureWrapper>
            <Inner>
                <Title>
                    {t("landing.eith.3.forWho")}
                </Title>
                <FlexColumnStart className={'desc'}>
                    <span>
                        {t("landing.eith.3.forWhoFound")}
                    </span>
                    {items.map((it, idx) =>
                        <PointWrapper>
                            <div className={'circleWrapper'}>{Circle}</div>
                            <p>
                                {t(it.title)}
                            </p>
                        </PointWrapper>
                    )}
                </FlexColumnStart>
            </Inner>
        </ForWhoContent>
    );
};
