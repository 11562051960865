import {FlexColumn, FlexColumnStart, FlexRow} from "../../../../style/projectComponents";
import React, {FC} from "react";
import styled from "styled-components";
import {StyledSection} from "../../../global/StyledSection";
import {colors} from "../../../../style/colors";
import {BottomInvestRectangle, BottomLeftInvestRectangle, LeftCircle, RightCircle} from "./utils";
import {First} from "./First";
import {Second} from "./Second";
import {Third} from "./Third";
import {Fourth} from "./Fourth";


const InvestmentSectionPage = styled(StyledSection)`
  max-width: 100vw;
  align-items: center;
  width: 100vw;
  padding-left: 0;
  padding-right: 0;
`

const Content = styled(FlexColumn)`
  //padding-right: 1.25rem;
  //padding-left: 1.25rem;
  width: 100%;
  align-items: center;
`


export const InvestmentSection: FC = () => {
    return (
        <InvestmentSectionPage id={'investments'}>
            <Content>
                <First/>
                <Second/>
                <Third/>
                <Fourth/>
            </Content>
        </InvestmentSectionPage>
    );
};