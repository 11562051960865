import styled from "styled-components";
import {StyledSection} from "../../global/StyledSection";
import {FlexColumn, FlexColumnStart, FlexRow, StyledLink} from "../../../style/projectComponents";
import {colors} from "../../../style/colors";
import {useTranslation} from "react-i18next";

const TeamSectionPage = styled(StyledSection)`
  max-width: 100vw;
  align-items: center;
  width: 100vw;
  padding-left: 0;
  padding-right: 0;
  position: relative;
`;

const BordersContent = styled(FlexColumn)`
  max-width: 120rem;
  align-items: center;
  position: relative;
  border: 0.0625rem solid ${colors.lightGrayBg};
  border-top-style: none;
  border-bottom-style: none;
  width: 100%;
  box-sizing: border-box;
`;

const TeamContent = styled(FlexColumnStart)`
  width: 100%;
  max-width: 81.25rem;
  box-sizing: content-box;
  margin: 0 auto;
  position: relative;
  padding: 15rem 1.25rem 15rem;

  @media (max-width: 480px) {
    margin: 0 1.25rem;
    align-items: center;
  }
`;

const Title = styled.h3`
  margin-bottom: 5rem;
  font-size: 3.125rem;
  font-weight: 300;
  line-height: 105%;

  @media (max-width: 900px) {
    align-self: start;
    padding-left: 1.25rem;
  }
  @media (max-width: 480px) {
    font-size: 2.125rem;
  }
`;

const CardWrapper = styled(FlexRow)`
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  row-gap: 1.25rem;
  margin: 0 -0.625rem;
  width: 100%;

  @media (min-width: 900px) {
    align-items: stretch;
  }

  * {
    box-sizing: border-box;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const CardLayout = styled(FlexColumnStart)`
  border: 0.0625rem solid #DEDDD8;
  padding: 1.875rem;
  box-sizing: border-box;
  height: 100%;
  justify-content: space-between;

  .avatar {
    width: 100%;
    height: 26.875rem;
    object-fit: cover;
    filter: grayscale(100%);
    transition: all 0.3s ease 0s;

    @media (min-width: 450px) and (max-width: 900px) {
      width: auto;
      height: 30rem;
    }
  }

  :hover {
    .avatar {
      filter: grayscale(0%);
    }
  }

  .bio {
    gap: 1rem;
    margin-top: 1.875rem;

    h3 {
      font-size: 1.750rem;
      font-weight: 300;
      margin: 0;
      padding: 0;
      text-align: left;
    }

    p {
      text-align: left;
      font-size: 1.125rem;
      color: #AAB0B8;
      margin: 0;
      padding: 0;
    }
  }

  .links {
    margin-top: 1.25rem;
    align-items: center;
    gap: 1.25rem;
  }
`;

const Link = styled(StyledLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border: 0.125rem solid #00AAC3;
  border-radius: 50%;
  transition: border 0.2s, color 0.2s ease 0.2s;

  :hover {
    color: #fff;
    border: 1.25rem solid #00AAC3;
  }
`;

const items = [
    {
        name: 'landing.seventh.items.1.name',
        avatar: '/landing_media/face0.png',
        position: 'landing.seventh.items.1.position',
        contacts: [
            'mailto:igor@kanna.capital',
            'https://www.linkedin.com/in/igor-lapin-9134a8264'
        ]
    },
    {
        name: 'landing.seventh.items.2.name',
        avatar: '/landing_media/face1.png',
        position: 'landing.seventh.items.2.position',
        contacts: [
            'mailto:margarita@kanna.capital',
            'https://www.linkedin.com/in/margarita-lapina-a56353271/'
        ]
    },
    {
        name: 'landing.seventh.items.3.name',
        avatar: '/landing_media/face2.png',
        position: 'landing.seventh.items.3.position',
        contacts: [
            "https://www.linkedin.com/in/ilia-pankov-7a4a7a104"
        ]
    },
    {
        name: 'landing.seventh.items.4.name',
        avatar: '/landing_media/face7.png',
        position: 'landing.seventh.items.4.position',
        contacts: [
            "https://www.linkedin.com/in/alexander-vitkovskiy"
        ]
    },
    {
        name: 'landing.seventh.items.5.name',
        avatar: '/landing_media/face4.png',
        position: 'landing.seventh.items.5.position',
        contacts: [
            'mailto:tatiana@kanna.capital'
        ]
    },
    {
        name: 'landing.seventh.items.6.name',
        avatar: '/landing_media/face5.jpg',
        position: 'landing.seventh.items.6.position',
        contacts: [
            'mailto:anna@kanna.capital'
        ]
    },
];

const CardItemInner = styled(FlexColumnStart)`
  flex: 1 1 33.333%;
  padding: 0 0.625rem;
`;
export const TeamSection = () => {
    const {i18n, t} = useTranslation();

    return (
        <TeamSectionPage id={'team'}>
            <BordersContent>
                <TeamContent>
                    <Title>
                        {t("landing.seventh.title")}
                    </Title>
                    <CardWrapper>
                        {items.map((it, idx) =>
                            <CardItemInner>
                                <CardLayout>
                                    <img className={'avatar'}
                                         src={it.avatar}/>
                                    <FlexColumnStart className={'bio'}>
                                        <h3>{t(it.name)}</h3>
                                        <p>{t(it.position)}</p>
                                    </FlexColumnStart>
                                    <FlexRow className={'links'}>
                                        {it.contacts.map((it, idx) =>
                                            <Link to={it}
                                                  className={it.includes('mailto') ? "_icon-email" : "_icon-linkedin"}/>
                                        )}
                                    </FlexRow>
                                </CardLayout>
                            </CardItemInner>
                        )}
                    </CardWrapper>
                </TeamContent>
            </BordersContent>
        </TeamSectionPage>
    );
};
