export const MainIllustrationPC = (
  <svg
    className="svgAnimated pc"
    width="1920"
    height="918"
    viewBox="0 0 1920 918"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
          <path d="M311 738H1189" stroke="#DEDDD8" />
          <path d="M311 558H1189" stroke="#DEDDD8" />
          <path d="M0 180H1189" stroke="#DEDDD8" />
          <path d="M0 917H1920" stroke="#DEDDD8" />
          <path d="M1708.5 1.5V917.5" stroke="#DEDDD8" />
          <path d="M750.5 180.5V916.5" stroke="#DEDDD8" />
          <path d="M310.5 180.5V916.5" stroke="#DEDDD8" />
          <path d="M0 360H310" stroke="#DEDDD8" />
          <path
            d="M689.5 369C689.5 473.378 603.885 558 499.5 558C395.118 558 310.5 473.382 310.5 369C310.5 264.618 395.118 180 499.5 180C603.885 180 689.5 264.622 689.5 369Z"
            stroke="#DEDDD8"
          />
          <path d="M1189 1H1920" stroke="#DEDDD8" />
          <path d="M1709 361H1920" stroke="#DEDDD8" />
          <path d="M1709 181H1920" stroke="#DEDDD8" />
          <path
            d="M310.5 558V558C139.292 558 0.5 696.792 0.5 868V917.5"
            stroke="#DEDDD8"
          />
          <path
            d="M1708 917C1455.62 917 1251 709.166 1251 457.075C1251 204.985 1455.62 1 1708 1"
            stroke="#00AAC3"
          />
          <path d="M1189.5 1.5V916.5" stroke="#00AAC3" />
  </svg>
);

export const MainIllustrationTablet = (
  <svg
    className="svgAnimated tablet"
    width="1000"
    height="585"
    viewBox="0 0 1000 585"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
          <path d="M153 448.5H553" stroke="#DEDDD8" />
          <path d="M0 310.5H553" stroke="#DEDDD8" />
          <path d="M0 155.5H553" stroke="#DEDDD8" />
          <path d="M553 1.5V584.5" stroke="#00AAC3" />
          <path d="M552.5 1L1000 1" stroke="#DEDDD8" />
          <path d="M0 584.5H1000" stroke="#DEDDD8" />
          <path d="M884 155.5H1000" stroke="#DEDDD8" />
          <path d="M884 309.5H1000" stroke="#DEDDD8" />
          <path d="M883.5 1V584" stroke="#DEDDD8" />
          <path d="M153.5 155V584" stroke="#DEDDD8" />
          <path d="M353.5 311V584" stroke="#DEDDD8" />
          <path
            d="M883 584.5C722.297 584.5 592 452.994 592 292.273C592 131.551 722.297 1 883 1"
            stroke="#00AAC3"
          />
          <path
            d="M153.5 233.006C153.5 275.268 119.242 310.5 77 310.5C34.7585 310.5 0.5 275.268 0.5 233.006C0.5 190.745 34.7586 155.5 77 155.5C119.241 155.5 153.5 190.745 153.5 233.006Z"
            stroke="#DEDDD8"
          />
          <path
            d="M153.5 448.5H137C61.6131 448.5 0.5 509.613 0.5 585V585"
            stroke="#DEDDD8"
          />
  </svg>
);
export const MainIllustrationPhone = (
  <svg
    className="svgAnimated phone"
    width="480"
    height="309"
    viewBox="0 0 480 309"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
          <path d="M0 176.5H305" stroke="#DEDDD8" />
          <path d="M67 242.5H305" stroke="#DEDDD8" />
          <path d="M0 308.5H480" stroke="#DEDDD8" />
          <path d="M0 110.5H305" stroke="#DEDDD8" />
          <path d="M66.5 111V308" stroke="#DEDDD8" />
          <path d="M198.5 177V308" stroke="#DEDDD8" />
          <path d="M132.5 111V309" stroke="#DEDDD8" />
          <path
            d="M198.5 242.505C198.5 278.484 168.151 308.5 131.982 308.5C95.8233 308.5 66.5 278.494 66.5 242.505C66.5 206.516 95.8234 176.5 131.982 176.5C168.151 176.5 198.5 206.527 198.5 242.505Z"
            stroke="#DEDDD8"
          />
          <path
            d="M67 242.5H66C29.8253 242.5 0.5 271.825 0.5 308V308"
            stroke="#DEDDD8"
          />
          <path d="M305.5 0.5H480" stroke="#DEDDD8" />
          <path
            d="M480 308.5C395.149 308.5 326 238.103 326 153.242C326 68.3809 395.149 0.5 480 0.5"
            stroke="#00AAC3"
          />
          <path d="M305 0V308" stroke="#00AAC3" />
  </svg>
);
export const MainIllustrationPhoneSmall = (
  <svg
    className="svgAnimated phoneSmall"
    width="360"
    height="309"
    viewBox="0 0 360 309"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
          <path d="M0 21.5H185" stroke="#DEDDD8" />
          <path d="M0 176.5H185" stroke="#DEDDD8" />
          <path d="M0 242.5H185" stroke="#DEDDD8" />
          <path d="M0 308.5H360" stroke="#DEDDD8" />
          <path d="M185 0V308" stroke="#00AAC3" />
          <path d="M20.5 1V309" stroke="#DEDDD8" />
          <path d="M102.5 177V243" stroke="#DEDDD8" />
          <path d="M0 0.5H360" stroke="#DEDDD8" />
          <path
            d="M360 308.5C275.149 308.5 206 238.103 206 153.242C206 68.3809 275.149 0.5 360 0.5"
            stroke="#00AAC3"
          />
          <path
            d="M101.5 136.003C101.5 158.078 82.8762 176.5 60.6808 176.5C38.4955 176.5 20.5 158.088 20.5 136.003C20.5 113.918 38.4956 95.5 60.6808 95.5C82.8761 95.5 101.5 113.928 101.5 136.003Z"
            stroke="#DEDDD8"
          />
  </svg>
);
