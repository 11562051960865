import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {QueryClient, QueryClientProvider} from "react-query";
import i18n from "./config/i18n";
import {I18nextProvider} from "react-i18next";
import App from "./App";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient();

root.render(
    <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
            <App/>
        </I18nextProvider>
    </QueryClientProvider>
);
