import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {FilterMethodButton} from "./FilterMethodButton";
import {OperationsComponent} from "./OperationsComponent";
import {DataEmptyComponent} from "../global/DataEmptyComponent";
import styled from "styled-components";
import {FlexColumn, FlexRow} from "../../style/projectComponents";


const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1300px;
  justify-content: start;
  background: #fff;
  padding: 26px 16px;
  border-radius: 8px;
  max-height: 820px;
  margin-top: 3.12rem;
`;
const ChartTitle = styled.p`
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-top: 18px;
  padding-bottom: 18px;
  @media (min-width: 780px) and (max-width: 1100px) {
    font-size: 1.25rem;
    margin-bottom: 5px;
  }
`;
const FilterTitle = styled.p`
  font-size: 14px;
  margin-right: 10px;
  @media(max-width: 780px) {
    display: none;
  }
`
const FilterMethods = styled(FlexRow)`
  @media(max-width: 780px) {
    width: 100%;
  }
`
const FilterWrapper = styled(FlexRow)`
  justify-content: end;
  width: 100%;
  margin-bottom: 24px;
  @media(max-width: 780px) {
    flex-direction: column;
  }
`

interface UserOperationsComponentProps {
    operations: any;
    isLoading: boolean;
}

export const UserOperationsComponent: React.FC<UserOperationsComponentProps> = ({operations, isLoading}) => {

    const {t} = useTranslation();

    const operationsFilterTypes = ["none", "deposit", "withdrawalRequest", "accrualOfInterest"]
    const operationsFilterNames = [t("dashboard.filter.all"),
        t("dashboard.filter.deposit"),
        t("dashboard.filter.withdrawal"),
        t("dashboard.filter.dividends")]

    const [filterId, setFilterId] = useState(0)

    const filteredOperationsData = (operations.items.length > 0
            && filterId > 0
            && {items: operations.items.filter(item => item.type === operationsFilterTypes[filterId])})
        || operations

    return (
        <OperationsWrapper>
            <ChartTitle>{t("dashboard.operations")}</ChartTitle>
            {operations?.items?.length > 0 && <FilterWrapper>
                <FilterTitle>{t("dashboard.filter.operationType")}</FilterTitle>
                <FilterMethods>
                    {operationsFilterNames.map((item, id) =>
                        <FilterMethodButton
                            title={item}
                            isActive={id === filterId}
                            setActiveTypeId={setFilterId}
                            id={id}
                        />
                    )}
                </FilterMethods>
            </FilterWrapper>}
            {filteredOperationsData?.items?.length > 0
                ? <OperationsComponent
                    keys={['operationDate', 'balanceDiff', 'status']}
                    operations={filteredOperationsData.items}/>
                : <DataEmptyComponent isLoading={isLoading}/>
            }
        </OperationsWrapper>
    );
};
