import {FlexColumnStart} from "../../../style/projectComponents";
import {CircleTitle, DocContent} from "../components";
import {useTranslation} from "react-i18next";

export const First = () => {

    const {t, i18n} = useTranslation();

    return (
        <DocContent>
            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.privacy.1.1")}
                </p>
            </FlexColumnStart>
            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.privacy.1.2")}
                </p>
            </FlexColumnStart>
            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.privacy.1.3")}
                </p>
            </FlexColumnStart>
            <FlexColumnStart className={'block'}>
                <span>
                    {t("documents.privacy.1.4")}
                </span>
                <p>
                    {t("documents.privacy.1.5")}
                </p>
            </FlexColumnStart>
            <FlexColumnStart className={'block'}>
                <span>
                    {t("documents.privacy.1.6")}
                </span>
                <p>
                    {t("documents.privacy.1.7")}
                </p>
            </FlexColumnStart>
            <FlexColumnStart className={'block'}>
                <span>
                    {t("documents.privacy.1.8")}
                </span>
                <p>
                    {t("documents.privacy.1.9")}
                </p>
            </FlexColumnStart>
            <FlexColumnStart className={'block'}>
                <span>
                    {t("documents.privacy.1.10")}
                </span>
                <p>
                    {t("documents.privacy.1.11")}
                </p>
            </FlexColumnStart>
            <FlexColumnStart className={'block'}>
                <span>
                  {t("documents.privacy.1.12")}
                </span>
                <p>
                    {t("documents.privacy.1.13")}
                </p>
            </FlexColumnStart>
            <FlexColumnStart className={'block'}>
                <span>
                    {t("documents.privacy.1.14")}
                </span>
                <p>
                    {t("documents.privacy.1.15")}

                    <CircleTitle text={t("documents.privacy.1.16")}/>
                    <CircleTitle text={t("documents.privacy.1.17")}/>

                    <CircleTitle text={t("documents.privacy.1.18")}/>

                    <CircleTitle text={t("documents.privacy.1.19")}/>

                    {t("documents.privacy.1.20")}
                </p>
            </FlexColumnStart>
        </DocContent>
    );
};