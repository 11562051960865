import HeaderComponent from "../components/global/HeaderComponent";
import React, {useContext, useEffect} from "react";
import styled from "styled-components";
import {AppStyle, FlexColumn} from "../style/projectComponents";
import {MainSection} from "../components/Home/sections/MainSection";
import {PrinciplesSection} from "../components/Home/sections/PrinciplesSection";
import {InvestmentSection} from "../components/Home/sections/InvestmentSection/InvestmentSection";
import {ProfitSection} from "../components/Home/sections/ProfitSection";
import {LeadersSection} from "../components/Home/sections/LeadersSection/LeadersSection";
import {DocumentSection} from "../components/Home/sections/DocumentSection";
import {TeamSection} from "../components/Home/sections/TeamSection";
import {ActualSection} from "../components/Home/sections/ActualSeection/ActualSection";
import {ProjectsSection} from "../components/Home/sections/ProjectsSection/ProjectsSection";
import {useTranslation} from "react-i18next";
import UserStorage from "../storage/UserStorage/UserStorage";
import LanguageStorage from "../storage/LanguageStorage/LanguageStorage";
import {useLocation} from "react-router-dom";
import {phoneCodes} from "../config/phoneCodes";

const StyledBg = styled(FlexColumn)<{ lng?: string }>`
  width: 100vw;
  height: auto;
  min-height: 100%;
  justify-content: center;
  max-width: 120rem;

  svg {
    transform: ${props => props.lng.toLowerCase() === 'ar' ? 'scale(-1, 1)' : ''};
  }

  * {
    z-index: 10;
  }
`;

export const HomePage = () => {
    const {i18n} = useTranslation();
    const UserStore = useContext(UserStorage);
    const LanguageStore = useContext(LanguageStorage);
    const isArLanguage = LanguageStore.language === "ar"
    const isAuth = !!UserStore.accessToken;


    return (
        <AppStyle
            isLandingPage={true}
            isArLanguage={isArLanguage}
            isAuth={isAuth}>
            <FlexColumn style={{width: "100vw"}}>
                <HeaderComponent/>
                <StyledBg lng={i18n.language}>
                    <MainSection/>
                    <PrinciplesSection/>
                    <InvestmentSection/>
                    <ProfitSection/>
                    <LeadersSection/>
                    <DocumentSection/>
                    <TeamSection/>
                    <ActualSection/>
                    <ProjectsSection/>
                </StyledBg>
            </FlexColumn>
        </AppStyle>

    )
}
