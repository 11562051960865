import {FlexColumn, FlexRow, StatsWrapper} from "../../style/projectComponents";
import {CommonInfoForm} from "../../components/AdminComponents/AdminUserDetail/CommonInfoForm";
import {BalanceAddForm} from "../../components/AdminComponents/AdminUserDetail/BalanceAddForm";
import {BalanceUserDashboard} from "../../components/AdminComponents/AdminUserDetail/BalanceUserDashboard";
import styled from "styled-components";
import React, {FC, useContext, useEffect, useState} from "react";
import {
    IGetUserOperationsRequest,
    IGetUserOperationsResponse, IGetUserReportRequest,
    IStatusOperation,
    IUserDetailsResponse
} from "../../service/user-api/models";
import {useParams} from "react-router-dom";
import HeaderComponent from "../../components/global/HeaderComponent";
import {colors} from "../../style/colors";
import {useMutation} from "react-query";
import UserApi from "../../service/user-api/UserApi";
import {StatisticsComponent} from "../../components/DashboardComponents/StatisticsComponent";
import toLocaleNumber from "../../utils/toLocaleNumber";
import {useTranslation} from "react-i18next";
import GlobalModalStore from "../../storage/GlobalModalStore/GlobalModalStore";
import {observer} from "mobx-react-lite";
import {PercentAddForm} from "../../components/AdminComponents/AdminUserDetail/PercentAddForm";
import {UserOperationsComponent} from "../../components/DashboardComponents/UserOperationsComponent";
import {UserReportsComponent} from "../../components/DashboardComponents/UserReportsComponent";

const Page = styled(FlexColumn)`
  width: 100vw;
  background-color: ${colors.pageBg};
`

const MainBlock = styled(FlexRow)`
  width: 100%;
  border-radius: 0.75rem;
  background-color: white;
  gap: 8rem;
  padding: 3.625rem 0 3.625rem 0;
  align-items: baseline;

  @media (max-width: 1100px) {
    gap: 3.12rem;
  }

  @media (max-width: 780px) {
    flex-direction: column;
    align-items: center;
    gap: 3.12rem;
  }


`

const BalanceBlock = styled(FlexRow)`
  align-items: start;
  gap: 3.75rem;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: 780px) {
    flex-direction: column;
    align-items: center;
    box-sizing: content-box;
  }
`

const StyledBg = styled(FlexColumn)`
  background-color: ${colors.pageBg};
  max-width: 1130px;

  gap: 3.75rem;
  width: 100%;
  padding: 24px;
  height: auto;
  box-sizing: border-box;
  min-height: 100vh;
  justify-content: flex-start;
  margin-top: 110px;
  @media (min-width: 780px) and (max-width: 1100px) {
    padding: 3.5rem;
  }
`;

const AdminUserDetail: FC = (props) => {
    const params = useParams();
    const {t} = useTranslation();
    const slug = params['id'];
    const [userData, setUserData] = useState<IUserDetailsResponse>({
        birthDate: "",
        email: "",
        firstName: "",
        id: "",
        lastName: "",
        middleName: "",
        phoneNumber: "",
        roles: [],
        wallets: []
    });
    const GlobalModalStorage = useContext(GlobalModalStore);

    const getUserInfo = useMutation((userID: string) => UserApi.getUserDetails(userID), {
        onSuccess: data => {
            setUserData(data);
        },
    });


    useEffect(() => {
        getUserInfo.mutate(slug);
    }, [GlobalModalStorage.windows]);

    const userBalance = userData?.wallets.find(it => it.currency === 'USDT')?.balance || 0
    const withdrawalBalance = userData?.wallets.find(it => it.currency === 'USDT')?.withdrawalBalance || 0
    const efficientUserBalance = userData?.wallets.find(it => it.currency === 'USDT')?.efficientBalance || 0
    const totalProfitUsd = userData?.wallets?.find(it => it.currency === 'USDT')?.totalAccrualOfInterest || 0

    const getOperationsItemsQuery = useMutation((data: IGetUserOperationsRequest) => UserApi.getUserOperationsList(data));
    const [operationsData, setOperationsData] = useState<IGetUserOperationsResponse>({
        items: [],
        totalCount: 0
    });

    const getReportsItemsQuery = useMutation((data: IGetUserReportRequest) => UserApi.getUsersReport(data));
    const [reportsData, setReportsData] = useState({
        items: []
    });

    useEffect(() => {
        if (!!userData.id) {
            const operationsReq: IGetUserOperationsRequest = {
                statuses: Object.values(IStatusOperation),
                skip: 0,
                take: 200,
                userId: userData.id
            }
            getOperationsItemsQuery.mutate(operationsReq, {
                onSuccess: data => {
                    setOperationsData(data)
                }
            })

            const getLastDayPrevMonth = () => {
                const currentDate: Date = new Date();
                const firstDayOfCurrentMonth: Date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                const lastDayOfPreviousMonth: Date = new Date(firstDayOfCurrentMonth);
                const formattedDate: string = lastDayOfPreviousMonth.toISOString().split('T')[0];
                return formattedDate
            }

            const reportsReq: IGetUserReportRequest = {
                userId: userData.id,
                walletId: userData.wallets?.find(it => it.currency === 'USDT')?.id,
                dateFrom: "2024-02-01",
                dateTo: getLastDayPrevMonth()
            };
            getReportsItemsQuery.mutate(reportsReq, {
                onSuccess: data => {
                    setReportsData(data);
                }
            });
        }
    }, [userData.id]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page>
            <HeaderComponent/>
            <StyledBg>
                <StatsWrapper>
                    <StatisticsComponent
                        title={`$${toLocaleNumber(efficientUserBalance)} / $${toLocaleNumber(userBalance)}`}
                        description={t("dashboard.balance")}/>
                    <StatisticsComponent
                        title={`$ ${toLocaleNumber(withdrawalBalance)}`}
                        description={t("dashboard.withdrawProfit")}/>
                    <StatisticsComponent
                        title={`$ ${toLocaleNumber(totalProfitUsd)}`}
                        description={t("dashboard.total_profit")}/>
                </StatsWrapper>

                <MainBlock>
                    <CommonInfoForm user={userData}/>
                </MainBlock>

                <BalanceBlock>
                    <FlexColumn>
                        <BalanceAddForm user={userData}/>
                        <PercentAddForm user={userData}/>
                    </FlexColumn>
                    <BalanceUserDashboard user={userData}/>
                </BalanceBlock>

                <UserOperationsComponent
                    operations={operationsData}
                    isLoading={getOperationsItemsQuery.isLoading}/>
                <UserReportsComponent operations={reportsData}
                                      isLoading={getReportsItemsQuery.isLoading}/>
            </StyledBg>
        </Page>
    );
};

export default observer(AdminUserDetail);
