import {useTranslation} from "react-i18next";
import {RectTitle, DocContent} from "../components";
import {FlexColumnStart} from "../../../style/projectComponents";

export const First = () => {
    const {t, i18n} = useTranslation();

    return (
        <DocContent>
            <FlexColumnStart className={'block'}>
                <span>{t('documents.terms.1.1')}</span>

            </FlexColumnStart>

            <FlexColumnStart className={'block'}>
                <p>{t("documents.terms.1.2")}</p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.3")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.4")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.5")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>{t("documents.terms.1.6")}</p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>{t("documents.terms.1.7")}</p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>{t("documents.terms.1.8")}</p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.9")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.10")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.11")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.12")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.13")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.14")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.15")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.16")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.17")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.18")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.19")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.20")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.21")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.22")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.23")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.24")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.25")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.26")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.27")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.28")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.29")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.30")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.31")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.32")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.33")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.34")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.35")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.36")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.37")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.38")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.39")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.40")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.41")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.42")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.43")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.44")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.45")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.46")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.47")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.48")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.49")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    <RectTitle text={t("documents.terms.1.50")}/>
                    <RectTitle text={t("documents.terms.1.51")}/>
                    <RectTitle text={t("documents.terms.1.52")}/>
                    <RectTitle text={t("documents.terms.1.53")}/>
                    <RectTitle text={t("documents.terms.1.54")}/>
                    <RectTitle text={t("documents.terms.1.55")}/>
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    Additionally, you agree not to:

                    <RectTitle text={t("documents.terms.1.56")}/>
                    <RectTitle text={t("documents.terms.1.57")}/>
                    <RectTitle text={t("documents.terms.1.58")}/>
                    <RectTitle text={t("documents.terms.1.59")}/>
                    <RectTitle text={t("documents.terms.1.60")}/>
                    <RectTitle text={t("documents.terms.1.61")}/>
                    <RectTitle text={t("documents.terms.1.62")}/>
                    <RectTitle text={t("documents.terms.1.63")}/>
                    <RectTitle text={t("documents.terms.1.64")}/>
                    <RectTitle text={t("documents.terms.1.65")}/>

                    <RectTitle text={t("documents.terms.1.66")}/>
                    <RectTitle text={t("documents.terms.1.67")}/>
                    <RectTitle text={t("documents.terms.1.68")}/>
                    <RectTitle text={t("documents.terms.1.69")}/>
                    <RectTitle text={t("documents.terms.1.70")}/>

                </p>
            </FlexColumnStart>

            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.71")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    <RectTitle
                        text={t("documents.terms.1.72")}/>
                    <RectTitle
                        text={t("documents.terms.1.73")}/>
                    <RectTitle
                        text={t("documents.terms.1.74")}/>
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.75")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.76")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.77")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.78")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.79")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.80")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.81")}
                </p>
            </FlexColumnStart>


        </DocContent>
    );
};
