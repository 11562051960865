export const RightCircle = (
    <svg className="top-investmentsSvgAnimated top-investments-block__img top-investments-block__img-pc top-investments-block__img-pc-second"
         width="213" height="349" viewBox="0 0 213 349" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 348C95.3233 348 171.693 269.387 171.693 174.564C171.693 79.741 95.3233 1 0.5 1"
              stroke="#00AAC3"></path>
        <path d="M212 1V348" stroke="#00AAC3"></path>
    </svg>
)

export const LeftCircle = (
    <svg className="top-investments-block__img top-investments-block__img-pc top-investments-block__img-pc-first"
         width="150" height="151" viewBox="0 0 150 151" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M149.5 75.5C149.5 116.65 116.141 150.5 75 150.5C33.8591 150.5 0.5 116.65 0.5 75.5C0.5 34.3548 33.8548 1 75 1C116.145 1 149.5 34.3548 149.5 75.5Z"
            stroke="#DEDDD8"></path>
    </svg>
)

export const BottomInvestRectangle = (
  <svg className="body-investments-block__img body-investments-block__img-pc body-investments-block__img-pc-third"
       width="617" height="353" viewBox="0 0 617 353" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M617 117L309 117L309 353" stroke="#DEDDD8"></path>
    <path d="M1 235H310" stroke="#DEDDD8"></path>
    <path d="M0 1L617 1.00005" stroke="#DEDDD8"></path>
  </svg>
)

export const BottomLeftInvestRectangle = (
    <svg className="body-investments-block__img body-investments-block__img-pc body-investments-block__img-pc-first"
         width="310" height="478" viewBox="0 0 310 478" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M309.003 126V126C138.416 126 0.195982 264.416 0.437513 435.003L0.498392 478" stroke="#DEDDD8"></path>
        <path d="M0.996796 1.00003L310 1" stroke="#DEDDD8"></path>
    </svg>
)

export const RightInvestRectangle = (
  <svg className="body-investments-block__img body-investments-block__img-pc body-investments-block__img-pc-second"
       width="310" height="465" viewBox="0 0 310 465" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1.00003L310 1" stroke="#DEDDD8"></path>
    <path d="M128 1.00003L128 336" stroke="#DEDDD8"></path>
    <path d="M0 336L310 336" stroke="#DEDDD8"></path>
    <path d="M128 336V336C128 406.692 70.6924 464 0 464V464" stroke="#DEDDD8"></path>
  </svg>
)
