import {FlexColumnStart} from "../../../style/projectComponents";
import {DocContent} from "../components";
import {useTranslation} from "react-i18next";

export const First = () => {
    const {t, i18n} = useTranslation();

    return (
        <DocContent>
            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.1")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.2")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.3")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.4")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.5")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.6")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.7")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.8")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.9")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.10")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.11")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.12")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.13")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.14")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.15")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.16")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.17")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.18")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.19")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.20")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.21")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.22")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.23")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.24")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.25")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.26")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.27")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.28")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.29")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.30")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.31")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.32")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.33")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.34")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.35")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.36")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.37")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.38")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.39")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.40")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.41")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.42")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.43")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.44")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.45")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.46")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.websiteTerms.1.47")}
                </p>
            </FlexColumnStart>

        </DocContent>
    );
};
