import {colors} from "../../style/colors";
import {FlexColumn, FlexColumnStart, FlexRow, FlexRowBetween, StyledButton} from "../../style/projectComponents";
import {ValidationInputComponent} from "../global/ValidationInputComponent";
import React, {FC, useContext, useState} from "react";
import styled from "styled-components";
import {Controller, useForm} from "react-hook-form";
import {useMutation} from "react-query";
import WithdrawalApi from "../../service/withdrawal-api/WithdrawalApi";
import {
    IAproveRequest,
    IGetWithdrawalResponse,
    IGetWithdrawalResponseItem,
    IRejectRequest
} from "../../service/withdrawal-api/models";
import {useTranslation} from "react-i18next";
import LanguageStorage from "../../storage/LanguageStorage/LanguageStorage";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import GlobalModalStore from "../../storage/GlobalModalStore/GlobalModalStore";
import {Link} from "react-router-dom";

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 380px;
  box-sizing: border-box;

  @media (max-width: 480px) {
    input {
      max-width: 17.65rem;
      width: 100%;
    }

    label {
      left: 48px;
    }
  }
  @media (min-width: 480px) and (max-width: 780px) {
    width: 300px;
  }
`;

const Wrapper = styled(FlexColumnStart)`
  position: relative;
  box-sizing: border-box;
  border-radius: 0.58438rem;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  background-color: ${colors.pageBg};
  padding: 2.13rem 1.94rem;

  @media (min-width: 780px) and (max-width: 1100px) {
    padding: 1.9rem 1.45rem;
  }

  @media (min-width: 480px) and (max-width: 780px) {
    padding: 1.85rem 1.93rem;
    width: 30rem;
    gap: 1rem;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    padding: 1.85rem 1.93rem;
    width: 22rem;
    gap: 1rem;
  }
`;

const TextWrapper = styled(FlexColumnStart)`
  text-align: left;
  gap: 0.5rem;
  width: 100%;

  p {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.16869rem;
  }

  .amount {
    font-weight: 600;
  }

  .address {
    font-size: 0.875rem;
  }

  @media (max-width: 480px) {
    p {
      font-size: 0.875rem;
    }

    .address {
      font-size: 0.75rem;
    }
  }
`;

const ButtonWrapper = styled(FlexRow)`
  width: 100%;
  gap: 0.5rem;
  box-sizing: border-box;

  button {
    width: 100%;
    padding: 1rem;
    border-radius: var(--8, 0.5rem);
    font-size: 1rem;
    font-weight: 700;
  }

  @media (min-width: 780px) and (max-width: 1100px) {
    button {
      font-size: 0.75rem;
    }
  }

  @media (max-width: 480px) {
    button {
      width: 9.8rem;
    }
  }
`;

const ActionContent = styled(FlexColumn)`
  box-sizing: border-box;
  position: relative;
  gap: 0.5rem;
`;
const UserAccount = styled.p`
  color: #000;
  padding: 5px 8px;
  background: ${colors.blueGray};
  border-radius: 6px;
`

export type IWithdrawAdminProps = {
    withdrawals: IGetWithdrawalResponse,
    setWithdrawals: (e: IGetWithdrawalResponse) => void
}

export const WithdrawAdminReqComponent: FC<IWithdrawAdminProps & IGetWithdrawalResponseItem> = (props) => {
    const {withdrawals, setWithdrawals, ...other} = props;
    const {register, reset, clearErrors, control, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            comment: other.comment || other.transactionNumber
        }
    });
    const [accept, setAccept] = useState<boolean>(false);

    const approveWithdraw = useMutation((data: IAproveRequest) => WithdrawalApi.approveWithdrawal(data));
    const rejectWithdraw = useMutation((data: IRejectRequest) => WithdrawalApi.rejectWithdrawal(data));

    const GlobalModalStorage = useContext(GlobalModalStore);

    const onSubmit = (data: any) => {
        if (accept) {
            const approve: IAproveRequest = {
                transactionNumber: data.comment,
                withdrawalId: other.withdrawalRequestId
            };
            approveWithdraw.mutate(approve, {
                onSuccess: data => {
                    setWithdrawals({
                        items: withdrawals.items.filter(it => it.withdrawalRequestId != other.withdrawalRequestId),
                        totalCount: withdrawals.totalCount
                    });
                    reset({
                        comment: ''
                    });
                    GlobalModalStorage.makeVisible(true, t("common.success"));
                },
                onError: error => {
                    GlobalModalStorage.makeVisible(false, t("common.error"));
                }
            });
        } else {
            const reject: IRejectRequest = {
                comment: data.comment,
                withdrawalId: other.withdrawalRequestId
            };
            rejectWithdraw.mutate(reject, {
                onSuccess: data => {
                    setWithdrawals({
                        items: withdrawals.items.filter(it => it.withdrawalRequestId != other.withdrawalRequestId),
                        totalCount: withdrawals.totalCount
                    });
                    reset({
                        comment: ''
                    });
                    GlobalModalStorage.makeVisible(true, t("common.success"));
                },
                onError: error => {
                    GlobalModalStorage.makeVisible(false, t("common.error"));
                }
            });
        }
    };

    const {t, i18n} = useTranslation();
    const LanguageStore = useContext(LanguageStorage);
    const getDate = (date: string) => format(new Date(date), 'dd.MM.yyyy', {locale: ru });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Wrapper {...other}>
                <TextWrapper>
                    <FlexRowBetween style={{marginBottom: 5}}>
                        <p className={'created'}>{getDate(other.createdOn)}</p>
                        <p className={'amount'}>$ {other.withdrawalAmount.toFixed(2)}</p>
                    </FlexRowBetween>
                    <Link to={`/admin/${other.userId}`} style={{textDecoration: "none"}}>
                        <UserAccount>{other.userName}</UserAccount>
                    </Link>
                    <p className={'address'}>{other.destinationWalletNumber}</p>
                </TextWrapper>
                <ActionContent>
                    <InputWrapper>
                        <Controller
                            name={"comment"}
                            control={control}
                            rules={{required: true}}
                            render={({field: {ref, ...field}}) =>
                                <ValidationInputComponent
                                    {...field}
                                    type="text"
                                    isError={!!errors["comment"]}
                                    label={t('adminWithdraw.comment')}/>}
                        />
                    </InputWrapper>
                    <ButtonWrapper>
                        <StyledButton onClick={() => setAccept(true)} type={'submit'}>
                            {t('adminWithdraw.accept')}
                        </StyledButton>
                        <StyledButton
                            onClick={() => setAccept(false)}
                            type={'submit'}
                            style={{
                                backgroundColor: colors.redFail
                            }}>
                            {t('adminWithdraw.decline')}
                        </StyledButton>
                    </ButtonWrapper>
                </ActionContent>
            </Wrapper>
        </form>

    );
};