import {FlexColumnStart, StyledLink} from "../../../style/projectComponents";
import styled from "styled-components";
import {colors} from "../../../style/colors";
import {ComponentPropsWithoutRef, FC} from "react";
import {useTranslation} from "react-i18next";
import {Link as ScrollLink} from 'react-scroll';

const StyledMenuItem = styled(StyledLink)`
  color: #000000;
  font-size: 1rem;
  font-weight: 300;

  :hover {
    color: ${colors.primary};
    transition: all ease-in 0.3s;
  }
`

const StyledScrollLink = styled(ScrollLink)`
  color: #000000;
  font-size: 1rem;
  font-weight: 300;
  text-decoration: none;
  cursor: pointer;


  :hover {
    color: ${colors.primary};
    transition: all ease-in 0.3s;
  }
`

const MenuCol = styled(FlexColumnStart)`
  @media (max-width: 1350px) {
    gap: 1.5rem;
    //flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
  }
  flex-wrap: wrap;
`

export const MenuColItem: FC<{
    isScrollLink?: boolean,
    items: {
        title: string,
        link: string
    }[]
} & ComponentPropsWithoutRef<'div'>> = (props) => {
    const {items, isScrollLink = false, ...other} = props;
    const {t, i18n} = useTranslation();

    return (
        <MenuCol {...other} className={'column_menu'}>
            {items.map((it, idx) =>
                isScrollLink
                    ? <StyledScrollLink
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={500}
                        to={it.link}>
                        {t(it.title)}
                    </StyledScrollLink>
                    : <StyledMenuItem to={it.link}>
                        {t(it.title)}
                    </StyledMenuItem>
            )}
        </MenuCol>
    );
};
