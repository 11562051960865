export type IWithdrawalRequest = {
    walletId: string,
    currency: string,
    withdrawalAmount: number,
    destinationNetworkId: string,
    destinationWalletNumber: string
}

export enum IWithdrawalStatus {
    undefined = 'undefined',
    notApproved = 'notApproved',
    approved = 'approved',
    rejected = 'rejected',
    cancelled = 'cancelled'
}

export type IGetWithdrawalRequest = {
    statuses: IWithdrawalStatus[],
    skip: number,
    take: number
}

export type IGetWithdrawalResponse = {
    items: IGetWithdrawalResponseItem[],
    totalCount: number
}

export type IGetWithdrawalResponseItem = {
    userId: string,
    userName: string,
    withdrawalRequestId: string,
    walletId: string,
    currency: string,
    withdrawalAmount: number,
    destinationNetwork: {
        id: string,
        name: string
    },
    destinationWalletNumber: string,
    comment: string,
    transactionNumber: string,
    createdOn: string,
    updatedOn: string
    status: IWithdrawalStatus
}
export type IAproveRequest = {
    withdrawalId: string,
    transactionNumber: string
}

export type IRejectRequest = {
    withdrawalId: string,
    comment: string
}

export type IWithdrawalInterface = {
    createWithdrawal(data: IWithdrawalRequest)
    getWithdrawal(data: IGetWithdrawalRequest)
    approveWithdrawal(data: IAproveRequest)
    rejectWithdrawal(data: IRejectRequest)
    cancelWithdrawal(withdrawalId: string)

}