import {Controller, useForm} from "react-hook-form";
import {ValidationInputComponent} from "../global/ValidationInputComponent";
import {ErrorText, FlexColumnStart, FlexRow, InfoText, StyledRegisterButton} from "../../style/projectComponents";
import React, {FC, useContext, useState} from "react";
import styled from "styled-components";
import {colors} from "../../style/colors";
import {getCurrentLabel, getCurrentRegex, isAmountGood, isWalletGood} from "./utils";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "react-query";
import WithdrawalApi from "../../service/withdrawal-api/WithdrawalApi";
import {IWithdrawalRequest} from "../../service/withdrawal-api/models";
import ProfileStorage from "../../storage/ProfileStorage/ProfileStorage";
import NetworkStorage from "../../storage/NetworkStorage/NetworkStorage";
import NetworkApi from "../../service/network-api/NetworkApi";
import {ValidationInputComponentMax} from "../global/ValidationInputComponentMax";
import GlobalModalStore from "../../storage/GlobalModalStore/GlobalModalStore";
import i18n from "../../config/i18n";

const WithdrawBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 12px;
  margin-top: 30px;
  border-radius: 12px;
  width: 100%;
  max-width: 400px;
  border: 1px solid ${colors.blueGray};

  img {
    margin-bottom: 25px;
    margin-top: 25px;
    border-radius: 10px;
  }

  @media (max-width: 480px) {
    width: auto;
  }
`;
const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 100%;
  max-width: 400px;
  min-width: 320px;
  @media (max-width: 480px) {
    label {
      left: 20px;
    }
  }
`;
const Description = styled.p`
  font-size: 14px;
  padding: 0 10px;
  margin-top: 24px;
  text-align: left;
`;


export type IDepositDataProps = {
    activeTypeId: number;
}

export const DepositData: FC<IDepositDataProps> = (props) => {

    const {activeTypeId} = props;

    const {control, clearErrors, watch, setError, reset, handleSubmit, formState: {errors}, setValue} = useForm();

    const {t} = useTranslation();

    const ProfileStore = useContext(ProfileStorage);
    const [userBalance, setUserBalance] = useState((ProfileStore.getBalance() + ProfileStore.getWithdrawalBalance()));
    const [userProfit, setUserProfit] = useState(ProfileStore.getWithdrawalBalance());


    const createWithdrawMutation = useMutation((data: IWithdrawalRequest) => WithdrawalApi.createWithdrawal(data));
    const NetworkStore = useContext(NetworkStorage);

    const GetNetworksList = useQuery('networks_list', () => NetworkApi.getNetworkList(), {
        onSuccess: data => {
            NetworkStore.setNetworkList(data);
        },
        enabled: NetworkStore.networkList.items.length === 0
    });
    const GlobalModalStorage = useContext(GlobalModalStore);

    const onSubmit = (data) => {
        const req: IWithdrawalRequest = {
            currency: 'USDT',
            destinationNetworkId: NetworkStore?.networkList?.items.find(it => it.name === 'Default network').id,
            destinationWalletNumber: data["withdrawal address"],
            walletId: ProfileStore.getUSDTWallet()?.id,
            withdrawalAmount: Number(data["Amount"])
        };

        createWithdrawMutation.mutate(req, {
            onSuccess: _data => {
                ProfileStore.reduceBalanceUSDTFor(Number(watch('Amount')));
                setUserProfit(() => ProfileStore.getWithdrawalBalance());
                setUserBalance(() => ProfileStore.getBalance());
                GlobalModalStorage.makeVisible(true, t('common.withdrawSuccessNotification', {amount: watch("Amount")}));
                reset({
                    "withdrawal address": "",
                    "Amount": ""
                });
            },
            onError: _error => {
                GlobalModalStorage.makeVisible(false, t('common.withdrawErrorNotification', {amount: watch("Amount")}));
                setError("badRequest", {
                    type: "manual",
                    message: "Bad request please try again"
                });
            }
        });
    }

    const setValueToMax = () => {
        if (userBalance > 0) {
            setValue("Amount", userBalance)
        }
    }

    const setToProfitMax = () => {
        if (userProfit > 0) {
            setValue("Amount", userProfit)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <WithdrawBlock>
                <FlexColumnStart style={{marginBottom: 15, width: "100%"}}>
                    <InputWrapper style={{marginBottom: 5}}>
                        <Controller
                            name="withdrawal address"
                            control={control}
                            rules={isWalletGood(getCurrentRegex(activeTypeId))}
                            render={({field: {ref, ...field}}) =>
                                <ValidationInputComponent
                                    {...field}
                                    type="text"
                                    isError={!!errors["withdrawal address"]}
                                    label={t(getCurrentLabel(activeTypeId))}/>}
                        />
                    </InputWrapper>

                    <ErrorText hasError={!!errors["withdrawal address"]}>
                        {t(errors["withdrawal address"]?.message?.toString())}
                    </ErrorText>

                </FlexColumnStart>

                <FlexColumnStart style={{width: "100%"}}>
                    <InputWrapper style={{marginBottom: 5}}>
                        <Controller
                            name="Amount"
                            control={control}
                            rules={isAmountGood(userBalance)}
                            render={({field: {ref, ...field}}) =>
                                <ValidationInputComponentMax
                                    setValueToMax={setValueToMax}
                                    {...field}
                                    type="text"
                                    isError={!!errors["Amount"]}
                                    label={t("withdraw.amount")}/>}
                        />
                    </InputWrapper>

                    <InfoText style={{marginTop: 3, marginBottom: 3}} onClick={setToProfitMax}>
                        {t("withdraw.withdrawProfit")}: <span
                        style={{color: colors.secondary}}>${userProfit?.toFixed(2) || 0}</span>
                    </InfoText>

                    <InfoText style={{marginTop: 3, marginBottom: 3}} onClick={setValueToMax}>
                        {t("withdraw.available")}: <span
                        style={{color: colors.secondary}}>${userBalance?.toFixed(2) || 0}</span>
                    </InfoText>

                    <ErrorText hasError={!!errors["Amount"]}>
                        {t(errors["Amount"]?.message?.toString())}
                    </ErrorText>
                </FlexColumnStart>

                <StyledRegisterButton
                    disabled={GetNetworksList.isLoading}
                    onClick={() => clearErrors()}
                    type={'submit'} style={{width: '100%'}}>
                    {t('withdraw.withdraw')}
                </StyledRegisterButton>
                <Description>{t('withdraw.warning')}</Description>
            </WithdrawBlock>
        </form>
    );
};
