import styled from "styled-components";
import {StyledSection} from "../../global/StyledSection";
import {colors} from "../../../style/colors";
import {AppStyle, FlexColumn, FlexColumnStart, FlexRow, StyledLink} from "../../../style/projectComponents";
import {MenuColItem} from "./MenuColItem";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useContext, useRef} from "react";
import {useIsVisible} from "../../../hooks/useIsVisible";
import UserStorage from "../../../storage/UserStorage/UserStorage";
import LanguageStorage from "../../../storage/LanguageStorage/LanguageStorage";
import i18n from "../../../config/i18n";

const FooterSectionPage = styled(StyledSection)`
  max-width: 100vw;
  align-items: center;
  width: 100vw;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  border-top: 0.0625rem solid ${colors.lightGrayBg};
`

const BordersContent = styled(FlexColumn)`
  max-width: 120rem;
  align-items: center;
  position: relative;
  border: 0.0625rem solid ${colors.lightGrayBg};
  border-top-style: none;
  border-bottom-style: none;
  width: 100%;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  box-sizing: border-box;
`

const FooterContent = styled(FlexRow)`
  width: 100%;
  align-items: flex-start;
  column-gap: 3.125rem;
  justify-content: space-between;
  row-gap: 2.125rem;

  @media (max-width: 1350px) {
    flex-direction: column;
  }
`


const LogoWrapper = styled(FlexColumnStart)`
  text-align: left;

  p {
    font-size: 0.875rem;
    margin-top: 1.875rem;
    color: #AAB0B8;
  }
`

const MenuWrapper = styled(FlexColumnStart)`
  span {
    font-size: 0.875rem;
    margin-bottom: 1rem;
    color: #AAB0B8;
  }

  .column_menu {
    row-gap: 1.5rem;
  }

  @media (min-width: 950px) and (max-width: 1350px) {
    margin-left: auto;
  }
`

const ContactsWrapper = styled(FlexColumnStart)`
  row-gap: 3.125rem;
  width: 40.625rem;

  .content, .links {
    column-gap: 5.9375rem;
    flex-wrap: wrap;

    a {
      color: black;
      text-decoration: none;
      font-size: 1.5rem;
      font-weight: 300;
      cursor: pointer;

      :hover {
        color: ${colors.primary};
        transition: all ease-in 0.3s;
      }
    }
  }

  @media (min-width: 900px) {
    .links {
      width: max-content;
    }
  }

  @media (max-width: 900px) {

    .content, .links {
      a {
        font-size: 1.125rem;
      }
    }

    .content {
      flex-direction: column;
      align-items: start;
      row-gap: 1.875rem;
    }

    .links {
      flex-direction: row;
      column-gap: 0;
      gap: 1.875rem;
    }
  }

  @media (max-width: 480px) {
    .links {
      flex-wrap: wrap;
      width: 350px;
      justify-content: flex-start;
    }
  }
`

const StyledHr = styled.hr`
  width: 100%;
  margin: 0;
  height: 0.0625rem;
  border: none;
  background-color: #DEDDD8;
`

const BottomContent = styled(FlexRow)`
  justify-content: space-between;
  width: 100%;
  align-items: center;
  column-gap: 50px;

  @media (max-width: 1040px) {
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }
`
const MenuAndLinks = styled(FlexRow)`
  width: 100%;
  gap: 8rem;
  justify-content: unset;

  @media (max-width: 950px) {
    flex-direction: column;
    gap: 2rem;
    align-items: baseline;
  }

  @media (max-width: 1350px) {
    padding-bottom: 3.75rem;
    border-bottom: 0.0625rem solid #DEDDD8;
    align-items: start;
  }
`

const Inner = styled(FlexColumnStart)`
  gap: 2.125rem;
  max-width: 81.25rem;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  padding: 0rem 1.25rem;
`

const StyledUl = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  row-gap: 1.5rem;
  align-items: end;
  flex-wrap: wrap;
  column-gap: 3.125rem;
  box-sizing: border-box;

  a {
    text-decoration: none;
    color: black;
    list-style: none;
    font-size: 1.125rem;
    font-weight: 400;
  }

  a:hover {
    color: ${colors.primary};
    transition: all ease-in 0.3s;
  }

  @media (max-width: 480px) {
    a {
      font-size: 1rem;
    }
  }

`

const AddressRow = styled(FlexRow)`
  width: 100%;
  column-gap: 3.125rem;
  align-items: baseline;

  .address {
    width: 50%;
    text-align: left;
    @media (max-width: 600px) {
      width: 100%;
    }

    span {
      margin-bottom: 1rem;
      font-size: 0.875rem;
      color: #AAB0B8;
    }

    a {
      cursor: pointer;
      color: black;
      font-size: 1rem;
      text-decoration: none;
      transition: all 0.3s ease 0s;

      :hover {
        color: ${colors.primary};
        transition: all ease-in 0.3s;
      }
    }

  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: start;
    gap: 2rem;
  }
`

const Menu = styled(FlexRow)`
  @media (max-width: 1350px) {
    gap: 1.5rem;
    //flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
  }
`

const MenuCol = styled(FlexColumnStart)`
  flex-direction: row;
  gap: 1.5rem;
`

const landingMenuItems = [
    {
        title: 'footer.profit',
        link: 'profit'
    },
    {
        title: 'footer.leaders',
        link: 'leaders'
    },
    {
        title: 'footer.principles',
        link: 'principles'
    },
    {
        title: 'footer.strategy',
        link: 'investments'
    },
    {
        title: 'footer.team',
        link: 'team'
    },
    {
        title: 'footer.plans',
        link: 'plans'
    },
    {
        title: 'footer.serts',
        link: 'document'
    },
    {
        title: 'footer.cooperation',
        link: 'cooperation'
    },
]

const FooterVector = (
    <svg className="footer__img footer__img-mobile" width="241" height="229" viewBox="0 0 241 229"
         fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 228L241 228" stroke="#DEDDD8"></path>
        <path d="M1.00001 0L1.00001 228" stroke="#DEDDD8"></path>
        <path d="M1 168L108 168" stroke="#DEDDD8"></path>
        <path d="M1 60L108 60" stroke="#DEDDD8"></path>
        <path d="M108 1L108 227.5" stroke="#00AAC3"></path>
        <path d="M241 228C178.36 228 128 176.859 128 114.29C128 51.7217 178.36 0.5 241 0.5"
              stroke="#00AAC3"></path>
        <path
            d="M60.5 198C60.5 214.303 47.2817 228 31 228C14.7076 228 1 214.292 1 198C1 181.708 14.7076 168 31 168C47.2817 168 60.5 181.697 60.5 198Z"
            stroke="#DEDDD8"></path>
    </svg>
);

const FigureContainer = styled.div<{ lng?: string }>`
  position: absolute;
  left: ${({lng}) => lng === 'ar' && 0};
  right: ${({lng}) => lng === 'ar' && 'auto'};
  transform: ${({lng}) => lng === 'ar' && 'scaleX(-1)'};
`

export const Footer = () => {

    const location = useLocation()
    const isLandingPage = location.pathname === "/"

    const {t} = useTranslation();

    const headerAuthLinks = [
        {
            link: '/dashboard',
            title: t('menu.dashboard')
        },
        {
            link: '/deposit',
            title: t('menu.deposit')
        },
        {
            link: '/withdraw',
            title: t('menu.withdraw')
        },
        {
            link: '/settings',
            title: t('menu.settings')
        },
    ];

    const ref = useRef();
    const isVisible = useIsVisible(ref);

    const UserStore = useContext(UserStorage);
    const LanguageStore = useContext(LanguageStorage);
    const isArLanguage = LanguageStore.language === "ar"
    const isAuth = !!UserStore.accessToken;


    return (
        <FooterSectionPage style={{backgroundColor: isLandingPage ? '#FCFBF5' : ''}}>
            <FigureContainer
                lng={i18n.language}
                className={"footer__decor " + (isVisible ? "element-show" : "")} ref={ref}>
                {FooterVector}
            </FigureContainer>
            <BordersContent>
                <Inner>
                    <FooterContent>
                        <MenuAndLinks>
                            <LogoWrapper>
                                <img src={'/landing_media/logo2.svg'}/>
                                <p>
                                    © 2023 Kanna Group Ltd
                                    <br/>
                                    {t("footer.allRights")}
                                </p>
                            </LogoWrapper>

                            <MenuWrapper>
                                {(isLandingPage || isAuth) && <span>{t("footer.menu")}</span>}
                                <Menu style={{gap: '1.5rem'}}>
                                    {isLandingPage &&
                                        <MenuColItem isScrollLink={true}
                                                     items={landingMenuItems.filter((it, idx) => idx < 4)}/>}
                                    {isLandingPage &&
                                        <MenuColItem isScrollLink={true}
                                                     items={landingMenuItems.filter((it, idx) => idx > 3)}/>}
                                    {!isLandingPage && isAuth && <MenuColItem items={headerAuthLinks}/>}
                                </Menu>
                            </MenuWrapper>
                        </MenuAndLinks>

                        <ContactsWrapper>
                            <FlexRow className={'content'}>
                                <a style={{direction: 'ltr'}} href={'tel:+994506165928'}>
                                    +994 50 616 5928
                                </a>
                                <a href={'mailto:inbox@kanna.capital'}>inbox@kanna.capital</a>
                            </FlexRow>
                            <FlexRow className={'links'}>
                                <a href={'https://instagram.com/kanna.capital?igshid=MzRlODBiNWFlZA=='}>Instagram</a>
                                <a href={'https://www.linkedin.com/company/93645365'}>Linkedin</a>
                                <a href={'https://twitter.com/kannacapital?s=21'}>Twitter</a>
                                <a href={'https://www.facebook.com/kannacapital'}>Facebook</a>
                            </FlexRow>
                        </ContactsWrapper>

                    </FooterContent>

                    <StyledHr/>

                    <BottomContent>
                        <StyledUl>
                            <StyledLink to={'/aml'}>
                                {t("footer.aml")}
                            </StyledLink>
                            <StyledLink to={'/privacy'}>
                                {t("footer.privacy")}
                            </StyledLink>
                            <StyledLink to={'/subscription'}>
                                {t("footer.subscription")}
                            </StyledLink>
                            <StyledLink to={'https://kanna-capital.gitbook.io/kanna-capital/'}>
                                FAQ
                            </StyledLink>
                            <StyledLink to={'/terms'}>
                                {t("footer.termsOfUse")}
                            </StyledLink>
                            <StyledLink to={'/website-terms'}>
                                {t("footer.websiteTerms")}
                            </StyledLink>
                        </StyledUl>

                        <AddressRow>
                            <FlexColumnStart className={'address'}>
                                <span>{t("footer.juridic")}</span>
                                <a href={'https://maps.app.goo.gl/hmzbktJ6SBTs9tYs6?g_st=it'}>
                                    {t("footer.addressJur")}
                                </a>
                            </FlexColumnStart>
                            <FlexColumnStart className={'address'}>
                                <span>{t("footer.mainOffice")}</span>
                                <a href={'https://goo.gl/maps/HBPA3h8pcPuA8u2M8'}>{t("footer.mainAddress")}</a>
                            </FlexColumnStart>
                        </AddressRow>
                    </BottomContent>
                </Inner>
            </BordersContent>
        </FooterSectionPage>
    );
};
