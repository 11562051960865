import {action, autorun, computed, makeAutoObservable} from "mobx";
import {createContext} from "react";
import {ITokens} from "../../service/auth-api/models";

class UserStorage {

    user: any = localStorage.user ? JSON.parse(localStorage.user) : null;
    accessToken: string = localStorage.accessToken ? JSON.parse(localStorage.accessToken) : null;
    refreshToken: string = localStorage.refreshToken ? JSON.parse(localStorage.refreshToken) : null;
    isAuth: boolean = localStorage.isAuth ? JSON.parse(localStorage.isAuth) : false;

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
        autorun(async () => {
            localStorage.user = JSON.stringify(this.user);
            localStorage.accessToken = JSON.stringify(this.accessToken);
            localStorage.refreshToken = JSON.stringify(this.refreshToken);
            localStorage.isAuth = JSON.stringify(this.isAuth);

        });
    }

    @computed isAdmin() {
        const res = this.user ? this.user['UserRole']?.includes("Admin") : false;
        return res;
    }

    @action setUser(data: any) {
        this.user = data;
    }

    @action setIsAuth(status: boolean) {
        this.isAuth = status;
    }

    @action logout() {
        sessionStorage['language'] = localStorage['language'];
        localStorage.clear();
        localStorage['language'] = sessionStorage['language'];
    }

    @action setTokens(response: ITokens) {
        this.accessToken = response.accessToken;
        this.refreshToken = response.refreshToken;
    }

}

export default createContext(new UserStorage());
