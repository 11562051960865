import React, {ComponentPropsWithoutRef, useState} from "react";
import {StyledInput} from "../../style/projectComponents";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

type ValidationInputComponentProps = {
    value?: string;
    isError?: any;
    label?: string;
    ref?: any;
}

export const ValidationLabel = styled.label<{ isFocused?: any, lng?: string }>`
  display: ${props => props.isFocused ? "block" : "none"};
  font-size: 14px;
  position: absolute;
  left: ${props => props.lng.toLowerCase() === 'ar' ? '' : '20px'};
  right: ${props => props.lng.toLowerCase() === 'ar' ? '20px' : ''};
  margin-top: 10px;
  transition: all 1s;
  color: rgba(0, 0, 0, 0.8);
  width: fit-content;
  @media (max-width: 480px) {
    left: ${props => props.lng.toLowerCase() === 'ar' ? '' : '20px'};
    right: ${props => props.lng.toLowerCase() === 'ar' ? '20px' : ''};
  }
`;

export const CustomStyledInput = styled(StyledInput)<{ lng?: string }>`
  width: 100%;
  position: relative;
  font-family: sans-serif;
  

  @media (max-width: 480px) {
    max-width: 350px;
  }
`
export const ValidationInputComponent: React.FC<ValidationInputComponentProps & ComponentPropsWithoutRef<'input'>> = (props) => {

    const {value, isError, label, ref, ...other} = props;

    const [isFocused, setIsFocused] = useState(false);
    const {i18n} = useTranslation();

    return (
        <>
            <CustomStyledInput
                {...other}
                lng={i18n.language}
                ref={ref}
                value={value}
                placeholder={isFocused ? "" : label}
                onChange={other.onChange}
                isFailed={isError}
                onBlur={(e) => {
                    setIsFocused(false);
                    if (!!other.onBlur) {
                        other.onBlur(e);
                    }
                }}
                onFocus={(e) => {
                    setIsFocused(true);
                    if (!!other.onFocus){
                        other.onFocus(e);
                    }
                }}
            />
            <ValidationLabel lng={i18n.language} isFocused={isFocused || value}>{label}</ValidationLabel>
        </>
    );
};
