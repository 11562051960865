import {apiClient} from "../../config/serverConstants";
import {
    GetBybitBalanceAdmin,
    GetFullStatisticAdmin,
    getUpdateBalancePermissionsAdmin,
    IAccrualOfInterestReq,
    IAdminGetRolesResponse,
    IAdminInterface,
    IDepositMoneyRequest,
    IGetAllOperationsListAdminReq,
    IGetAllOperationsListAdminResp,
    IUpdateBalancePermissionsReq,
    IUpdateUserRolesRequest
} from "./models";

class AdminService implements IAdminInterface {
    async depositMoney(data: IDepositMoneyRequest) {
        const response = await apiClient.post('/api/Admin/depositMoney', data);
        return response.data as string;
    }

    async getAllRoles() {
        const response = await apiClient.get('/api/Admin/getAllRoles');
        return response.data as IAdminGetRolesResponse;
    }

    async updateUserRoles(data: IUpdateUserRolesRequest) {
        const response = await apiClient.post('/api/Admin/updateUserRoles', data);
        return response.data as string;
    }

    async accrualOfInterest(data: IAccrualOfInterestReq) {
        const response = await apiClient.post('/api/Admin/accrualOfInterest', data);
        return response.data as string;
    }

    async getAllOperationsList(data: IGetAllOperationsListAdminReq) {
        const response = await apiClient.post('/api/Admin/getAllOperationsList', data);
        return response.data as IGetAllOperationsListAdminResp;
    }

    async getFullStatistic() {
        const response = await apiClient.get('/api/Admin/getFullStatistic');
        return response.data as GetFullStatisticAdmin;
    }

    async getBybitBalance() {
        const response = await apiClient.get('/api/Admin/getBybitBalance');
        return response.data as GetBybitBalanceAdmin;
    }

    async getUpdateBalancePermissions(data: IUpdateBalancePermissionsReq) {
        const response = await apiClient.post('/api/Admin/getUpdateBalancePermissions', data);
        return response.data as getUpdateBalancePermissionsAdmin;
    }
}

export default new AdminService();