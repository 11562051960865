import {ErrorText, FlexColumn, FlexRow, StyledButton} from "../../../style/projectComponents";
import React, {ComponentPropsWithoutRef, FC, useContext, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import styled from "styled-components";
import {ValidationInputComponent} from "../../global/ValidationInputComponent";
import {IUserDetailsResponse} from "../../../service/user-api/models";
import {useMutation, useQueryClient} from "react-query";
import AdminApi from "../../../service/admin-api/AdminApi";
import {IDepositMoneyRequest} from "../../../service/admin-api/models";
import {useTranslation} from "react-i18next";
import GlobalModalStore from "../../../storage/GlobalModalStore/GlobalModalStore";
import {CheckComponent} from "../../auth/Register/CheckComponent";

type BalanceAddForm = {
    user: IUserDetailsResponse
}

type IFormType = {
    name: 'sum' | 'setWithdrawalBalance',
    rules: any,
    type: string
}

const forms: IFormType[] = [
    {
        name: 'sum',
        rules: {
            required: true,
            valueAsNumber: true,
            pattern: {
                value: /^-?\d{1,7}(?:\.\d{1,2})?$/,
                message: "adminUserDetail.inputRule"
            }
        },
        type: 'number',
    },
    {
        name: 'setWithdrawalBalance',
        rules: {
            required: false,
            type: 'boolean'
        },
        type: 'boolean'
    }
];

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 380px;
  @media (max-width: 480px) {
    width: 100%;
    max-width: 380px;
    min-width: 280px;
    label {
      left: 20px;
    }
  }
`;

const StyledForm = styled.form`
  flex-direction: column;
  display: flex;
  align-items: start;
  background-color: white;
  border-radius: var(--8, 0.5rem);
  padding: 2.25rem var(--4, 3.9375rem) 5.75rem var(--4, 3rem);

  .inps {
    gap: 0.81rem;
    margin-bottom: 0.81rem;

    .checkbox {
      width: 100%;
      justify-content: left;
    }
  }

  @media (max-width: 780px) {
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 20px;

    h3 {
      align-self: start;
    }
  }
`

export const BalanceAddForm: FC<BalanceAddForm & ComponentPropsWithoutRef<'form'>> =
    (props) => {

        const {user, ...other} = props;
        const queryClient = useQueryClient();
        const {
            register,
            control,
            clearErrors,
            setValue,
            reset,
            resetField,
            formState: {errors, isDirty},
            watch,
            handleSubmit
        } = useForm();
        const depositMoney = useMutation((data: IDepositMoneyRequest) => AdminApi.depositMoney(data));
        const [uId, setUId] = useState<any>(user.wallets.find(it => it.currency === 'USDT')?.id);
        useEffect(() => {
            console.log(user)
            setUId(user.wallets.find(it => it.currency === 'USDT')?.id)
        }, [user]);

        const GlobalModalStorage = useContext(GlobalModalStore);

        const onSubmit = (data: any) => {
            depositMoney.mutate({
                amount: Number(parseFloat(data['sum']).toFixed(6)),
                comment: 'Deposit',
                walletId: uId ?? '',
                setWithdrawalBalance: data.setWithdrawalBalance
            }, {
                onSuccess: data => {
                    GlobalModalStorage.makeVisible(true, t("common.success"));
                    reset({
                        "sum": "",
                        "setWithdrawalBalance": false
                    });
                }, onError: error => {
                    GlobalModalStorage.makeVisible(false, t("common.error"));
                }
            })
        };

        const {t, i18n} = useTranslation();


        return (
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <h3 style={{marginBottom: '1rem', fontSize: '1.5rem', fontWeight: 400}}>
                    {t('adminUserDetail.addBalance')}
                </h3>
                <FlexColumn className={'inps'}>
                    {forms.map((it, idx) =>
                        it.type === 'boolean'
                            ? (
                                <CheckComponent
                                    textXl={true}
                                    text={"adminUserDetail.setWithdrawalBalance"}
                                    isClicked={watch('setWithdrawalBalance')}
                                    handleClick={() => setValue('setWithdrawalBalance', !watch('setWithdrawalBalance'))}/>
                            )
                            : (<InputWrapper>
                                <Controller
                                    name={it.name}
                                    control={control}
                                    rules={it.rules}

                                    render={({field: {ref, ...field}}) =>
                                        <ValidationInputComponent
                                            {...field}
                                            maxLength={10}
                                            isError={!!errors[it.name]}
                                            label={t(`adminUserDetail.${it.name}.label`)}/>}
                                />
                            </InputWrapper>)
                    )}
                </FlexColumn>

                {!!errors["sum"] &&
                    <ErrorText style={{marginBottom: 10}}>
                        {t(errors['sum']?.message.toString())}
                    </ErrorText>
                }

                <StyledButton
                    type={'submit'}
                    style={{width: '100%', padding: 'var(--16, 1rem)'}}
                    disabled={!isDirty || depositMoney.isLoading || !uId}
                    noRadius
                >
                    {depositMoney.isLoading ? t('adminPercent.processus') : t('adminPercent.add')}
                </StyledButton>
            </StyledForm>
        );
    };
