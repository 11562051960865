import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {
    AuthContent,
    AuthModal,
    AuthTitle,
    ErrorText,
    FlexColumnStart,
    FlexRow,
    Page, PassIcon,
    StyledLink,
    StyledRegisterButton
} from "../style/projectComponents";
import HeaderComponent from "../components/global/HeaderComponent";
import {observer} from "mobx-react-lite";
import {ValidationInputComponent} from "../components/global/ValidationInputComponent";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useMutation} from "react-query";
import AuthApi from "../service/auth-api/AuthApi";
import {IAuthSigninRequest} from "../service/auth-api/models";
import UserStorage from "../storage/UserStorage/UserStorage";
import jwtDecode from "jwt-decode";
import {useNavigate} from "react-router-dom";
import GlobalModalStore from "../storage/GlobalModalStore/GlobalModalStore";

interface AuthPageProps {

}

const InputWrapper = styled(FlexRow)<{ lng?: string }>`
  align-items: start;
  position: relative;
  width: 300px;

  @media (max-width: 480px) {
    label {
      left: ${props => props.lng.toLowerCase() === 'ar' ? '' : '20px'};
      right: ${props => props.lng.toLowerCase() === 'ar' ? '20px' : ''};
    }
  }
`;

const StyledPage = styled(Page)`
  background-position: center;
  background-size: cover;
  justify-content: start;

  .header {
    position: absolute;
  }
`;

const AuthPage: React.FC<AuthPageProps> = () => {

    const UserStore = useContext(UserStorage);
    const {
        control, clearErrors,
        register,
        watch,
        setError,
        handleSubmit,
        formState: {errors},
        getValues
    } = useForm();

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const GlobalModalStorage = useContext(GlobalModalStore);

    const AuthMutation = useMutation((data: IAuthSigninRequest) => AuthApi.signin(data), {
        onError: error => {
            setError("badSignin", {
                type: "manual",
                message: "auth.errors.password.serverError"
            });
            GlobalModalStorage.makeVisible(false, t("auth.errors.password.serverError"));
        },
        onSuccess: data => {
            console.log(data);
            UserStore.setTokens(data);
            UserStore.setUser(jwtDecode(data.accessToken));
            navigate('/dashboard');
        },
    });

    const onSubmit = (data) => {
        AuthMutation.mutate(data);
    };

    const [showPass, setShowPass] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <StyledPage style={{backgroundImage: "url(/images/bg.svg)"}}>
                <HeaderComponent/>
                <AuthContent>
                    <AuthModal style={{marginTop: 100, marginBottom: 100}}>
                        <AuthTitle>{t("auth.title")}</AuthTitle>
                        <FlexColumnStart style={{marginBottom: 10}}>
                            <InputWrapper lng={i18n.language} style={{marginBottom: 5}}>
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'auth.errors.email.required'
                                        },
                                        pattern: {
                                            value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                            message: 'auth.errors.email.pattern'
                                        }
                                    }}
                                    render={({field: {ref, ...field}}) =>
                                        <ValidationInputComponent
                                            {...field}
                                            onKeyDown={(e) => {
                                                if (e.code === 'Enter') {
                                                    e.preventDefault();
                                                    onSubmit(getValues());
                                                }
                                            }}
                                            isError={!!errors.email}
                                            label={t('auth.email')}/>}
                                />
                            </InputWrapper>
                            <ErrorText hasError={!!errors.email}>
                                {t(errors?.email?.message.toString())}
                            </ErrorText>
                        </FlexColumnStart>


                        <FlexColumnStart>
                            <InputWrapper lng={i18n.language} style={{marginBottom: 5}}>
                                <Controller
                                    name="password"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'auth.errors.password.required'
                                        },
                                    }}
                                    render={({field: {ref, ...field}}) =>
                                        <ValidationInputComponent
                                            {...field}
                                            onKeyDown={(e) => {
                                                if (e.code === 'Enter') {
                                                    e.preventDefault();
                                                    onSubmit(getValues());
                                                }
                                            }}
                                            type={showPass ? 'text' : 'password'}
                                            isError={!!errors.password}
                                            label={t("auth.password")}/>}
                                />
                                <PassIcon
                                    lng={i18n.language}
                                    onClick={() => setShowPass(prev => !prev)}
                                    src={showPass ? '/images/show.svg' : '/images/hide.svg'}/>
                            </InputWrapper>
                            <ErrorText hasError={!!errors.password}>
                                {t(errors?.password?.message.toString())}
                            </ErrorText>
                        </FlexColumnStart>

                        <StyledLink to={'/forgot'} style={{
                            cursor: 'pointer',
                            color: 'gray',
                            alignSelf: 'end',
                            marginBottom: 5,
                            fontSize: 12
                        }}>
                            {t('common.forgotPassword')}
                        </StyledLink>


                        <StyledRegisterButton
                            disabled={AuthMutation.isLoading}
                            onClick={() => clearErrors()}
                            type={'submit'}>
                            {t('auth.login')}
                        </StyledRegisterButton>

                        <ErrorText style={{marginTop: 10}} hasError={!!errors.badSignin}>
                            {t(errors?.badSignin?.message.toString())}
                        </ErrorText>


                        <p style={{marginBottom: 10, marginTop: 40, fontSize: 16}}>
                            {t("auth.not_registered")}
                        </p>

                        <StyledLink to={"/register"} onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                e.preventDefault();
                            }
                        }}>
                            {t("auth.create_acc")}
                        </StyledLink>
                    </AuthModal>
                </AuthContent>
            </StyledPage>
        </form>
    );
};

export default observer(AuthPage);
