import styled from "styled-components";
import {StyledSection} from "../../../global/StyledSection";
import {colors} from "../../../../style/colors";
import {FlexColumn, FlexColumnStart, FlexRow} from "../../../../style/projectComponents";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useRef} from "react";
import {useIsVisible} from "../../../../hooks/useIsVisible";

const ProjectsSectionPage = styled(StyledSection)`
  max-width: 100vw;
  align-items: center;
  width: 100vw;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  border-top: 0.0625rem solid ${colors.lightGrayBg};
`

const BordersContent = styled(FlexColumn)`
  max-width: 120rem;
  align-items: center;
  position: relative;
  border: 0.0625rem solid ${colors.lightGrayBg};
  border-top-style: none;
  border-bottom-style: none;
  width: 100%;
  padding-top: 15rem;
  padding-bottom: 15rem;
  box-sizing: border-box;
`

const ProjectsContent = styled(FlexRow)<{ lng?: string }>`
  width: 100%;
  max-width: 81.25rem;
  align-items: flex-start;
  box-sizing: content-box;
  margin: 0 auto;
  position: relative;
  padding: 0rem 1.25rem;
  row-gap: 7.1875rem;

  .links {
    margin-left: ${props => props.lng.toLowerCase() === 'ar' ? '' : 'auto'};
    margin-right: ${props => props.lng.toLowerCase() === 'ar' ? 'auto' : ''};
    position: relative;
  }

  @media (max-width: 970px) {
    flex-direction: column;
    row-gap: 1rem;
    padding: 0;

    .links {
      align-items: center;
      width: 100%;
    }
  }
`

const Title = styled.h3<{ lng?: string }>`
  margin-top: 0;
  text-align: left;
  font-size: 3.125rem;
  font-weight: 300;
  line-height: 105%;
  padding-right: ${props => props.lng.toLowerCase() === 'ar' ? '' : '3.75rem'};
  padding-left: ${props => props.lng.toLowerCase() === 'ar' ? '3.75rem' : ''};
  padding-bottom: 35.3125rem;
  transform: ${({lng}) => lng === 'ar' && 'translateX(-20px)'};


  @media (max-width: 970px) {
    padding-bottom: 1rem;
    padding-left: 1.25rem;
    font-size: 2.125rem;
  }
`

const LeftFigure = (
    <svg className="projects-block__decor-pc" width="950" height="490" viewBox="0 0 950 490" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M0 244.5H950" stroke="#DEDDD8"></path>
        <path d="M0 489.5H950" stroke="#DEDDD8"></path>
        <path d="M618 489.5C753.453 489.5 865 379.977 865 244.258C865 108.538 753.453 0.5 618 0.5"
              stroke="#DEDDD8"></path>
        <path d="M310 1V489" stroke="#00AAC3"></path>
        <path d="M617.5 1V490" stroke="#DEDDD8"></path>
        <path d="M0 0.5H950" stroke="#DEDDD8"></path>
        <path d="M617 489.5C481.547 489.5 371 379.977 371 244.258C371 108.538 481.547 0.5 617 0.5"
              stroke="#00AAC3"></path>
    </svg>
)

const LeftFigureContainer = styled.div<{ lng?: string }>`
  position: absolute;
  width: 59.3125rem;
  height: 30.625rem;
  left: ${props => props.lng === 'ar' ? 'auto' : '-59.3125rem'};
  right: ${props => props.lng === 'ar' ? '-59.3125rem' : ''};
  bottom: 0;

  @media (max-width: 970px) {
    position: static;
    margin-top: 2rem;
  }
`

const StyledLink = styled(Link)`
  padding: 8.0625rem 14.0625rem;
  text-decoration: none;
  width: fit-content;
  min-height: 4.375rem;
  align-items: center;
  justify-content: center;
  max-height: 7.625rem;
  border: 0.0625rem solid #DEDDD8;

  :hover {
    background-color: #00C2DF;
    transition: all ease-in 0.3s;
  }

  .img_container_0 {
    width: 13.75rem;
    height: 4.375rem;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .img_container_1 {
    height: 7.625rem;
    width: 13.75rem;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }


  @media (max-width: 970px) {
    padding-bottom: clamp(4.6875rem, 0.9368748958rem + 16.6694449075vw, 5.9375rem);
    padding-top: clamp(4.6875rem, 0.9368748958rem + 16.6694449075vw, 5.9375rem);

    .img_container_0 {
      width: clamp(9.375rem, 3.7490623437rem + 25.0041673612vw, 11.25rem);
      height: clamp(3.125rem, 1.2496874479rem + 8.3347224537vw, 3.75rem);
    }

    .img_container_1 {
      width: clamp(9.375rem, 3.7490623437rem + 25.0041673612vw, 11.25rem);
      height: clamp(5.375rem, 1.9994374062rem + 15.0025004167vw, 6.5rem);
    }
  }
`

const items = [
    {
        img: '/landing_media/igogo.png',
        link: 'https://www.igogo.io/'
    },
    {
        img: '/landing_media/elavia.png',
        link: 'https://elavia.org/'
    }
];

export const ProjectsSection = () => {

    const {t, i18n} = useTranslation();

    const ref = useRef();
    const isVisible = useIsVisible(ref);
    return (
        <ProjectsSectionPage>
            <BordersContent>
                <ProjectsContent lng={i18n.language}>
                    <Title lng={i18n.language}>
                        {t("landing.nineth.some.1")}<br/>{t("landing.nineth.some.2")}
                    </Title>
                    <FlexColumnStart className={'links'}>
                        {items.map((it, idx) =>
                            <StyledLink to={it.link}>
                                <div className={`img_container_${idx}`}>
                                    <img
                                        src={it.img}/>
                                </div>

                            </StyledLink>
                        )}
                        <LeftFigureContainer
                            lng={i18n.language}
                            ref={ref}
                            className={isVisible ? "element-show" : ""}>
                            {LeftFigure}
                        </LeftFigureContainer>
                    </FlexColumnStart>
                </ProjectsContent>
            </BordersContent>
        </ProjectsSectionPage>
    );
};
