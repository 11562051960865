import styled from "styled-components";
import {StyledSection} from "../../global/StyledSection";
import {FlexColumn, FlexColumnStart, FlexRow, FlexRowBetween} from "../../../style/projectComponents";
import {colors} from "../../../style/colors";
import React, { useRef } from "react";
import {useTranslation} from "react-i18next";
import { useIsVisible } from "../../../hooks/useIsVisible";

const ProfitSectionPage = styled(StyledSection)`
  max-width: 100vw;
  align-items: center;
  width: 100vw;
  padding-left: 0;
  padding-right: 0;
`

const CardWrapper = styled(FlexColumnStart)`
  flex: 0 1 50%;
  padding: 1.875rem;
  gap: 7.8125rem;
  text-align: left;
  border: 0.0625rem solid #DEDDD8;

  .desc {
    text-align: left;
    gap: 1.25rem;

    .percent {
      margin: 0;
      font-size: 2.125rem;
      font-weight: 300;
    }

    p {
      font-size: 1.125rem;
    }
  }

  .year {
    margin: 0;
    font-size: 2.125rem;
    font-weight: 300;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }

`

const LeftFigurePC = (
    <svg className="technologies-block__img technologies-block__img-pc" width="621" height="731" viewBox="0 0 621 731"
         fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M620 304.5C620 136.245 477.806 1 309.551 1C141.296 1 0 135.928 0 304.184" stroke="#DEDDD8"
              stroke-width="0.979592"></path>
        <path d="M0 304.674L620 305" stroke="#DEDDD8" stroke-width="0.979592"></path>
        <path d="M0 609.326L620 609" stroke="#DEDDD8" stroke-width="0.979592"></path>
        <path d="M0 729.5L620 729.5" stroke="#DEDDD8" stroke-width="0.979592"></path>
        <path d="M311 1L311 730" stroke="#DEDDD8" stroke-width="0.979592"></path>
        <path d="M-0.5 669.51L620 670" stroke="#00AAC3" stroke-width="0.979592"></path>
        <path d="M620 1V730" stroke="#DEDDD8" stroke-width="0.979592"></path>
        <path d="M620 305C620 473.255 477.806 609.327 309.551 609.327C141.296 609.327 0 473.419 0 305.163"
              stroke="#00AAC3" stroke-width="0.979592"></path>
    </svg>
);

const LeftFigureTablet = (
    <svg className="technologies-block__img technologies-block__img-tablet" width="1000" height="687"
         viewBox="0 0 1000 687" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M633 310.5C633 138.739 487.761 1 316 1C144.239 1 0 138.739 0 310.5" stroke="#DEDDD8"></path>
        <path d="M0 311H1000" stroke="#DEDDD8"></path>
        <path d="M0 622H634" stroke="#DEDDD8"></path>
        <path d="M317 1V687" stroke="#DEDDD8"></path>
        <path d="M633.5 1V687" stroke="#DEDDD8"></path>
        <path d="M633 311.5C633 483.261 487.761 622 316 622C144.239 622 0 483.261 0 311.5" stroke="#00AAC3"></path>
        <path d="M0 686.5H633" stroke="#00AAC3"></path>
        <path d="M0 1H1000" stroke="#DEDDD8"></path>
        <path d="M634 686.5H1000" stroke="#DEDDD8"></path>
        <path d="M633.5 622H688C860.037 622 999.5 482.537 999.5 310.5V310.5" stroke="#DEDDD8"></path>
        <path
            d="M999.5 251.701C999.5 284.396 972.862 311 940 311C907.138 311 880.5 284.396 880.5 251.701C880.5 219.008 907.137 192.5 940 192.5C972.863 192.5 999.5 219.008 999.5 251.701Z"
            stroke="#DEDDD8"></path>
    </svg>
)

const ProfitContent = styled(FlexColumn)`
  width: 100%;
  padding-top: 7.5rem;
  max-width: 120rem;
  align-items: center;
  position: relative;
  border: 0.0625rem solid ${colors.lightGrayBg};
  border-top-style: none;
  border-bottom: none;
  box-sizing: border-box;
`

const StyledHr = styled.hr`
  width: 100%;
  border: none;
  border-top: 0.0625rem solid rgb(222, 221, 216);
  margin: 0;
`

const RightSide = styled(FlexColumnStart)`
  gap: 5rem;
  width: 53.75rem;
  margin-right: 6.9375rem;

  .profit_title {
    font-size: 3.125rem;
    text-align: left;
    font-weight: 300;
  }

  .bottom_info {
    gap: 1.25rem;

    @media (max-width: 1300px) {
      flex-direction: column;
    }
  }

  @media (max-width: 1040px) {
    width: auto;
    gap: 2.5rem;
    margin: 1.25rem;
    .profit_title {
      font-size: 2.125rem;
    }
  }

`

const Inner = styled(FlexRowBetween)`
  align-items: flex-start;
  gap: 6.9375rem;

  @media (min-width: 1040px) and (max-width: 1300px) {
    padding-bottom: 2rem;
  }

  @media (max-width: 1040px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`

const LeftFigureContainer = styled.div`
  width: 38.8125rem;
  margin-bottom: -2px;

  height: 100%;
   display: flex; 
`

const items = [
    {
        year: 2022,
        percent: 31,
        text: `landing.fourth.items.1.text`
    },
    {
        year: 2023,
        percent: 7.75,
        text: `landing.fourth.items.2.text`
    },
]
export const ProfitSection = () => {

    const {t} = useTranslation();
    const ref = useRef();
    const isVisible = useIsVisible(ref);

    return (
        <ProfitSectionPage id={'profit'}>
            <ProfitContent>
                <StyledHr/>
                <Inner>
                    <LeftFigureContainer ref={ref} className={isVisible ? "element-show" : ""}>
                        {LeftFigurePC}
                    </LeftFigureContainer>
                    <RightSide>
                        <h3 className={'profit_title'}>
                            {t('landing.fourth.title.1')} <br/> {t('landing.fourth.title.2')}
                        </h3>
                        <FlexRow className={'bottom_info'}>
                            {items.map((it) =>
                                <CardWrapper>
                                    <h3 className={'year'}>{it.year}</h3>
                                    <FlexColumnStart className={'desc'}>
                                        <h3 className={'percent'}>{it.percent}%</h3>
                                        <p>{t(it.text)}</p>
                                    </FlexColumnStart>
                                </CardWrapper>
                            )}
                        </FlexRow>
                    </RightSide>
                </Inner>
            </ProfitContent>
        </ProfitSectionPage>
    );
};
