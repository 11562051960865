import {FlexColumnStart} from "../../../style/projectComponents";
import {CircleTitle, DocContent, RectTitle} from "../components";
import {useTranslation} from "react-i18next";

export const Second = () => {

    const {t, i18n} = useTranslation();

    return (
        <DocContent>
            <FlexColumnStart className={'block'}>
                <span>
                    {t("documents.privacy.2.1")}
                </span>
                <p>
                    {t("documents.privacy.2.2")}
                    <CircleTitle
                        text={t("documents.privacy.2.3")}/>
                    <CircleTitle
                        text={t("documents.privacy.2.4")}/>
                    <CircleTitle
                        text={t("documents.privacy.2.5")}/>
                    <CircleTitle
                        text={t("documents.privacy.2.6")}/>
                </p>
            </FlexColumnStart>
            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.privacy.2.7")}
                    <CircleTitle text={t("documents.privacy.2.8")}/>
                    <CircleTitle
                        text={t("documents.privacy.2.9")}/>
                    <CircleTitle
                        text={t("documents.privacy.2.10")}/>
                    <CircleTitle
                        text={t("documents.privacy.2.11")}/>
                    <CircleTitle
                        text={t("documents.privacy.2.12")}/>
                    <CircleTitle
                        text={t("documents.privacy.2.13")}/>
                    <CircleTitle text={t("documents.privacy.2.14")}/>
                    <RectTitle
                        text={t("documents.privacy.2.15")}/>
                    <RectTitle
                        text={t("documents.privacy.2.16")}/>
                    <RectTitle
                        text={t("documents.privacy.2.17")}/>
                    <CircleTitle
                        text={t("documents.privacy.2.18")}/>
                </p>
            </FlexColumnStart>
            <FlexColumnStart className={'block'}>
                <span>
                    {t("documents.privacy.2.19")}
                </span>
                <p>
                    {t("documents.privacy.2.20")}
                    <CircleTitle
                        text={t("documents.privacy.2.21")}/>
                    <CircleTitle text={t("documents.privacy.2.22")}/>
                </p>
            </FlexColumnStart>
            <FlexColumnStart className={'block'}>
                <span>
                    {t("documents.privacy.2.23")}
                </span>
                <p>
                    {t("documents.privacy.2.24")}
                    <CircleTitle
                        text={t("documents.privacy.2.25")}/>
                    <CircleTitle
                        text={t("documents.privacy.2.26")}/>
                    <CircleTitle
                        text={t("documents.privacy.2.27")}/>
                </p>
            </FlexColumnStart>
        </DocContent>
    );
};