import styled from "styled-components";
import {FlexColumn, Page} from "../../style/projectComponents";
import HeaderComponent from "../../components/global/HeaderComponent";
import {OperationsComponent} from "../../components/DashboardComponents/OperationsComponent";
import React, {useEffect, useState} from "react";
import {colors} from "../../style/colors";
import {useMutation} from "react-query";
import {DataEmptyComponent} from "../../components/global/DataEmptyComponent";
import {useTranslation} from "react-i18next";
import {IGetWithdrawalRequest, IGetWithdrawalResponse, IWithdrawalStatus} from "../../service/withdrawal-api/models";
import WithdrawalApi from "../../service/withdrawal-api/WithdrawalApi";

const StyledBg = styled(FlexColumn)`
  background: ${colors.pageBg};
  width: 100vw;
  padding: 24px;
  height: 100%;
  margin-top: 110px;
  justify-content: flex-start;
`;

const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1100px;
  justify-content: start;
  background: #fff;
  padding: 26px 16px;
  border-radius: 8px;
  max-height: 740px;
  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`;

const ChartTitle = styled.p`
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-top: 18px;
  padding-bottom: 18px;
`;

export const AdminWithdrawalHistory = () => {
    const getWithdrawalQuery = useMutation((data: IGetWithdrawalRequest) => WithdrawalApi.getWithdrawal(data));
    const [withdrawals, setWithdrawals] = useState<IGetWithdrawalResponse>({
        items: [],
        totalCount: 0
    });
    const statuses = [IWithdrawalStatus.approved, IWithdrawalStatus.rejected, IWithdrawalStatus.cancelled]

    useEffect(() => {
        const searchParams: IGetWithdrawalRequest = {
            statuses: statuses,
            skip: 0,
            take: 200
        }
        getWithdrawalQuery.mutate(searchParams, {
            onSuccess: data => {
                const res: IGetWithdrawalResponse = {
                    ...data,
                    items: data.items.map(it => ({
                        ...it,
                        withdrawalAmount: Number(it.withdrawalAmount.toFixed(2))
                    }))
                };
                setWithdrawals(res);
            }
        });
    }, []);

    const {t, i18n} = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page>
            <HeaderComponent/>
            <StyledBg>
                <OperationsWrapper>
                    <ChartTitle>{t('adminWithdrawHistory.withdrawHistory')}</ChartTitle>
                    {withdrawals?.items.length > 0
                        ? <OperationsComponent
                            keys={['updatedOn','createdOn', 'userName', 'withdrawalAmount', 'status']}
                            operations={withdrawals.items}/>
                        : <DataEmptyComponent isLoading={getWithdrawalQuery.isLoading}/>
                    }
                </OperationsWrapper>
            </StyledBg>
        </Page>
    );
};