import {FlexColumnStart, FlexRow} from "../../../../style/projectComponents";
import {BottomInvestRectangle, BottomLeftInvestRectangle, RightInvestRectangle} from "./utils";
import React, {useRef} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useIsVisible} from "../../../../hooks/useIsVisible";

const Figure = styled.div`
  //z-index: -10;
`

const Wrapper = styled(FlexRow)`
  position: relative;
  width: 100%;
  border: 0.0625rem solid #DEDDD8;
  margin: 0;
  padding: 0;
`

const Content = styled(FlexRow)`
  border-left: 0.0625rem solid #DEDDD8;
  border-right: 0.0625rem solid #DEDDD8;
  width: 100%;
  padding-top: 9rem;
  max-width: 120rem;
  position: relative;
  box-sizing: border-box;
`

const Inner = styled(FlexRow)`
  border-top: 0.0625rem solid #DEDDD8;
  width: 100%;
  align-items: start;
  position: relative;
  background: #FCFBF5;

  @media (max-width: 1270px) {
    flex-direction: column;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: stretch;

  @media (max-width: 1270px) {
    flex-direction: column;
  }
`

const LeftWrapper = styled(FlexColumnStart)<{ lng?: string }>`
  width: 38.5625rem;
  border-left: ${props => props.lng.toLowerCase() === 'ar' ? '' : '0.0625rem solid #DEDDD8'};
  border-right: ${props => props.lng.toLowerCase() === 'ar' ? '0.0625rem solid #DEDDD8' : ''};
  text-align: start;
  padding: 4.5rem calc(2.5rem - 4px) 27rem;
  background: #FCFBF5;
  position: relative;
  z-index: 9;
  box-sizing: border-box;

  h3 {
    font-size: 2.125rem;
    font-weight: 300;
    margin: 0;
    padding-bottom: 20px;
  }

  p {
    font-size: 1.125rem;
    font-weight: 300;
  }

  @media (max-width: 1270px) {
    width: auto;
    padding: 2.5rem;
    border-right: 0.0625rem solid #DEDDD8;
    border-bottom: 0.0625rem solid #DEDDD8;
  }
`

const BottomInvestRectContainer = styled(Figure)<{ lng?: string }>`
  position: absolute;
  bottom: 0;
  left: ${props => props.lng.toLowerCase() === 'ar' ? '' : '0'};
  display: flex;


  @media (max-width: 1270px) {
    display: none;
  }
`
const RightInvestRectContainer = styled(Figure)<{ lng?: string }>`
  position: absolute;
  bottom: 0;
  display: flex;
  top: 8rem;
  right: ${props => props.lng.toLowerCase() === 'ar' ? '' : '-19.3125rem'};
  left: ${props => props.lng.toLowerCase() === 'ar' ? '-19.3125rem' : ''};


  @media (max-width: 1270px) {
    display: none;
  }
`

const BottomLeftInvestContainer = styled(Figure)<{ lng?: string }>`
  bottom: 0;
  left: ${props => props.lng.toLowerCase() === 'ar' ? '' : '-19.3125rem'};
  right: ${props => props.lng.toLowerCase() === 'ar' ? '-19.3125rem' : ''};
  width: 19.375rem;
  display: flex;
  position: absolute;
  z-index: -10;


  @media (max-width: 1270px) {
    display: none;
  }
`

const RightWrapper = styled(FlexColumnStart)<{ lng?: string }>`
  width: 42.9375rem;
  box-sizing: border-box;
  border-left: 0.0625rem solid #DEDDD8;
  border-right: 0.0625rem solid #DEDDD8;
  text-align: start;
  padding: 7.5rem;
  position: relative;
  height: auto;
  //background-color: white;

  .invest {
    width: 25.9375rem;
    text-align: start;
    z-index: 100;


    h3 {
      font-size: 1.5rem;
      font-weight: 300;
    }

    p {
      font-size: 1rem;
      font-weight: 300;

      span {
        color: gray;
      }
    }
  }

  .ml {
    width: 25.9375rem;
    text-align: start;
    margin-top: 5rem;
    z-index: 100;

    h3 {
      font-size: 1.5rem;
      font-weight: 300;
    }

    p {
      font-size: 1rem;
      font-weight: 300;
    }
  }

  @media (max-width: 1270px) {
    width: auto;
    padding: 2.5rem;

    .invest, .ml {
      width: auto;
    }
  }
`

export const Third = () => {
    const {t, i18n} = useTranslation();

    const ref = useRef();
    const isVisible = useIsVisible(ref);

    return (
        <Wrapper ref={ref} className={isVisible ? "element-show" : ""}>
            <Content>
                <Inner>
                    <ContentWrapper>
                        <LeftWrapper lng={i18n.language}>
                            <h3>
                                {t("landing.third.3.title")}
                            </h3>
                            <p>
                                {t("landing.third.3.subtitle")}
                            </p>
                        </LeftWrapper>

                        <RightWrapper lng={i18n.language}>
                            <FlexColumnStart className={'invest'}>
                                <h3>
                                    {t("landing.third.3.invest")}
                                </h3>
                                <p>
                                    {t("landing.third.3.uniqueMethod")}

                                    <br/>
                                    <br/>
                                    <br/>

                                    <span>{t("landing.third.3.weAnalyze")}</span>
                                    <br/>
                                    <br/>
                                    {t("landing.third.3.weAnalyzeNext")}
                                </p>
                            </FlexColumnStart>

                            <FlexColumnStart className={'ml'}>
                                <h3>
                                    {t("landing.third.3.ai")}
                                </h3>
                                <p>
                                    {t("landing.third.3.aiTitle")}
                                    <br/>
                                    {t("landing.third.3.aiNext")}
                                </p>
                            </FlexColumnStart>
                        </RightWrapper>

                        <BottomLeftInvestContainer lng={i18n.language}>
                            {BottomLeftInvestRectangle}
                        </BottomLeftInvestContainer>

                        <BottomInvestRectContainer lng={i18n.language}>
                            {BottomInvestRectangle}
                        </BottomInvestRectContainer>

                        <RightInvestRectContainer lng={i18n.language}>
                            {RightInvestRectangle}
                        </RightInvestRectContainer>
                    </ContentWrapper>
                </Inner>
            </Content>
        </Wrapper>
    );
};
