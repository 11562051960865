import {ChartComponent} from "../../DashboardComponents/ChartComponent";
import {OperationsComponent} from "../../DashboardComponents/OperationsComponent";
import React, {FC} from "react";
import styled from "styled-components";
import {FlexColumn} from "../../../style/projectComponents";
import {IUserListResponse} from "../../../service/user-api/models";
import {useNavigate} from "react-router-dom";
import {DataEmptyComponent} from "../../global/DataEmptyComponent";
import {useTranslation} from "react-i18next";
import {AdminDepositsComponent} from "./AdminDepositsComponent";
import {GetFullStatisticAdmin} from "../../../service/admin-api/models";
import {format} from "date-fns";
import {ru} from "date-fns/locale";

const BalanceChartWrapper = styled(FlexColumn)`
  width: 100%;
  max-height: 380px;
  max-width: 1100px;
  justify-content: start;
  background: #fff;
  padding: 26px 16px;
  margin: 24px;
  border-radius: 8px;
  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`;

const DepositsWrapper = styled(BalanceChartWrapper)`
`;

const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1100px;
  justify-content: start;
  background: #fff;
  padding: 26px 16px;
  border-radius: 8px;
  max-height: 740px;
  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`;

const ChartTitle = styled.p`
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-top: 18px;
  //padding-bottom: 18px;
`;
export const AdminAnalyticsBase: FC<{
    dashboardData: GetFullStatisticAdmin,
    usersData: IUserListResponse,
    isUsersLoading?: boolean,
    isDashboardLoading?: boolean
}> = (props) => {
    const {dashboardData, isDashboardLoading, isUsersLoading, usersData} = props;
    const navigate = useNavigate();
    const handleOpenUser = (data: any) => {
        navigate(`/admin/${data.id}`);
    };

    const {t, i18n} = useTranslation();

    const handleUsers = () => {
        const res = usersData.users.map(it => (
            {
                ...it,
                balance: it.wallets.find(it => it.currency === 'USDT')?.balance ?? 0
            }
        ));
        return res;
    };
    const getDate = (date: string) => format(new Date(date), 'dd.MM.yy', {locale: ru})
    console.log(dashboardData)

    return (
        <>
            <BalanceChartWrapper>
                <ChartTitle>{t('adminAnalytics.depositsFlow')}</ChartTitle>
                {!isDashboardLoading && dashboardData?.depositsStatistics?.length > 0
                    ? <ChartComponent
                        titleKey={t('adminAnalytics.depositsAmount')}
                        valueKey={'depositsAmount'}
                        xAxisKey={'date'}
                        yAxisKey={'depositsAmount'}
                        data={dashboardData
                            .depositsStatistics.at(0)
                            .depositsPerYears
                            .slice()
                            .sort((a, b) => a.year - b.year)
                            .flatMap(year => year.depositsPerDays.map(day => ({...day, date: getDate(day.date)})))
                            .flatMap((it, idx, array) => {
                                return {
                                    ...it,
                                    amountDiff: idx > 0 ? it.depositsAmount - array[idx - 1].depositsAmount : 0
                                }
                            })
                        }
                    />
                    : <DataEmptyComponent isChart/>
                }
            </BalanceChartWrapper>
            <BalanceChartWrapper>
                <ChartTitle>{t('adminAnalytics.balanceSum')}</ChartTitle>
                {!isDashboardLoading && dashboardData?.systemBalancesStatistics.length > 0
                    ? <ChartComponent
                        titleKey={t('adminAnalytics.balanceSum')}
                        valueKey={'totalBalance'}
                        yAxisKey={'totalBalance'}
                        xAxisKey={'date'}
                        data={dashboardData
                            .systemBalancesStatistics.at(0)
                            .systemBalancesPerYears
                            .slice()
                            .sort((a, b) => a.year - b.year)
                            .flatMap(year => year.systemBalancesPerDays.map(day => ({
                                ...day,
                                date: getDate(day.date)
                            })))
                            .flatMap((it, idx, array) => {
                                return {
                                    ...it,
                                    amountDiff: idx > 0 ? it.totalBalance - array[idx - 1].totalBalance : 0
                                }
                            })
                    }
                    />
                    : <DataEmptyComponent isChart/>
                }
            </BalanceChartWrapper>
            <BalanceChartWrapper>
                <ChartTitle>{t('adminAnalytics.registration')}</ChartTitle>
                {!isDashboardLoading && dashboardData?.usersStatistic.registrationsPerDays.length > 0
                    ? <ChartComponent
                        titleKey={t('adminAnalytics.quantity')}
                        valueKey={'registrationsCount'}
                        variant={'linear'}
                        yAxisKey={'registrationsCount'}
                        xAxisKey={'date'}
                        data={dashboardData.usersStatistic.registrationsPerDays.map(it => ({
                            ...it,
                            date: getDate(it.date)
                        }))}/>
                    : <DataEmptyComponent isChart/>
                }
            </BalanceChartWrapper>

            <DepositsWrapper>
                <ChartTitle>{t('adminUserDetail.balances.title')}</ChartTitle>
                {!isDashboardLoading && dashboardData?.balancesStatistics.length > 0
                    ? <AdminDepositsComponent
                        data={dashboardData.balancesStatistics}/>
                    : <DataEmptyComponent/>
                }
            </DepositsWrapper>


            <OperationsWrapper>
                <ChartTitle>{t('adminAnalytics.users')}</ChartTitle>
                {!isUsersLoading && usersData?.users.length > 0
                    ? <OperationsComponent
                        keys={['firstName', 'lastName', 'balance']}
                        onClickItem={handleOpenUser}
                        operations={handleUsers()}/>
                    : <DataEmptyComponent/>
                }
            </OperationsWrapper>
        </>
    );
};
