import {
    FlexColumn,
    FlexColumnStart, FlexRow,
    StyledButton,
    StyledLink
} from "../../../style/projectComponents";
import {Link as ScrollLink} from 'react-scroll';
import styled from "styled-components";
import {colors} from "../../../style/colors";
import React, {ComponentPropsWithoutRef, FC, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {adminLinks, headerAuthLinks, headerNotAuthLinks} from "../headerLinks";
import {useLocation, useNavigate} from "react-router-dom";
import {AdminPercent} from "../../../pages/AdminPages/AdminPercent";
import UserStorage from "../../../storage/UserStorage/UserStorage";
import {ChangeLanguage} from "../ChangeLanguage";
import {observer} from "mobx-react-lite";
import {LogoutModal} from "./LogoutModal";

const Box = styled(FlexColumnStart)<{ isAuth?: boolean, isOpen?: boolean }>`
  position: ${({isAuth, isOpen}) => isAuth && isOpen ? 'fixed' : 'absolute'};
  width: ${props => props.isAuth && props.isOpen ? '100vw' : ''};
  height: ${props => props.isAuth && props.isOpen ? '100vh' : ''};
  bottom: ${props => props.isAuth ? '0' : ''};;
  right: ${props => props.isAuth ? '0' : ''};
  left: ${props => props.isAuth ? '0' : ''};
  top: ${props => props.isAuth ? '0' : ''};
  z-index: -100;

  @media (max-width: 600px) {
    z-index: -1;
    width: 100%;
  }

`

const Wrapper = styled(FlexColumnStart)<{ isAuth?: boolean; lng?: string; open?: boolean; isScrolled?: boolean }>`
  position: fixed;
  transform: translateX(${({open, lng}) => open ? '0' : lng.toLowerCase() === 'ar' ? '-150%' : '150%'});
  transition: all 0.3s ease-in-out;
  height: ${props => props.isAuth ? 'fit-content' : `calc(100% - ${props.isScrolled ? '109' : '142'}px)`};
  border-radius: ${props => props.isAuth ? '12px' : '0px'};
  background-color: ${props => props.isAuth ? 'white' : '#FCFBF5'};
  z-index: 100;
  width: ${props => props.isAuth ? 'fit-content' : '300px'};
  min-width: ${props => props.isAuth && "150px" };
  padding: ${props => props.isAuth ? '18px' : '20px'};
  padding-right: ${props => props.isAuth && '18px'};
  padding-left: ${props => !props.isAuth && '75px'};
  right: ${props => props.isAuth ? '30px' : '0px'};
  left: ${props => props.lng.toLowerCase() === 'ar' ? '10px' : ''};
  bottom: 0;
  top: ${props => props.isAuth ? '90px' : ''};
  border-left: ${props => !props.isAuth && `0.0625rem solid ${colors.pageBg}`};
  box-shadow: ${props => props.isAuth && '0 1px 2px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05)'};
  @media (max-width: 600px) {
    left: 0;
    top: 0;
    padding: 20px;
    height: calc(100%);
    border-radius: 0;
    z-index: -1;
    width: auto;
    min-width: 91%;
    border-left: none;
  }
`;

const CustomStyledLink = styled(StyledLink)<{ mini?: boolean, isAuth?: boolean }>`
  color: ${colors.secondary};
  font-size: ${props => props.isAuth && '1rem' || (props.mini ? '1rem' : '1.8rem')};
  font-weight: ${(props) => props.isAuth && '400' || !props.mini && '300'};
  line-height: 110%;

  &[data-admin-btn] {
    flex-direction: row;
    display: flex;
    font-size: 1rem;
    align-items: center;
    font-weight: 400;

    @media (max-width: 600px) {
      font-size: 1.25rem;
    }

    p {
      padding-right: 10px;
    }
  }

  :hover {
    color: ${colors.primary};
    transition: all 0.3s ease;

    img {
      filter: invert(53%) sepia(33%) saturate(6069%) hue-rotate(155deg) brightness(96%) contrast(101%);
    }
  }

  @media (max-width: 600px) {
    font-size: 1.25rem;
  }
`;

export type IMenuModalProps = {
    isAuth?: boolean;
    open: boolean;
    handleMenu: () => void;
    isScrolled?: boolean;
}

const LangWrapper = styled(FlexRow)`
  @media (min-width: 600px) {
    display: none;
  }
`;

const ExitBtn = styled(FlexRow)<{ isAuth: boolean }>`
  cursor: pointer;
  gap: 0.6rem;
  font-size: ${({isAuth}) => isAuth ? "1.25rem" : "2.125rem"};
  line-height: 110%;
  font-weight: ${({isAuth}) => isAuth ? "400" : "300"};
  align-items: center;

  //@media (max-height: 780px) {
  //  font-size: 1.75rem;
  //}

  @media (max-width: 600px) {
    font-size: 1.75rem;
  }

  span {
    color: ${colors.redFail}
  }

  :hover {
    img {
      filter: invert(49%) sepia(63%) saturate(1871%) hue-rotate(151deg) brightness(92%) contrast(101%);
      transition: all 0.3s ease;
    }

    span {
      color: ${colors.primary};
      transition: all 0.3s ease;
    }
  }
`;
const ContactsWrapper = styled(FlexColumnStart)`
  gap: 2rem;
  margin-top: 4rem;
  justify-content: space-between;
  @media (max-height: 780px) {
    margin-top: 1rem;
    gap: 1rem;
  }
`;

const BtnWrapper = styled(FlexColumn)`
  gap: 1rem;
  margin-top: 3.75rem;
  width: 100%;
  align-items: center;
  justify-content: center;

  button {
    width: 100%;
  }

  @media (min-width: 768px) {
    display: none;
  }
`

const StyledScrollLink = styled(ScrollLink)<{ isAuth?: boolean, mini?: boolean }>`
  color: ${colors.secondary};
  font-size: ${props => props.isAuth && '1rem' || (props.mini ? '1rem' : '1.8rem')};
  font-weight: ${(props) => props.isAuth && '400' || !props.mini && '300'};
  line-height: 110%;
  cursor: pointer;

  @media (max-height: 780px) {
    font-size: ${props => props.mini ? '1rem' : '1.75rem'};
  }

  :hover {
    color: ${colors.primary};
    transition: all 0.3s ease;

    img {
      filter: invert(53%) sepia(33%) saturate(6069%) hue-rotate(155deg) brightness(96%) contrast(101%);
    }
  }

  @media (max-width: 600px) {
    font-size: 1.25rem;
  }
`

const MenuModal: FC<IMenuModalProps & ComponentPropsWithoutRef<"div">> = (props) => {
    const {open, handleMenu, isAuth, ...other} = props;
    const {t, i18n} = useTranslation();
    const location = useLocation();
    const isAdminPaths = location.pathname.includes('admin');
    const [visiblePercent, setVisiblePercent] = useState<boolean>(false);
    const [visibleLogout, setVisibleLogout] = useState<boolean>(false);
    const UserStore = useContext(UserStorage);
    const isAdmin = UserStore.isAdmin();
    const navigate = useNavigate();

    useEffect(() => {
        if (open) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }
    }, [open]);

    return (
        <Box
            isOpen={open}
            onClick={e => {
                if (e.currentTarget === e.target) {
                    if (open) {
                        handleMenu();
                    }
                }
            }}
            isAuth={isAuth}>
            <Wrapper lng={i18n.language} isAuth={isAuth} open={open} {...other}>
                <FlexColumnStart style={{rowGap: '1.875rem'}}>
                    {(isAuth
                            ? isAdminPaths
                                ? adminLinks
                                : headerAuthLinks
                            : headerNotAuthLinks
                    ).map((it, idx) => (
                        isAuth
                            ? <CustomStyledLink
                                to={it.to}
                                isAuth={isAuth}>
                                {t(it.text)}
                            </CustomStyledLink>
                            : <StyledScrollLink
                                onClick={() => handleMenu()}
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                to={it.to}>
                                {t(it.text)}
                            </StyledScrollLink>
                    ))}

                    {isAdminPaths &&
                        <StyledButton
                            onClick={() => setVisiblePercent(true)}
                            noRadius
                            style={{padding: 'var(--16, 1rem)', width: '100%'}}>
                            {t('adminPercent.accrualInterest')}
                        </StyledButton>}

                    {isAuth && isAdmin && <CustomStyledLink
                        data-admin-btn
                        to={!isAdminPaths ? '/admin/analytics' : '/dashboard'}>
                        <p>{t('menu.adminPanel')}</p>
                        <img src={'/images/door.svg'}/>
                    </CustomStyledLink>}

                    <LangWrapper>
                        <ChangeLanguage/>
                    </LangWrapper>

                    {isAuth && <ExitBtn onClick={() => setVisibleLogout(true)} isAuth>
                        <span>{t('menu.logout')}</span>
                    </ExitBtn>}
                </FlexColumnStart>

                {!isAuth && <ContactsWrapper>
                    {!isAuth && <>
                        <FlexColumnStart style={{fontSize: '1rem', height: 50, justifyContent: 'space-between'}}>
                        <span style={{color: colors.lightGray}}>
                    {t('menu.phone')}
                        </span>
                            <CustomStyledLink mini to={'tel:+994 50 616 5928'}>+994 50 616 5928</CustomStyledLink>
                        </FlexColumnStart>

                        <FlexColumnStart style={{fontSize: '1rem', height: 50, justifyContent: 'space-between'}}>
                        <span style={{color: colors.lightGray}}>
                    {t('menu.email')}
                        </span>
                            <CustomStyledLink mini
                                              to={'mailto:inbox@kanna.capital'}>inbox@kanna.capital</CustomStyledLink>
                        </FlexColumnStart>
                    </>
                    }
                </ContactsWrapper>}


                {!isAuth && <BtnWrapper>
                    <StyledButton
                        onClick={() => navigate('/register')}>
                        {t("auth.reg_title")}
                    </StyledButton>

                    <StyledButton
                        style={{
                            height: 'auto',
                            minHeight: 25
                        }}
                        onClick={() => navigate('/login')}
                        variant={'text'}>
                        {t("auth.login")}
                    </StyledButton>
                </BtnWrapper>
                }
            </Wrapper>

            {isAdminPaths && visiblePercent && <AdminPercent setIsOpen={setVisiblePercent}/>}
            {visibleLogout && <LogoutModal setIsOpen={setVisibleLogout}/>}
        </Box>
    );
};

export default observer(MenuModal);

