import React from "react";
import styled from "styled-components";
import {colors} from "../../style/colors";


interface BlockProps {
    isActive: boolean;
}
const Block = styled.button<BlockProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 6px 14px;
  border-radius: 6px;
  border: 1px solid ${colors.blueGray};
  margin: 0 3px; 
  cursor: pointer;
  background: ${({isActive}) => isActive ? colors.blueGray : "none"};
  
  @media(max-width: 768px) {
    width: calc(100vw - 32px);
  }

  img {
    width: 30px;
    height: 30px;
  }

  p {
    font-size: 14px;
    text-align: center;
    font-weight: 300;
    margin: 0;
    width: 100%;
  }
`;

interface FilterMethodButtonProps {
    title: string;
    isActive: boolean;
    setActiveTypeId: any;
    id: number;
}

export const FilterMethodButton: React.FC<FilterMethodButtonProps> = ({
                                                                            title,
                                                                            isActive,
                                                                            setActiveTypeId,
                                                                            id
                                                                        }) => {

    return (
        <Block isActive={isActive} onClick={() => setActiveTypeId(id)}>
            <p>{title}</p>
        </Block>
    );
};
