import {FlexColumnStart, FlexRowBetween} from "../../../../style/projectComponents";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useRef} from "react";
import {useIsVisible} from "../../../../hooks/useIsVisible";

const LeftTopFigure = (
    <svg className="about-block__top-decor-pc" width="634" height="468" viewBox="0 0 634 468" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M633.5 0V468" stroke="#DEDDD8"></path>
        <circle cx="389" cy="389" r="78.5" stroke="#DEDDD8"></circle>
        <path d="M0 310.5H633" stroke="#DEDDD8"></path>
        <path d="M310.5 311V467" stroke="#DEDDD8"></path>
        <path d="M0 467.5H634" stroke="#DEDDD8"></path>
    </svg>
);

const LeftFigureWrapper = styled.div<{ lng?: string }>`
  position: absolute;
  left: ${props => props.lng.toLowerCase() === 'ar' ? '' : '-46.875rem'};
  right: ${props => props.lng.toLowerCase() === 'ar' ? '-46.875rem' : ''};
  top: 0;
  width: 39.625rem;
  height: 29.3125rem;

  @media (max-width: 900px) {
    position: static;
  }
`

const TextContent = styled(FlexColumnStart)<{ lng?: string }>`
  max-width: 53.75rem;
  padding-top: 7.5rem;
  width: 100%;
  margin-left: ${props => props.lng.toLowerCase() === 'ar' ? '' : 'auto'};
  margin-right: ${props => props.lng.toLowerCase() === 'ar' ? 'auto' : ''};
  position: relative;
  @media (max-width: 900px) {
    padding-top: 0;
    margin: 0;
  }
`

const Title = styled.h3`
  margin-bottom: 5rem;
  text-align: left;
  font-size: 3.125rem;
  font-weight: 300;
  line-height: 105%;

  @media (max-width: 900px) {
    font-size: 2.125rem;
    padding-right: 1.25rem;
  }
`

const DescContainer = styled(FlexRowBetween)<{ lng?: string }>`
  width: 100%;
  gap: 5.375rem;
  justify-content: flex-start;

  p {
    font-size: 1.125rem;
    text-align: left;
    line-height: 140%;
    padding-right: 40px;
    width: auto;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 2.5rem;

    p {
      font-size: 1rem;
    }
  }

  .subtext {
    transform: ${({lng}) => lng === "ar" && 'translateX(40px)'};
  }
`
const PlansTitle = styled(Title)<{ lng?: string }>`
  margin: 0;
  font-size: 2.125rem;
  width: 100%;
  align-self: ${({lng}) => lng === "ar" ? "flex-end" : "flex-start"};
  transform: ${({lng}) => lng === "ar" && 'translateX(40px)'};
`

export const First = () => {
    const {t, i18n} = useTranslation();
    const ref = useRef();
    const isVisible = useIsVisible(ref);
    return (
        <TextContent lng={i18n.language} id={'plans'}>
            <LeftFigureWrapper
                lng={i18n.language}
                ref={ref} className={isVisible ? "element-show" : ""}>
                {LeftTopFigure}
            </LeftFigureWrapper>
            <Title>
                {t("landing.eith.1.title.1")}
                <br/>
                {t("landing.eith.1.title.2")}
            </Title>
            <DescContainer lng={i18n.language}>
                <PlansTitle lng={i18n.language}>
                    {t("landing.eith.1.plans")} 2024
                </PlansTitle>
                <p className={'subtext'}>
                    {t("landing.eith.1.subtitle.1")}
                    <br/>
                    <br/>
                    {t("landing.eith.1.subtitle.2")}
                </p>
            </DescContainer>
            <div id={'cooperation'}></div>
        </TextContent>
    )
}
