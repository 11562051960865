import {withdrawTypes} from "../../config/withdrawTypes";
import {ethereumAddressRegex, tronAddressRegex} from "../../utils/addressValidation";

export const getCurrentRegex = (activeTypeId: number) => {
    switch (withdrawTypes[activeTypeId].title) {
        case "USDT ERC-20":
            return ethereumAddressRegex;
        case "USDT TRC-20":
            return tronAddressRegex;
        case "USDT BEP-20":
            return ethereumAddressRegex;
    }
}

export const getCurrentLabel = (activeTypeId: number) => {
    switch (withdrawTypes[activeTypeId].title) {
        case "USDT ERC-20":
            return 'withdraw.eth_address';
        case "USDT TRC-20":
            return 'withdraw.trc_address';
        case "USDT BEP-20":
            return 'withdraw.eth_address';
    }
}

export const isWalletGood = (pattern: any) => {
    return {
        required: {
            value: true,
            message: 'withdraw.errors.wallet.required',
        },
        pattern: {
            value: pattern,
            message: 'withdraw.errors.wallet.pattern'
        },
        minLength: 1,
    }
};

export const isAmountGood = (userBalance: number) => {
    return {
        required: {
            value: true,
            message: 'withdraw.errors.amount.required',
        },
        pattern: {
            value: /[0-9]{1,}$/g,
            message: 'withdraw.errors.amount.pattern',
        },
        max: {
            value: userBalance,
            message: 'withdraw.errors.amount.max',
        },
        min: {
            value: 0.01,
            message: 'withdraw.errors.amount.min',
        },
        valueAsNumber: true
    }
}