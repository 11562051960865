import styled from "styled-components";
import {StyledSection} from "../../global/StyledSection";
import {FlexColumn, FlexRowBetween} from "../../../style/projectComponents";
import {colors} from "../../../style/colors";
import {useTranslation} from "react-i18next";
import {useRef} from "react";
import {useIsVisible} from "../../../hooks/useIsVisible";


const DocumentSectionPage = styled(StyledSection)`
  max-width: 100vw;
  align-items: center;
  width: 100vw;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  border: 0.0625rem solid #DEDDD8;
  border-left-style: none;
  border-right-style: none;
`

const DocumentContent = styled(FlexColumn)`
  width: 100%;
  max-width: 81.25rem;
  box-sizing: content-box;
  margin: 0 auto;
  align-items: center;
  position: relative;
  border-top-style: none;
`

const VerticalHr = styled.hr<{ position: 'left' | 'right' }>`
  height: 100%;
  background-color: #DEDDD8;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  ${props => props.position === 'left' ? 'left: -5rem' : "right: -5rem"};
  width: 0.0625rem;
`

const StyledHr = styled.hr`
  width: 100%;
  margin: 0;
  height: 0.0625rem;
  border: none;
  background-color: #DEDDD8;
`

const Inner = styled(FlexColumn)`
  width: 100%;
  position: relative;
  align-items: center;
  padding-top: 7.5rem;
  max-width: 62rem;
  border: 0.0625rem solid #DEDDD8;
  border-top: none;
  border-bottom: none;
`

const BordersContent = styled(FlexColumn)`
  max-width: 120rem;
  align-items: center;
  position: relative;
  border: 0.0625rem solid ${colors.lightGrayBg};
  border-bottom: none;
  border-top-style: none;
  width: 100%;
  box-sizing: border-box;
`

const LeftFigure = (
    <svg className="certificate-block__decor-first certificate-block__decor-pc" width="465" height="352"
         viewBox="0 0 465 352" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 232.5H464" stroke="#DEDDD8"></path>
        <circle cx="348.5" cy="116.5" r="116" stroke="#DEDDD8"></circle>
        <path d="M0 351.5H464" stroke="#DEDDD8"></path>
    </svg>
)

const RightFigure = (
    <svg className="certificate-block__decor-second certificate-block__decor-pc" width="465" height="507"
         viewBox="0 0 465 507" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 0.5H465" stroke="#00AAC3"></path>
        <path d="M464.5 273C464.5 145.098 360.583 41 232.5 41C104.417 41 0.5 145.098 0.5 273" stroke="#00AAC3"></path>
        <path d="M464.5 274C464.5 401.902 360.583 506.5 232.5 506.5C104.417 506.5 0.5 401.902 0.5 274"
              stroke="#DEDDD8"></path>
        <path d="M0.5 273.5H465" stroke="#DEDDD8"></path>
    </svg>
)

const Title = styled.h3`
  margin-bottom: 5rem;
  font-size: 3.125rem;
  font-weight: 300;
  line-height: 105%;
  margin-top: 0;

  @media (max-width: 900px) {
    font-size: clamp(2rem, 0.125rem + 6.25vw, 3.125rem);
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  @media(max-width: 480px){
    font-size: clamp(1rem, 0.125rem + 6.25vw, 3.125rem);
  }
`

const DocumentWrapper = styled(FlexColumn)<{ lng?: string }>`
  position: relative;
  
  .garanties {
    box-sizing: border-box;

    position: relative;
    border-top: 0.0625rem solid #DEDDD8;
    border-bottom: 0.0625rem solid #DEDDD8;
    width: 100%;
    align-items: stretch;
    padding-right: 1.25rem;



    @media (max-width: 900px) {
      flex-direction: column;
    }

    .text {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 50%;
      flex: 1 1 50%;
      position: relative;
      padding: 2.5rem 0.625rem;
      @media (max-width: 900px) {
        width: 100%;
      }

      h3 {
        font-size: 2.125rem;
        text-align: center;
        line-height: 110%;
        font-weight: 300;

        @media (max-width: 900px) {
          font-size: clamp(1.375rem, 0.5416087923rem + 2.7779706924vw, 1.875rem);
          max-width: 320px;
        }
      }

    }

    .text:first-child {
      border-right: ${props => props.lng.toLowerCase() === 'ar' ? '' : '0.0625rem solid #DEDDD8'};
      border-left: ${props => props.lng.toLowerCase() === 'ar' ? '0.0625rem solid #DEDDD8' : ''};

      h3 {
        max-width: 325px;
      }

      @media (max-width: 900px) {
        border-right: none;
        border-bottom: 0.0625rem solid #DEDDD8;
      }
    }
  }
`

const FigureContainer = styled(FlexColumn)`
  svg {
    width: 100%;
    height: 100%;
  }
`

const LeftFigureContainer = styled(FigureContainer)`
  position: absolute;
  left: -29.0625rem;
  top: 9.1875rem;
  width: max-content;

`

const RightFigureContainer = styled(FigureContainer)`
  position: absolute;
  bottom: 0;
  right: -29.0625rem;
  width: max-content;
`
export const DocumentSection = () => {

    const {t, i18n} = useTranslation();

    const ref = useRef();
    const isVisible = useIsVisible(ref);

    return (
        <DocumentSectionPage id={'document'}>
            <BordersContent>
                <DocumentContent ref={ref} className={isVisible ? "element-show" : ""}>
                    <VerticalHr position={"left"}/>
                    <Inner>
                        <Title>
                            {t("landing.sixth.title.1")} <br/>
                            {t("landing.sixth.title.2")}
                        </Title>

                        <DocumentWrapper lng={i18n.language}>
                            <FlexRowBetween className={'garanties'}>
                                <FlexColumn className={'text'}>
                                    <h3 style={{margin: 0}}>
                                        {t("landing.sixth.garanties")}
                                    </h3>
                                </FlexColumn>
                                <FlexColumn className={'text'}>
                                    <h3 style={{margin: 0}}>
                                        {t("landing.sixth.garText")}
                                    </h3>
                                </FlexColumn>
                            </FlexRowBetween>

                            <LeftFigureContainer>
                                {LeftFigure}
                            </LeftFigureContainer>

                            <img
                                style={{width: '100%'}}
                                src={'/landing_media/document.png'}/>

                        </DocumentWrapper>
                        <RightFigureContainer>
                            {RightFigure}
                        </RightFigureContainer>
                    </Inner>

                    <VerticalHr position={"right"}/>
                </DocumentContent>

            </BordersContent>

        </DocumentSectionPage>
    );
};
