import {FlexColumnStart} from "../../../style/projectComponents";
import {First} from "./First";
import {Second} from "./Second";
import {Content, CustomStyledLink, DocWrapper, StyledHr, Title, Wrapper} from "../components";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";


export const PrivacyPage = () => {

    const {t, i18n} = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Wrapper>
            <Content>
                <CustomStyledLink to={'/'}>
                    <img src={'/images/arrBack.svg'}/> {t("documents.back")}
                </CustomStyledLink>

                <FlexColumnStart className={'docs'}>
                    <Title>
                        {t("documents.privacy.title")}
                    </Title>

                    <DocWrapper>
                        <First/>
                        <StyledHr/>
                        <Second/>
                    </DocWrapper>

                </FlexColumnStart>

            </Content>

        </Wrapper>
    );
};