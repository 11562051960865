export const colors = {
    primary: "#00AAC3",
    primaryActive: "#008080",
    lightGrayBg: "#DEDDD8",
    lightGray: "#DEDDD8",
    mediumGray: "#e8e8e8",
    pageBg: "#f6f7f8",
    gray: "#AAB0B8",
    blueGray: "#E2E8F0",
    white: '#FFFFFF',
    secondary: "#282E36",
    buttonHover: "rgba(40,46,54,0.5)",
    greenSuccess: "#50c878",
    redFail: "#DB6F68",
    redInput: "rgba(224,31,25,.16)"
}