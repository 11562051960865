import {FlexColumn, FlexColumnStart, FlexRow} from "../../../../style/projectComponents";
import styled from "styled-components";
import React from "react";
import {useTranslation} from "react-i18next";

const Wrapper = styled(FlexRow)`
  position: relative;
  width: 100%;
  border: 0.0625rem solid #DEDDD8;
  border-top-style: none;
  margin: 0;
  padding: 0;
`

const Content = styled(FlexColumn)`
  border-left: 0.0625rem solid #DEDDD8;
  border-right: 0.0625rem solid #DEDDD8;
  width: 100%;
  height: 100%;
  //background-color: white;
  padding-top: 9rem;
  max-width: 120rem;
  position: relative;
  box-sizing: border-box;
`

const Inner = styled(FlexRow)`
  width: 100%;
  height: 100%;
  align-items: start;
  position: relative;
  box-sizing: content-box;
  max-width: 81.25rem;
  justify-content: start;
  //background-color: white;
`

const TextBox = styled(FlexColumnStart)`
  padding: 7.5rem 7.5rem 7.5rem calc(2.5rem - 4px);
  height: 100%;
  width: 31.75rem;
  //background-color: white;
  text-align: left;
  border-left: 0.0625rem solid #DEDDD8;
  border-right: 0.0625rem solid #DEDDD8;
  box-sizing: border-box;

  h3 {
    font-size: 2.125rem;
    font-weight: 300;
    width: auto;
    margin: 0;
    padding-bottom: 20px;
    
    @media (max-width: 480px) {
      max-width: 300px;
    }
  }

  p {
    font-size: 1.125rem;
    font-weight: 300;

    @media (max-width: 480px) {
      max-width: 300px;
    }
  }

  @media (max-width: 480px) {
    padding: 3rem;
  }
`

const RightFigureContainer = styled.div<{ lng?: string }>`
  height: 100%;
  left: ${props => props.lng.toLowerCase() === 'ar' ? '' : '100%'};
  right: ${props => props.lng.toLowerCase() === 'ar' ? '100%' : ''};
  top: 0;
  max-height: 20.16125rem;
  width: 68.875rem;
  border-bottom: 0.0625rem solid #DEDDD8;
  padding-left: 4.0625rem;
  z-index: 5 !important;
  box-sizing: border-box;
  position: absolute
`

const LeftFigureContainer = styled.div<{ lng?: string }>`
  max-height: 20.16125rem;
  width: 19.375rem;
  border-bottom: 0.0625rem solid #DEDDD8;
  height: 7.9375rem;
  box-sizing: border-box;
  position: absolute;
  left: ${props => props.lng.toLowerCase() === 'ar' ? '' : '-19.3125rem'};
  right: ${props => props.lng.toLowerCase() === 'ar' ? '-19.3125rem' : ''};
  bottom: -0.0625rem;
`
const TextAndFigsWrapper = styled.div`
  position: relative;
`

const LeftFigure = (
    <svg
        className="bottom-investments-block__img bottom-investments-block__img-pc bottom-investments-block__img-pc-first"
        width="311" height="128" viewBox="0 0 311 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M310 0.999973L0 1" stroke="#DEDDD8"></path>
        <path
            d="M310 64C310 98.5178 281.518 127 247 127C212.487 127 184.5 98.5229 184.5 64C184.5 29.4771 212.487 1 247 1C281.518 1 310 29.4822 310 64Z"
            stroke="#DEDDD8"></path>
    </svg>
);

const RightFigure = (
    <svg
        className=" bottom-investments-block__img bottom-investments-block__img-pc bottom-investments-block__img-pc-second"
        width="1038" height="324" viewBox="0 0 1038 324" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M165 161H163C73.5299 161 1 233.53 1 323V323" stroke="#DEDDD8"></path>
        <path d="M165 161L1038 161" stroke="#DEDDD8"></path>
        <path d="M165 323L165 0.999969" stroke="#00AAC3"></path>
        <path d="M360 0.999993C271.634 0.999997 200 72.4233 200 161.065C200 249.707 272.134 323 360.5 323"
              stroke="#00AAC3"></path>
        <path d="M361 0.999993C449.366 0.999997 521 72.4233 521 161.065C521 249.707 448.866 323 360.5 323"
              stroke="#DEDDD8"></path>
        <path
            d="M1037.5 111C1037.5 138.345 1015.33 161 988 161C960.668 161 938.5 138.345 938.5 111C938.5 83.6619 960.662 61.5 988 61.5C1015.34 61.5 1037.5 83.6619 1037.5 111Z"
            stroke="#DEDDD8"></path>
        <path d="M938 162V323" stroke="#DEDDD8"></path>
        <path d="M728 1V162" stroke="#DEDDD8"></path>
        <path d="M728 161V161C728 250.47 800.53 323 890 323H938" stroke="#DEDDD8"></path>
    </svg>
);


const StyledHr = styled.hr`
  width: 100%;
  border: none;
  height: 0.0625rem;
  background: #DEDDD8;
  margin: 0;
`


export const Fourth = () => {

    const {t, i18n} = useTranslation();

    return (
        <Wrapper>
            <Content>
                <StyledHr/>
                <Inner>
                    <TextAndFigsWrapper>
                        <LeftFigureContainer lng={i18n.language}>
                            {LeftFigure}
                        </LeftFigureContainer>

                        <TextBox>
                            <h3>
                                {t("landing.third.4.title")}
                            </h3>
                            <p>
                                {t("landing.third.4.desc")}
                            </p>
                        </TextBox>

                        <RightFigureContainer lng={i18n.language}>
                            {RightFigure}
                        </RightFigureContainer>
                    </TextAndFigsWrapper>
                </Inner>
            </Content>
        </Wrapper>
    );
};
