import {ComponentPropsWithoutRef, FC, useContext, useEffect, useState} from "react";
import {FlexColumn, FlexRowBetween} from "../../style/projectComponents";
import styled from "styled-components";
import {colors} from "../../style/colors";
import LanguageStorage from "../../storage/LanguageStorage/LanguageStorage";
import {Close} from "../../assets/Close";

type DatePickerProps = {
    date?: string,
    setDate?: any,
    open?: boolean,
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const Wrapper = styled(FlexColumn)`
  border-radius: 20px;
  background-color: white;
  width: 300px;
  border: 2px solid ${colors.pageBg};
  min-height: 280px;
  z-index: 100;
  justify-content: flex-start;
  padding: 20px;
  gap: 20px;
  box-sizing: border-box;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;

  span {
    font-size: 20px;
    font-weight: 300;
  }
`;

const StyledSelect = styled.select`
  padding: 10px;
  appearance: none;
  width: auto;
  outline: none;
  border-radius: 8px;
  border: 1px solid ${colors.primary};
  text-align: center;
  color: black;


  option {
    padding: 5px;
    background-color: ${colors.pageBg};
  }
`;

const Content = styled(FlexColumn)`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
`;

const CloseWrapper = styled(FlexColumn)`
  height: fit-content;
  width: 100%;
  align-items: end;

  svg {
    cursor: pointer;
  }
`;
export const DatePicker: FC<DatePickerProps & ComponentPropsWithoutRef<'div'>> = (props) => {
    const {
        date,
        setDate,
        open,
        setOpen,
        ...other
    } = props;

    const months = [
        {
            dateRu: 'Январь',
            dateEng: 'January',
        },
        {
            dateRu: 'Февраль',
            dateEng: 'February',
        },
        {
            dateRu: 'Март',
            dateEng: 'March',
        },
        {
            dateRu: 'Апрель',
            dateEng: 'April',
        },
        {
            dateRu: 'Май',
            dateEng: 'May',
        },
        {
            dateRu: 'Июнь',
            dateEng: 'June',
        },
        {
            dateRu: 'Июль',
            dateEng: 'July',
        },
        {
            dateRu: 'Август',
            dateEng: 'August',
        },
        {
            dateRu: 'Сентябрь',
            dateEng: 'September',
        },
        {
            dateRu: 'Октябрь',
            dateEng: 'October',
        },
        {
            dateRu: 'Ноябрь',
            dateEng: 'November',
        },
        {
            dateRu: 'Декабрь',
            dateEng: 'December',
        },
    ];


    const years = Array
        .from(
            Array(new Date().getFullYear()),
            (_, i) => i + 1)
        .filter(it => it > 1960 && it <= 2005);

    const getDays = (month: number) => {
        let count = 30;
        const days31 = [1, 3, 5, 7, 8, 10, 12];
        if (days31.includes(month)) {
            count = 31;
        }
        if (month === 2) {
            count = 28;
        }
        const days = Array
            .from(
                Array(count),
                (_, i) => i + 1);

        return days;
    };

    const refactorDate = (data: any) => {
        const res = data?.toString().split('-').map(it => Number(it));
        return {day: res[0], month: res[1], year: res[2]};
    };


    const [activeMonth, setActiveMonth] = useState(refactorDate(date).month || 1);
    const [activeYear, setActiveYear] = useState(refactorDate(date).year || 1990);
    const [activeDay, setActiveDay] = useState(refactorDate(date).day || 1);

    const LangaugeStore = useContext(LanguageStorage);

    const refactorNull = (date: number) => {
        const res = date.toString().length === 2 ? date : `0${date}`;
        return res;
    };


    useEffect(() => {
        setDate('birthDate', `${refactorNull(activeDay)}-${refactorNull(activeMonth)}-${activeYear}`);
    }, [activeDay, activeYear, activeMonth]);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "scroll";
        };
    }, []);

    const [dayClicked, setDayClicked] = useState(false);
    const handleClickDay = (day: number) => {
        setActiveDay(() => day);
        setDayClicked(() => true);
    }

    useEffect(() => {
        if (dayClicked) {
            setOpen(() => false);
            setDayClicked(() => false)
        }
    }, [dayClicked]);

    return (
        <Content
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    setOpen(false);
                }
            }}>
            <Wrapper {...other}>
                <CloseWrapper
                    onClick={(e) => {
                        setOpen(false);
                    }}>
                    {Close(colors.primary)}
                </CloseWrapper>
                <FlexRowBetween>
                    <StyledSelect
                        onChange={(e) => setActiveYear(Number(e.target.value))}
                        defaultValue={activeYear}
                        name="year">
                        {years.map((it, idx) =>
                            <option value={it}>{it}</option>
                        )}
                    </StyledSelect>

                    <StyledSelect
                        defaultValue={activeMonth}
                        onChange={(e) => setActiveMonth(Number(e.target.value))} name="month">
                        {months.map((it, idx) =>
                            <option value={idx + 1}>{LangaugeStore.language === 'ru' ? it.dateRu : it.dateEng}</option>
                        )}
                    </StyledSelect>
                </FlexRowBetween>
                <StyledGrid>
                    {getDays(activeMonth).map((it, idx) =>
                            <span
                                onClick={() => handleClickDay(it)}
                                style={{
                                    backgroundColor: it === activeDay ? colors.primary : "white",
                                    color: it === activeDay ? "white" : "black",
                                    padding: 2,
                                    borderRadius: 5,
                                    cursor: 'pointer',
                                    width: "auto"
                                }}>
                        {it}
                    </span>
                    )}
                </StyledGrid>
            </Wrapper>
        </Content>

    );
};
