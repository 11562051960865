import React, {useState} from "react";
import styled from "styled-components";
import {FlexColumn, FlexColumnStart, FlexRow} from "../../style/projectComponents";
import {Area, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {colors} from "../../style/colors";
import {StockUp} from "../../assets/StockUp";
import {StockDown} from "../../assets/StockDown";
import {IDashboardOperationTypeRsp} from "../../service/user-api/models";
import {useTranslation} from "react-i18next";
import {FilterMethodButton} from "./FilterMethodButton";
import {isAfter, parse, subMonths, subYears} from "date-fns";


const FilterWrapper = styled(FlexRow)`
  justify-content: end;
  width: 100%;
  margin-bottom: 24px;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;
const FilterMethods = styled(FlexRow)`
  @media (max-width: 780px) {
    width: 100%;
  }
`;

const ChartWrapper = styled(FlexColumn)`
  width: calc(100% - 32px);
  height: 500px;
  
  .rsp_container {
    direction: ltr;
  }
  
  line {
    stroke: ${colors.lightGray};
  }
`;

interface ChartComponentProps {
    variant?: 'gradient' | 'linear';
    data: any;
    xAxisKey?: string;
    yAxisKey?: string;
    titleKey?: string | string[];
    valueKey?: string | string[];
}

const CustomizedDot = (props: any) => {
    const {cx, cy, payload} = props;
    const hideDot = !!payload.registrationsCount || !!payload.balanceDiffPercent;
    console.log(payload);
    return (
        <g>
            {!hideDot && <g transform={`translate(${cx - 10},0)`}>
                <g x={0} y={0} dy={4}>
                    {(payload.operationType === IDashboardOperationTypeRsp.withdrawalRequest
                        || payload['depositsAmount'] < 0
                        || payload['amountDiff'] < 0)
                        ? StockDown
                        : StockUp
                    }
                </g>
            </g>}
        </g>
    );
};
const CustomTooltip = (props) => {

    const {active, payload, label, titleKey, valueKey} = props;
    const {t, i18n} = useTranslation();

    const numKeys = ['balance', 'deposit'];
    const handleKeys = () => {
        if (typeof titleKey === "string") {
            const res = payload[0].payload[titleKey]
                ? t(`common.${payload[0].payload[titleKey]}`)
                : titleKey;
            return [res];
        } else {
            const res = titleKey.filter(it => it != 'operationType').map((it, idx) => {
                const r = payload[0].payload[it]
                    ? t(`common.${payload[0].payload[it]}`)
                    : it;
                return r;
            });
            return res;
        }
    };

    const handleValues = () => {
        if (typeof valueKey === "string") {
            const res = numKeys.find(it => valueKey.toLowerCase().includes(it))
                ? `$${Number(payload[0].payload[valueKey]).toFixed(2)}`
                : payload[0].payload[valueKey];
            return [res];
        } else {
            const res = valueKey.map((it, idx) => {
                let rs;
                if (it === 'balanceDiffPercent') {
                    rs = Number(payload[0].payload[it]).toFixed(2);
                    if (payload[0].payload.operationType === IDashboardOperationTypeRsp.withdrawalRequest) {
                        return `-${rs} %`;
                    } else {
                        return `${rs} %`;
                    }
                }
                if (numKeys.find(it_ => it.toLowerCase().includes(it_.toLowerCase()))) {
                    rs = Number(payload[0].payload[it]).toFixed(2);
                    if (payload[0].payload.operationType === IDashboardOperationTypeRsp.withdrawalRequest
                        && !it.toLowerCase().includes('balance')
                        && rs > 0
                    ) {
                        return `-$${rs}`;
                    } else {
                        return `$${rs}`;
                    }
                }
                rs = payload[0].payload[it];
                return rs;
            });
            return res;
        }

    };

    if (active && payload && payload.length) {
        return (
            <FlexColumnStart
                style={{
                    backgroundColor: 'white',
                    padding: '0.75rem 1rem',
                    borderRadius: '0.1875rem',
                    border: '1px solid #E2E8F0',
                }}>
                <p style={{fontSize: '0.875rem'}} className="label">
                    {`${label} ${titleKey.includes("operationType")
                        ? t(`common.${payload[0].payload["operationType"]}`)
                        : ""
                    }`}
                </p>
                <p style={{
                    fontSize: '0.875rem',
                    color: '#6893DB',
                    paddingTop: '0.25rem'
                }}>
                    {handleKeys().map((it, idx) =>
                        <FlexColumnStart>
                            {`${it}: ${handleValues().at(idx)}`}
                            <br/>
                        </FlexColumnStart>
                    )}
                </p>
            </FlexColumnStart>
        );
    }

    return null;
};

export const ChartComponent: React.FC<ChartComponentProps> = (props) => {

    const {
        data,
        titleKey,
        valueKey,
        variant = 'gradient',
        xAxisKey = "operationDate",
        yAxisKey = 'balanceDiff'
    } = props;

    const formatYAxis = (value) => {
        return Math.round(value);
    };

    const {t, i18n} = useTranslation();
    const chartPeriods = [
        t("dashboard.chart.month1"),
        t("dashboard.chart.month3"),
        t("dashboard.chart.year1"),
        t("dashboard.chart.all")
    ];
    const [chartPeriodId, setChartPeriodId] = useState(3);

    const formatString = 'dd.MM.yy';

    const parsedDate = (date: string) => parse(date, formatString, new Date());
    const subtractedDate =
        chartPeriodId === 0 && subMonths(new Date(), 1)
        || chartPeriodId === 1 && subMonths(new Date(), 3)
        || chartPeriodId === 2 && subYears(new Date(), 1);

    const filteredData = chartPeriodId < 3 ? data.filter(dataItem => isAfter(parsedDate(dataItem.date), subtractedDate)) : data;

    return (
        <ChartWrapper>
            <FilterWrapper>
                <FilterMethods>
                    {chartPeriods.map((item, id) =>
                        <FilterMethodButton
                            title={item}
                            isActive={id === chartPeriodId}
                            setActiveTypeId={setChartPeriodId}
                            id={id}
                        />
                    )}
                </FilterMethods>
            </FilterWrapper>
            <ResponsiveContainer className={'rsp_container'} width="100%" height={250} key={`rc_${filteredData.length}`}>
                <ComposedChart data={filteredData} key={`cc_${filteredData.length}`}>
                    {variant === 'gradient' && <defs>
                        <linearGradient id="colorUv" x1="1" y1="1" x2="0" y2="0">
                            <stop offset="35%" stopColor="#88c6fb"/>
                            <stop offset="65%" stopColor="#6893db"/>
                        </linearGradient>
                    </defs>}
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis
                        dataKey={xAxisKey}

                        style={{fontSize: 14}}/>
                    <YAxis
                        type="number"
                        // @ts-ignore
                        tickFormatter={formatYAxis}
                        domain={[
                            dataMin => valueKey === "registrationsCount" ? 0 : ((dataMin <= 0) ? (dataMin * 1.1) : (dataMin * 0.9)).toFixed(0),
                            dataMax => ((dataMax * 1.1).toFixed(0))
                        ]}
                        dataKey={yAxisKey}
                        style={{fontSize: 14}}/>
                    <Tooltip
                        content={<CustomTooltip titleKey={titleKey} valueKey={valueKey}/>}
                        contentStyle={{fontSize: 14, textAlign: "left"}}/>
                    {variant === 'gradient' &&
                        <Area
                            key={`ac_${filteredData.length}`}
                            type={"monotone"}
                            dataKey={yAxisKey}
                            stroke={"#6893db"}
                            strokeWidth={1}
                            fill={"url(#colorUv)"}
                            activeDot={<CustomizedDot/>}
                        />}
                    {variant === 'linear' &&
                        <Line
                            key={`lc_${filteredData.length}`}
                            activeDot={<CustomizedDot/>}
                            dot={<></>}
                            type={"monotone"}
                            dataKey={yAxisKey}
                            stroke={"#6893DB"}
                            strokeWidth={'3px'}/>
                    }

                </ComposedChart>
            </ResponsiveContainer>
        </ChartWrapper>
    );
};
