import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {AuthContent, AuthModal, AuthTitle, FlexRow, Page} from "../style/projectComponents";
import HeaderComponent from "../components/global/HeaderComponent";
import styled from "styled-components";
import {isPasswordGood} from "../components/auth/Register/SecUtils";
import RecoveryContent from "../components/auth/RecoveryUpdate/RecoveryContent";


type IFormValues = {
    resetToken: string,
    newPassword: string,
    badReset: any
}

type IInputs = {
    name: 'resetToken' | 'newPassword',
    label: string,
    type: string,
    rules: any
}

const baseInputs: IInputs[] = [
    {
        name: 'resetToken',
        label: 'Код сброса',
        type: 'text',
        rules: {required: true}
    },
    {
        name: 'newPassword',
        label: 'Новый пароль',
        type: 'password',
        rules: isPasswordGood
    }
]

const StyledPage = styled(Page)`
  background-position: center;
  background-size: cover;
  justify-content: start;

  .header {
    position: absolute;
  }
`

export const ResetPassword = () => {

    const {t, i18n} = useTranslation();

    return (
        <StyledPage style={{background: "url(/images/bg.svg)"}}>
            <HeaderComponent/>
            <AuthContent>
                <AuthModal>
                    <FlexRow style={{
                        marginBottom: 20,
                        position: 'relative',
                        alignItems: 'center',
                        width: '100%',
                        height: 'fit-content'
                    }}>
                        <AuthTitle>
                            {t('forgotPassword.title')}
                        </AuthTitle>
                    </FlexRow>
                    <RecoveryContent/>
                </AuthModal>
            </AuthContent>
        </StyledPage>
    );
};