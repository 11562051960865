export const withdrawTypes = [
    {
        icon: "/images/coins/usdt.png",
        title: "USDT ERC-20",
        address: "0xcac434178054541ac9f13a8d2d081fcb96caffc5"
    },
    {
        icon: "/images/coins/usdt.png",
        title: "USDT TRC-20",
        address: "TWkxruktKRAGinagLVZuNLcWQuAyMcUSgM"

    },
    {
        icon: "/images/coins/usdt.png",
        title: "USDT BEP-20",
        address: "0x465669af49535567e6bc716be5959cba2ac8eef1"
    },
]