import styled from "styled-components";

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
  max-width: 120rem;
  width: 100%;
  //padding-left: 1.25rem;
  //padding-right: 1.25rem;
  box-sizing: border-box;
`;
