import React from "react";
import styled from "styled-components";
import {colors} from "../../style/colors";


interface BlockProps {
    isActive: boolean;
}
const Block = styled.button<BlockProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px 14px;
  border-radius: 8px;
  border: 1px solid ${colors.blueGray};
  margin: 6px 10px;
  cursor: pointer;
  background: ${({isActive}) => isActive ? colors.blueGray : "none"};
  width: 180px;
  
  @media(max-width: 768px) {
    width: calc(100vw - 32px);
  }

  img {
    width: 30px;
    height: 30px;
  }

  p {
    font-size: 14px;
    text-align: center;
    font-weight: 300;
    margin: 0;
    text-transform: uppercase;
    width: 100%;
  }
`;

interface DepositMethodButtonProps {
    icon: string;
    title: string;
    isActive: boolean;
    setActiveTypeId: any;
    id: number;
}

export const DepositMethodButton: React.FC<DepositMethodButtonProps> = ({
                                                                            icon,
                                                                            title,
                                                                            isActive,
                                                                            setActiveTypeId,
                                                                            id
                                                                        }) => {

    return (
        <Block isActive={isActive} onClick={() => setActiveTypeId(id)}>
            <img src={icon} alt=""/>
            <p>{title}</p>
        </Block>
    );
};
