import {StyledSection} from "../../../global/StyledSection";
import styled from "styled-components";
import {FlexColumn, FlexColumnStart, FlexRow} from "../../../../style/projectComponents";
import {colors} from "../../../../style/colors";
import {ItemCompany} from "./ItemCompany";
import {useTranslation} from "react-i18next";

const LeaderSectionPage = styled(StyledSection)`
  max-width: 100vw;
  align-items: center;
  width: 100vw;
  padding-left: 0;
  padding-right: 0;
  border: 0.0625rem solid #DEDDD8;
  border-left-style: none;
  border-right-style: none;
  border-bottom: none;
`

const LeadersContent = styled(FlexColumn)`
  width: 100%;
  padding-top: calc(7.8125rem * 2);
  padding-bottom: calc(7.8125rem * 2);
  max-width: 120rem;
  align-items: center;
  position: relative;
  border: 0.0625rem solid ${colors.lightGrayBg};
  border-top-style: none;
  border-bottom: none;
  box-sizing: border-box;
`

const StyledHr = styled.hr`
  width: 100%;
  height: 0.0625rem;
  background-color: #DEDDD8;
  margin: 0;
  border: none;
`

const CardsWrapperRow = styled(FlexRow)`
  width: 100%;
  gap: 1.25rem;
  margin: 0 -0.625rem;

  .right {
    margin-top: -10.625rem;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    margin: 0;

    .right {
      margin-top: 0;
    }
  }
`

const CardsWrapperColumn = styled(FlexColumn)`
  row-gap: 1.25rem;
  flex: 0 1 50%;
`

const Inner = styled(FlexColumnStart)`
  max-width: 81.25rem;
  padding-left: 20px;

  .title {
    font-size: 3.125rem;
    margin-bottom: 5rem;
    max-width: 33.125rem;
    font-weight: 300;
    text-align: left;
  }

  @media (max-width: 480px) {
    .title {
      font-size: 2.125rem;
    }
  }
  @media (max-width: 900px) {
    padding: 1.25rem;
  }
`

const items = [
    {
        img: '/landing_media/1.png',
        title: 'landing.fifth.items.1.title',
        desc: `landing.fifth.items.1.desc`,
        profit: 'landing.fifth.items.1.profit',
        minimum: '$10000',
        manager: 'James Simons',
        link: 'https://www.rentec.com/Home.action?index=true'
    },
    {
        img: '/landing_media/3.png',
        title: 'landing.fifth.items.2.title',
        desc: `landing.fifth.items.2.desc`,
        profit: 'landing.fifth.items.2.profit',
        minimum: '$1000',
        manager: 'Larry Fink',
        link: 'https://www.blackrock.com/'

    },
    {
        img: '/landing_media/2.png',
        title: 'landing.fifth.items.3.title',
        desc: `landing.fifth.items.3.desc`,
        profit: 'landing.fifth.items.3.profit',
        minimum: '$10M',
        manager: 'Kenneth Griffin',
        link: 'https://www.citadel.com/'

    },
    {
        img: '/landing_media/4.png',
        title: 'landing.fifth.items.4.title',
        desc: `landing.fifth.items.4.desc`,
        profit: 'landing.fifth.items.4.profit',
        minimum: 'landing.fifth.no',
        manager: 'John Overdeck',
        link: 'https://www.twosigma.com/'
    },
]

export const LeadersSection = () => {
    const {i18n, t} = useTranslation();
    return (
        <LeaderSectionPage id={'leaders'}>
            <LeadersContent>
                <Inner>
                    <h3 className={'title'}>
                        {t("landing.fifth.title")}
                    </h3>
                    <CardsWrapperRow>
                        <CardsWrapperColumn>
                            {items.filter((it, idx) => idx < 2).map((it, idx) =>
                                <ItemCompany {...it}/>
                            )}
                        </CardsWrapperColumn>

                        <CardsWrapperColumn className={'right'}>
                            {items.filter((it, idx) => idx > 1).map((it, idx) =>
                                <ItemCompany {...it}/>
                            )}
                        </CardsWrapperColumn>
                    </CardsWrapperRow>
                </Inner>

            </LeadersContent>
        </LeaderSectionPage>
    );
};
