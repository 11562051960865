import React, {useContext} from 'react';
import {BrowserRouter, Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {AppStyle} from './style/projectComponents';
import {observer} from "mobx-react-lite";
import UserStorage from "./storage/UserStorage/UserStorage";
import AppProvider from "./AppProvider";
import {adminRoutes, privateRoutes, publicRoutes} from "./utils/routes";
import {Footer} from "./components/Home/Footer/Footer";
import LanguageStorage from "./storage/LanguageStorage/LanguageStorage";


function App() {

    const UserStore = useContext(UserStorage);
    const isAdmin = UserStore.isAdmin();
    const LanguageStore = useContext(LanguageStorage);
    const isArLanguage = LanguageStore.language === "ar"
    const isAuth = !!UserStore.accessToken;

    return (
        <AppProvider>
            <AppStyle
                isArLanguage={isArLanguage}
                isAuth={isAuth}
            >
                <BrowserRouter>
                    {!!UserStore.accessToken
                        ? <Routes>
                            {privateRoutes.map((it, idx) =>
                                <Route path={it.path} Component={it.component}/>
                            )}
                            <Route path="/*" element={<Navigate to={'/dashboard'} replace/>}/>

                            {isAdmin && <>
                                {adminRoutes.map((it, idx) =>
                                    <Route path={it.path} Component={it.component}/>
                                )}
                                <Route path="/admin/*" element={<Navigate to={'/admin/analytics'} replace/>}/>
                            </>}
                        </Routes>
                        : <Routes>
                            {publicRoutes.map((it, idx) =>
                                <Route path={it.path} Component={it.component}/>
                            )}
                            <Route path="/*" element={<Navigate to={'/'} replace/>}/>
                        </Routes>
                    }
                    <Footer/>
                </BrowserRouter>
            </AppStyle>
        </AppProvider>

    );
}

export default observer(App);

