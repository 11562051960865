import {useTranslation} from "react-i18next";
import {CircleTitle, DocContent, RectTitle} from "../components";
import {FlexColumnStart} from "../../../style/projectComponents";

export const Second = () => {
    const {t, i18n} = useTranslation();

    return (
        <DocContent>
            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.1")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.2")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.3")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.4")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.5")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.6")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.7")}

                    <CircleTitle text={t("documents.terms.2.8")}/>
                    <CircleTitle text={t("documents.terms.2.9")}/>
                    <CircleTitle text={t("documents.terms.2.10")}/>
                    <CircleTitle text={t("documents.terms.2.11")}/>
                    <CircleTitle text={t("documents.terms.2.12")}/>
                </p>
            </FlexColumnStart>

            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.13")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.14")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.15")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.16")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.17")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.18")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.19")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.20")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.21")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.22")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.23")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.24")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.25")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.26")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.27")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.28")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.29")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.30")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.31")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.32")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.33")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.34")}
                </p>
            </FlexColumnStart>


            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.2.35")}
                </p>
            </FlexColumnStart>

        </DocContent>
    );
};
