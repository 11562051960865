import {ErrorText, FlexColumnStart, FlexRow, StyledButton} from "../../style/projectComponents";
import styled from "styled-components";
import {ValidationInputComponent} from "../../components/global/ValidationInputComponent";
import React, {FC, useContext, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useMutation} from "react-query";
import AdminApi from "../../service/admin-api/AdminApi";
import {IAccrualOfInterestReq} from "../../service/admin-api/models";
import {useTranslation} from "react-i18next";
import GlobalModalStore from "../../storage/GlobalModalStore/GlobalModalStore";
import {IGetWithdrawalRequest, IWithdrawalStatus} from "../../service/withdrawal-api/models";
import WithdrawalApi from "../../service/withdrawal-api/WithdrawalApi";

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 380px;

  @media (max-width: 480px) {
    width: 320px;
    label {
      left: 20px;
    }
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
`

const Content = styled(FlexColumnStart)`
  background-color: white;
  border-radius: var(--8, 0.5rem);
  padding: 2.37rem 3.5rem;
  position: relative;

  @media (max-width: 480px) {
    padding: 2.5rem 1rem;
    width: 100%;
    align-items: center;
    border-radius: 0.75rem;
  }
`

const InnerWrapper = styled(FlexColumnStart)`
  row-gap: 1rem;

  h3 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 400;
  }   
  h2 {
    font-size: 20px;
    width: 100%;
  }
`

const CloseButton = styled(StyledButton)`
  align-self: end;
  position: absolute;
  right: 1.6rem;
  top: 1.31rem;
`

export const AdminPercent: FC<{ setIsOpen?: any }> = (props) => {
    const {setIsOpen} = props;

    const {control, clearErrors, formState: {errors}, handleSubmit} = useForm();

    const GlobalModalStorage = useContext(GlobalModalStore);

    const addPercent = useMutation((data: IAccrualOfInterestReq) => AdminApi.accrualOfInterest(data), {
        onSuccess: data => {
            setIsOpen(false);
            GlobalModalStorage.makeVisible(true, t("common.success"));
            console.log(data);
        },
        onError: error => {
            GlobalModalStorage.makeVisible(false, t("common.error"));
            console.log(error);
        }
    });

    const onSubmit = (data: any) => {
        const newData: IAccrualOfInterestReq = {
            commissionPercents: 0,
            setEfficientBalance: true,
            doNotSyncBalances: true,
            comment: "Interest accrual",
            currency: "USDT",
            percents: parseFloat(parseFloat(data.percents).toFixed(6))
        };
        addPercent.mutate(newData);
    }

    const {t} = useTranslation();

    const ITEMS_PER_PAGE = 10;
    const notApprovedStatuses = [IWithdrawalStatus.notApproved, IWithdrawalStatus.undefined]
    const getWithdrawalQuery = useMutation((data: IGetWithdrawalRequest) => WithdrawalApi.getWithdrawal(data));
    const [anyWithdrawActive, SetAnyWithdrawActive] = useState(false)
    useEffect(() => {
        const searchParams: IGetWithdrawalRequest = {
            statuses: notApprovedStatuses,
            skip: 0,
            take: ITEMS_PER_PAGE
        }
        getWithdrawalQuery.mutate(searchParams, {
            onSuccess: data => {
                SetAnyWithdrawActive(data.items.length >= 1)
            }
        });
    }, []);

    return (
        <StyledForm
            onSubmit={handleSubmit(onSubmit)}
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    setIsOpen(false);
                }
            }}>
            <Content>
                <CloseButton
                    onClick={() => setIsOpen(false)}
                    variant={'text'}>
                    <img src={'/images/ic_cancel.svg'}/>
                </CloseButton>

                <InnerWrapper>
                    <h3>
                        {t('adminPercent.accrualInterest')}
                    </h3>
                    {anyWithdrawActive
                        ? <h2> {t("adminUserDetail.inactivePercent")}</h2>
                        :<>
                        <InputWrapper>
                            <Controller
                                name={"percents"}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Required'
                                    },
                                    pattern: {
                                        value: /^-?\d{1,7}(?:\.\d{1,2})?$/,
                                        message: "adminPercent.errors.max"
                                    },
                                    max: {
                                        value: 1000,
                                        message: "adminPercent.errors.max"
                                    }
                                }}
                                render={({field: {ref, ...field}}) =>
                                    <ValidationInputComponent
                                        {...field}
                                        type={'text'}
                                        maxLength={6}
                                        isError={!!errors['percents'] || addPercent.isError}
                                        label={t('adminPercent.percent')}/>}
                            />

                        </InputWrapper>
                        <ErrorText>
                            {t(errors?.percents?.message.toString())}
                        </ErrorText>
                        <StyledButton
                            onClick={() => clearErrors()}
                            type={'submit'}
                            noRadius
                            style={{width: '100%', padding: '1rem'}}>
                            {addPercent.isLoading
                                ? t('adminPercent.processus')
                                : t('adminPercent.add')
                            }
                        </StyledButton>
                    </>}
                </InnerWrapper>
            </Content>
        </StyledForm>

    );
};
