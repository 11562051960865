import React, {ComponentPropsWithoutRef, useState} from "react";
import {StyledInput} from "../../style/projectComponents";
import styled from "styled-components";
import {colors} from "../../style/colors";
import {useTranslation} from "react-i18next";

type ValidationInputComponentProps = {
    setValueToMax: any;
    value?: string;
    onChange: any;
    isError?: any;
    label: string;
    ref?: any;
}

const Label = styled.label<{ isFocused?: any, lng?: string }>`
  display: ${props => props.isFocused ? "block" : "none"};
  font-size: 14px;
  position: absolute;
  left: ${props => props.lng.toLowerCase() === 'ar' ? '' : '20px'};
  right: ${props => props.lng.toLowerCase() === 'ar' ? '20px' : ''};
  margin-top: 10px;
  transition: all 1s;
  color: rgba(0, 0, 0, 0.8);
  
  @media (max-width: 480px) {
    left: ${props => props.lng.toLowerCase() === 'ar' ? '' : '50px'};
    right: ${props => props.lng.toLowerCase() === 'ar' ? '50px' : ''};
  }
`;

Label.defaultProps = {
    lng: ''
}

const StyledMaxButton = styled.div<{ lng?: string }>`
  position: absolute;
  right: ${props => props.lng.toLowerCase() === 'ar' ? '' : '20px'};
  left: ${props => props.lng.toLowerCase() === 'ar' ? '20px' : ''};
  margin-top: 18px;
  font-size: 16px;
  border: none;
  background: none;
  cursor: pointer;
  color: ${colors.primary};
  user-select: none;

  &:hover {
    color: ${colors.secondary};
  }
`;

const CustomStyledInput = styled(StyledInput)`
  width: 100%;
  position: relative;
  font-family: sans-serif;

  @media (max-width: 480px) {
    max-width: 350px;
  }
`;
export const ValidationInputComponentMax: React.FC<ValidationInputComponentProps & ComponentPropsWithoutRef<'input'>> = (props) => {

    const {value, onChange, isError, label, ref, setValueToMax, ...other} = props;
    const [isFocused, setIsFocused] = useState(false);

    const {i18n} = useTranslation();

    return (
        <>
            <CustomStyledInput
                {...other}
                ref={ref}
                value={value}
                placeholder={isFocused ? "" : label}
                onChange={onChange}
                isFailed={isError}
                onBlur={() => setIsFocused(false)}
                onFocus={() => setIsFocused(true)}
            />
            <StyledMaxButton lng={i18n.language} onClick={setValueToMax}>
                Max
            </StyledMaxButton>
            <Label lng={i18n.language} isFocused={isFocused || value}>{label}</Label>
        </>
    );
};
