import React, {useEffect, useState} from "react";
import {FlexColumn, FlexRow, FlexRowResponsive, Page, WhiteExternalLink} from "../style/projectComponents";
import HeaderComponent from "../components/global/HeaderComponent";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {DepositMethodButton} from "../components/DepositComponents/DepositMethodButton";
import {depositTypes} from "../config/depositTypes";
import {Spacer} from "../components/global/Spacer";
import {colors} from "../style/colors";
import {QRCodeCanvas} from "qrcode.react";
import {useTranslation} from "react-i18next";


const StyledBg = styled(FlexColumn)`
  width: 100vw;
  padding: 24px;
  height: 100%;
  margin-top: 110px;
  padding-bottom: 100px;
`;

const PayMethodTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;
const Address = styled.p`
  font-size: 15px;
  word-break: break-all;
  cursor: pointer;
  vertical-align: center;
  @media (max-width: 768px) {
    height: 40px;
  }
`;
const Description = styled.p`
  font-size: 15px;
  padding: 0 10px;
`;
const DepositBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
  background: ${colors.blueGray};
  margin-top: 30px;
  border-radius: 12px;
  width: 100%;
  max-width: 758px;

  img {
    margin-bottom: 25px;
    margin-top: 25px;
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    max-width: calc(100vw - 32px);
  }
`;

const DepositPage: React.FC = () => {

    const [activeTypeId, setActiveTypeId] = useState(0);
    const [copyClicked, setCopyClicked] = useState(false)
    const copyAddress = () => {
        if (copyClicked === false) {
            setCopyClicked(true)
            navigator.clipboard.writeText(depositTypes[activeTypeId].address)
            setTimeout(() => setCopyClicked(false), 1000)
        }
    }

    const {t} = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Page>
            <HeaderComponent/>
            <StyledBg>
                <PayMethodTitle>{t('deposit.title')}</PayMethodTitle>
                <FlexRowResponsive>
                    {depositTypes.map((method, id) =>
                        <DepositMethodButton
                            icon={method.icon}
                            title={method.title}
                            isActive={id === activeTypeId}
                            setActiveTypeId={setActiveTypeId}
                            id={id}
                        />)}
                </FlexRowResponsive>

                <DepositBlock>
                    <PayMethodTitle
                        style={{fontSize: 20}}>
                        {t("deposit.pay_method", {title: depositTypes[activeTypeId].title})}
                    </PayMethodTitle>
                    <Address
                        onClick={copyAddress}>{!copyClicked ? depositTypes[activeTypeId].address : t("withdraw.copy")}</Address>
                    <QRCodeCanvas
                        value={`${depositTypes[activeTypeId].address}`}
                        size={256}
                        width={200}
                        height={200}
                        bgColor={'white'}
                        includeMargin={true}
                        style={{borderRadius: 20, marginTop: 20, marginBottom: 20}}
                    />
                    <Description>
                        {t('deposit.description.warning1')}
                    </Description>
                    <Spacer />
                    <Description>
                        {t('deposit.description.warning2')}
                    </Description>
                    <Spacer />
                    <Description>
                        {t('deposit.description.1', {title: depositTypes[activeTypeId].title})}
                    </Description>
                    <Description>{t("deposit.description.2")}</Description>
                    <Description>{t("deposit.description.3")}</Description>
                    <Spacer/>
                    <Description>{t("deposit.contacts")}</Description>
                    <Spacer/>
                    <FlexRow style={{gap: 10}}>
                        <WhiteExternalLink href="https://t.me/tanya_1906">
                            <p>Telegram</p>
                            <h1>@tanya_1906</h1>
                        </WhiteExternalLink>
                        <WhiteExternalLink href="tel:+994 50 616 5928">
                            <p>{t('deposit.phone')}</p>
                            <h1>+994 50 616 5928</h1>
                        </WhiteExternalLink>
                    </FlexRow>
                    <Spacer/>
                </DepositBlock>
            </StyledBg>
        </Page>
    );
};

export default observer(DepositPage);