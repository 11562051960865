import {ErrorText, FlexColumn, FlexRow, StyledButton} from "../../../style/projectComponents";
import React, {ComponentPropsWithoutRef, FC, useContext, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import styled from "styled-components";
import {ValidationInputComponent} from "../../global/ValidationInputComponent";
import {IUserDetailsResponse} from "../../../service/user-api/models";
import {useMutation} from "react-query";
import AdminApi from "../../../service/admin-api/AdminApi";
import {
    IAccrualOfInterestReq,
    IDepositMoneyRequest,
    IUpdateBalancePermissionsReq
} from "../../../service/admin-api/models";
import {useTranslation} from "react-i18next";
import GlobalModalStore from "../../../storage/GlobalModalStore/GlobalModalStore";
import {CheckComponent} from "../../auth/Register/CheckComponent";


type BalanceAddForm = {
    user: IUserDetailsResponse
}

type IFormType = {
    name: 'percent' | 'setEfficientBalance' | 'commissionPercents',
    rules: any,
    type: string,
    placeholder: string
}

const forms: IFormType[] = [
    {
        name: 'percent',
        rules: {
            required: true,
            valueAsNumber: true,
            pattern: {
                value: /^-?\d{1,7}(?:\.\d{1,2})?$/,
                message: "adminPercent.errors.max"
            }
        },
        type: 'number',
        placeholder: 'adminUserDetail.commissionRule'
    },
    {
        name: 'commissionPercents',
        rules: {
            required: true,
            valueAsNumber: true,
            pattern: {
                value: /^-?\d{1,7}(?:\.\d{1,3})?$/,
                message: "adminUserDetail.addCommission"
            },
            min: 0,
            max: 100
        },
        type: 'number',
        placeholder: 'adminUserDetail.addCommission'
    },
    {
        name: 'setEfficientBalance',
        rules: {
            required: false,
            type: 'boolean'
        },
        type: 'boolean',
        placeholder: ''
    },
];

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 380px;
  @media (max-width: 480px) {
    width: 100%;
    max-width: 380px;
    min-width: 280px;
    label {
      left: 20px;
    }
  }
`;

const StyledForm = styled.form`
  flex-direction: column;
  display: flex;
  align-items: start;
  background-color: white;
  border-radius: var(--8, 0.5rem);
  padding: 2.25rem var(--4, 3.9375rem) 5.75rem var(--4, 3rem);
  margin-top: 20px;

  h2 {
    font-size: 20px;
    width: 100%;
  }

  @media (max-width: 780px) {
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 20px;

    h3 {
      align-self: start;
    }
  }

  .inps {
    gap: 0.81rem;
    margin-bottom: 0.81rem;

    .checkbox {
      width: 100%;
      justify-content: left;
    }
  }
`;

export const PercentAddForm: FC<BalanceAddForm & ComponentPropsWithoutRef<'form'>> =
    (props) => {

        const {user, ...other} = props;
        const {
            control,
            reset,
            setValue,
            formState: {errors, isDirty},
            watch,
            handleSubmit
        } = useForm();
        const depositMoney = useMutation((data: IDepositMoneyRequest) => AdminApi.depositMoney(data));
        const [uId, setUId] = useState<any>(user.wallets.find(it => it.currency === 'USDT')?.id);

        const getWithdrawalPermissionQuery = useMutation((data: IUpdateBalancePermissionsReq) => AdminApi.getUpdateBalancePermissions(data));
        const [canChangeBalance, setCanChangeBalance] = useState(true);

        useEffect(() => {
            setUId(user.wallets.find(it => it.currency === 'USDT')?.id);
            const searchParams: IUpdateBalancePermissionsReq = {
                walletId: user.wallets.find(it => it.currency === 'USDT')?.id,
                currency: 'USDT'
            };
            getWithdrawalPermissionQuery.mutate(searchParams, {
                onSuccess: data => {
                    setCanChangeBalance(data.canChangeBalance);
                }
            });
        }, [user]);

        const GlobalModalStorage = useContext(GlobalModalStore);

        const addPercent = useMutation((data: IAccrualOfInterestReq) => AdminApi.accrualOfInterest(data), {
            onSuccess: data => {
                GlobalModalStorage.makeVisible(true, t("common.success"));
                reset({
                    "percent": "",
                    "commissionPercents": "",
                    "setEfficientBalance": false,
                });
            },
            onError: error => {
                GlobalModalStorage.makeVisible(false, t("common.error"));
                console.log(error);
            }
        });

        const onSubmit = (data: any) => {
            const newData: IAccrualOfInterestReq = {
                commissionPercents: parseFloat(parseFloat(data.commissionPercents).toFixed(6)),
                setEfficientBalance: data.setEfficientBalance,
                doNotSyncBalances: false,
                walletId: user.wallets[0].id,
                comment: "Interest accrual",
                currency: "USDT",
                percents: parseFloat(parseFloat(data.percent).toFixed(6))

            };
            addPercent.mutate(newData);
        };

        const {t} = useTranslation();


        return (
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <h3 style={{marginBottom: '1rem', fontSize: '1.5rem', fontWeight: 400}}>
                    {t('adminUserDetail.addPercent')}
                </h3>
                {!canChangeBalance
                    ? <h2> {t("adminUserDetail.inactivePercent")}</h2>
                    : <>
                        <FlexColumn className={'inps'}>
                            {forms.map((it, idx) =>
                                it.type === 'boolean'
                                    ? (
                                        <CheckComponent
                                            textXl={true}
                                            text={"adminUserDetail.initialBalance"}
                                            isClicked={watch('setEfficientBalance')}
                                            handleClick={() => setValue('setEfficientBalance', !watch('setEfficientBalance'))}/>
                                    )
                                    : (<InputWrapper>
                                            <Controller
                                                name={it.name}
                                                control={control}
                                                rules={it.rules}
                                                render={({field: {ref, ...field}}) =>
                                                    <ValidationInputComponent
                                                        {...field}
                                                        maxLength={6}
                                                        isError={!!errors[it.name]}
                                                        label={t(it.placeholder)}/>}
                                            />
                                        </InputWrapper>
                                    )
                            )}
                        </FlexColumn>
                        {!!errors["sum"] &&
                        <ErrorText style={{marginBottom: 10}}>
                            {t(errors['sum']?.message.toString())}
                        </ErrorText>
                        }

                        <StyledButton
                            type={'submit'}
                            style={{width: '100%', padding: 'var(--16, 1rem)'}}
                            disabled={!isDirty || depositMoney.isLoading || !uId}
                            noRadius
                        >
                            {(depositMoney.isLoading) ? t('adminPercent.processus') : t('adminPercent.add')}
                        </StyledButton>
                    </>}
            </StyledForm>
        );
    };
