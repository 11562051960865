import {IAuthInterface, IAuthSigninRequest, IAuthSignupRequest, IResetPasswordRequest, ITokens} from "./models";
import {apiClient} from "../../config/serverConstants";

class AuthService implements IAuthInterface {
    async resetPassword(data: IResetPasswordRequest) {
        const response = await apiClient.post('/api/Auth/resetPassword', data);
        return response.data as string;
    }

    async forgotPassword(email: string) {
        const response = await apiClient.post('/api/Auth/forgotPassword', {
            email: email
        });
        return response.data as string;
    }

    async refreshToken(data: ITokens) {
        const response = await apiClient.post('/api/Auth/refreshToken', data);
        return response.data as ITokens;
    }

    async signin(data: IAuthSigninRequest) {
        const response = await apiClient.post('/api/Auth/signIn', data);
        return response.data as ITokens;
    }

    async signup(data: IAuthSignupRequest) {
        const response = await apiClient.post('/api/Auth/signUp', data);
        return response.data as ITokens;
    }

}

export default new AuthService();