import {FlexColumnStart, FlexRow, FlexRowBetween, StyledButton, StyledLink} from "../../../../style/projectComponents";
import {ArrowIcon} from "../../../../assets/ArrowIcon";
import {colors} from "../../../../style/colors";
import styled from "styled-components";
import {FC} from "react";
import {useTranslation} from "react-i18next";

const CardLayout = styled(FlexColumnStart)`
  border: 1px solid var(--gray, #DEDDD8);
  padding: 1.8125rem;

  .cardTop {
    width: 100%;
    align-items: center;
    margin-bottom: 7.5rem;

    img {
      width: auto;
      height: 1.25rem;
    }
  }

  .company {
    text-align: left;

    h3 {
      margin-bottom: 1.25rem;
      font-size: 2.125rem;
      font-weight: 300;
    }

    p {
      font-size: 1.125rem;
      line-height: 140%;
      font-weight: 300;
    }
  }

  @media (max-width: 480px) {
    .cardTop {
      margin-bottom: 6.25rem;
    }
  }
`

const MoreBtn = styled.a`
  font-size: 1.125rem;
  text-decoration: none;
  color: ${colors.primary};
  font-weight: 300;

  :hover {
    opacity: 0.8;
    transition: all 0.3s ease;
  }
  
  span {
    margin-right: 10px;
  }

  @media (max-width: 480px) {
    border: 0.0625rem solid ${colors.primary};
    width: 1.8125rem;
    height: 1.8125rem;
    border-radius: 0;
    position: relative;
    span {
      display: none;
    }
    svg {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(50%) translateY(45%);
    }
  }
`

const BottomInfoWrapper = styled(FlexRowBetween)`
  @media (max-width: 480px) {
    .manager {
      display: none;
    }
  }
`

const BottomInfoContent = styled(FlexColumnStart)`
  span {
    color: #AAB0B8;
    font-size: 1rem;
  }

  p {
    font-size: 1rem;
    margin-top: 0.625rem;
  }
`

export const ItemCompany: FC<{
    img: string,
    title: string,
    desc: string,
    profit: string,
    minimum: string,
    manager: string,
    link: string
}> = (props) => {

    const {img, manager, minimum, desc, profit, title, link} = props;
    const {t, i18n} = useTranslation();

    return (
        <CardLayout>
            <FlexRowBetween className={'cardTop'}>
                <img
                    src={img}/>
                <MoreBtn
                    href={link}>
                    <span>
                        {t("landing.fifth.knowMore")}
                    </span>
                    <>{ArrowIcon(colors.primary)}</>
                </MoreBtn>
            </FlexRowBetween>
            <FlexColumnStart style={{rowGap: '3.125rem'}}>
                <FlexColumnStart
                    className={'company'}>
                    <h3>
                        {t(title)}
                    </h3>
                    <p>
                        {t(desc)}
                    </p>
                </FlexColumnStart>
                <BottomInfoWrapper>
                    <BottomInfoContent className={'profit'}>
                        <span>
                            {t("landing.fifth.profit")}
                        </span>
                        <p>{t(profit)}</p>
                    </BottomInfoContent>
                    <BottomInfoContent className={'minimum'}>
                        <span>
                            {t("landing.fifth.limit")}
                        </span>
                        <p>{t(minimum)}</p>
                    </BottomInfoContent>
                    <BottomInfoContent className={'manager'}>
                        <span>
                            {t("landing.fifth.manager")}
                        </span>
                        <p>{manager}</p>
                    </BottomInfoContent>
                </BottomInfoWrapper>
            </FlexColumnStart>

        </CardLayout>
    );
};
