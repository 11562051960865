import {Controller, useForm} from "react-hook-form";
import {CustomStyledInput, ValidationInputComponent} from "../../global/ValidationInputComponent";
import {ErrorText, FlexColumn, FlexRow, PassIcon, StyledRegisterButton} from "../../../style/projectComponents";
import React, {FC, useContext, useState} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useMutation} from "react-query";
import AuthApi from "../../../service/auth-api/AuthApi";
import {IAuthSignupRequest} from "../../../service/auth-api/models";
import {Link, useNavigate} from "react-router-dom";
import UserStorage from "../../../storage/UserStorage/UserStorage";
import {finalInputs, isPhoneNumberGood} from "./SecUtils";
import jwtDecode from "jwt-decode";
import GlobalModalStore from "../../../storage/GlobalModalStore/GlobalModalStore";
import formatDateCustom from "../../../utils/formatDateCustom";
import {DropdownComponent} from "../../global/DropdownComponent";
import {phoneCodes} from "../../../config/phoneCodes";
import InputMask from 'react-input-mask';
import {CheckComponent} from "./CheckComponent";
import {colors} from "../../../style/colors";
import {INITIAL_STATE_REG, RegisterContext} from "../../../pages/RegisterPage";


export type ISecondStepProps = {}

const InputWrapper = styled(FlexRow)<{ lng?: string }>`
  position: relative;
  align-items: start;
  width: 300px;

  @media (max-width: 480px) {
    label {
      left: ${props => props.lng.toLowerCase() === 'ar' ? '' : '20px'};
      right: ${props => props.lng.toLowerCase() === 'ar' ? '20px' : ''};
    }
  }
`;

type IFormValues = {
    phoneNumber: string,
    password: string,
    email: string,
    retypePassword: string,
    badSignup: any
}

export type IFinalInput = {
    name: "phoneNumber" | "password" | "email" | "retypePassword" | "badSignup",
    label: string,
    type: string,
    rules: any
}

const PhoneWrapper = styled(FlexColumn)`
  width: 250px;
  box-sizing: border-box;
  padding: 0;
  direction: ltr;

  .phone {
    margin-top: 0;
    padding-bottom: 20px;
    padding-top: 20px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border: 1px solid #d3d0cd;
    border-top-style: none;
  }

  .menu {
    margin: 0;
    width: 292px;

    div {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  label {
    top: 55px;
    left: 25px;
  }
`

const Text = styled.p`
  width: 300px;
  font-size: 11px;
  text-align: center;
  margin-top: 20px;

  a {
    text-decoration: none;
    color: ${colors.primary};
  }
`

const StyledAdvise = styled.p`
  margin-top: 10px;
  color: ${colors.gray};
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  width: 300px;
  min-height: 14px;
`

export const SecondStep: FC<ISecondStepProps> = (props) => {
    const navigate = useNavigate();

    const UserStore = useContext(UserStorage);
    const {formUser, setFormUser} = useContext(RegisterContext);

    const {
        control,
        clearErrors,
        setValue,
        resetField,
        setError,
        register,
        watch,
        handleSubmit,
        formState: {errors},
    } = useForm<IFormValues>({
        defaultValues: {
            phoneNumber: formUser.phoneNumber,
            password: formUser.password,
            email: formUser.email,
            retypePassword: ""
        }
    });


    const {t, i18n} = useTranslation();

    const GlobalModalStorage = useContext(GlobalModalStore);

    const signupMutation = useMutation((data: IAuthSignupRequest) => AuthApi.signup(data), {
        onError: (error: any) => {
            setError("badSignup", {
                type: "manual",
                message: "auth.errors.password.serverError"
            });
            if (error?.response?.data?.errorCode === "ERR_AUTHUSER_EXIST") {
                GlobalModalStorage.makeVisible(false, t("auth.errors.password.serverRegErrorUserExist"))
            } else GlobalModalStorage.makeVisible(false, t("auth.errors.password.serverRegError"))
        },
        onSuccess: data => {
            UserStore.setTokens(data)
            UserStore.setUser(jwtDecode(data.accessToken));
            setFormUser(INITIAL_STATE_REG);
            navigate('/dashboard');
        }
    });
    const onSubmit = (data) => {
        setFormUser({
            ...formUser,
            phoneNumber: data.phoneNumber,
            email: data.email,
            password: data.password
        })
        const birthDate = formUser.birthDate
        const sendData = ({
            ...formUser,
            phoneNumber: data.phoneNumber,
            email: data.email,
            password: data.password
        })
        sendData.birthDate = formatDateCustom(birthDate)
        signupMutation.mutate(sendData);
    }
    const phone: IFinalInput = {
        name: 'phoneNumber',
        label: 'auth.phone',
        type: 'phone',
        rules: isPhoneNumberGood
    };

    const [itemId, setItemId] = useState(0);
    const [openMenu, setOpenMenu] = useState(false);

    const MaskPhoneInput = (props) => {
        const idx = phoneCodes.at(itemId).code;
        const handleMask = () => {
            const mask = phoneCodes.at(itemId).mask;
            const code = phoneCodes.at(itemId).code;
            if (Array.isArray(mask)) {
                return code + " " + (mask[0]);
            } else return code + " " + mask;
        }
        return (
            <InputMask
                style={{direction: 'ltr'}}
                mask={handleMask()}
                {...props}
                formatChars={{"#": "[0-9]"}}
                permanents={[0, idx.length]}
                value={props.value}
                onChange={props.onChange}>
                {(inputProps) =>
                    <CustomStyledInput {...inputProps}/>
                }
            </InputMask>
        )
    };

    const [isClicked, setIsClicked] = useState(true)
    const handleClick = () => {
        setIsClicked(!isClicked)
    }

    const [showPass, setShowPass] = useState(false);
    const [showRetypePass, setShowRetypePass] = useState(false);

    const handleCheckType = (name: string, typeDefault: any) => {
        switch (name) {
            case 'password':
                return showPass ? 'text' : 'password';
            case 'retypePassword':
                return showRetypePass ? 'text' : 'password';
            default:
                return typeDefault;
        }
    };

    const [advisePassword, setAdvisePassword] = useState('settings.inputs.password.errors.pattern');

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputWrapper lng={i18n.language} style={{marginBottom: 10}}>
                <Controller
                    rules={phone.rules}
                    name={phone.name}
                    control={control}
                    render={({field: {ref, ...field}}) =>
                        <PhoneWrapper>
                            <DropdownComponent
                                className={'menu'}
                                itemId={itemId}
                                itemList={phoneCodes.map(it => {
                                    const ctr = it[`name_${i18n.language}`];
                                    if (ctr.length > 28) {
                                        return `${ctr.substring(0, 28)}... (${it.code})`
                                    }
                                    return `${ctr} (${it.code})`

                                })}
                                setId={setItemId}
                                openMenu={openMenu}
                                setOpenMenu={(open: boolean) => {
                                    resetField("phoneNumber")
                                    setOpenMenu(open);
                                }}/>
                            <MaskPhoneInput
                                {...field}
                                value={field.value}
                                isFailed={!!errors[phone.name]}
                                className={'phone'}
                                name={phone.name}
                                placeholder={t("adminUserDetail.phoneNumber.placeholder")}
                                isError={!!errors[phone.name]}
                            />
                        </PhoneWrapper>
                    }
                />
            </InputWrapper>
            <ErrorText hasError={!!errors[phone.name]} style={{maxWidth: 300, marginBottom: 10}}>
                {t(errors[phone.name]?.message?.toString())}
            </ErrorText>

            <FlexColumn style={{marginBottom: 15}}>
                {finalInputs.map((it, idx) =>
                    <>
                        <InputWrapper lng={i18n.language} style={{marginBottom: 5, marginTop: idx !== 0 && 10}}>
                            <Controller
                                name={it.name}
                                control={control}
                                rules={it.name === 'retypePassword' ? it.rules(watch) : it.rules}
                                render={({field: {ref, ...field}}) =>
                                    <ValidationInputComponent
                                        {...field}
                                        name={it.name}
                                        type={handleCheckType(it.name, it.type)}
                                        isError={!!errors[it.name]}
                                        label={t(it.label)}/>}
                            />
                            {it.name === 'password' && <PassIcon
                                lng={i18n.language}
                                onClick={() => setShowPass(prev => !prev)}
                                src={showPass ? '/images/show.svg' : '/images/hide.svg'}/>}

                            {it.name === 'retypePassword' && <PassIcon
                                lng={i18n.language}
                                onClick={() => setShowRetypePass(prev => !prev)}
                                src={showRetypePass ? '/images/show.svg' : '/images/hide.svg'}/>}

                        </InputWrapper>

                        {it.name === 'password' && !errors[it.name] &&
                            <StyledAdvise>
                                {t(advisePassword)}
                            </StyledAdvise>
                        }

                        <ErrorText hasError={!!errors[it.name]} style={{maxWidth: 300}}>
                            {t(errors[it.name]?.message?.toString())}
                        </ErrorText>
                    </>
                )}
            </FlexColumn>

            <CheckComponent isClicked={isClicked} handleClick={handleClick}/>
            <StyledRegisterButton onClick={() => clearErrors()} type={'submit'} disabled={!isClicked}>
                {t("auth.reg_title")}
            </StyledRegisterButton>

            <Text>{t('auth.iConfirm')} <span><Link to={'/terms'}
                                                   target="_blank">{t('footer.termsOfUse')}</Link></span>
                , <span><a href="/privacy">{t('footer.privacy')}</a></span> {t('auth.and')} <span><a
                    href="/aml">{t('footer.aml')}</a></span>
            </Text>
        </form>
    );
};
