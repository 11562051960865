import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {FlexRow} from "../../../style/projectComponents";
import styled from "styled-components";
import {colors} from "../../../style/colors";

const CheckmarkWrapper = styled(FlexRow)`
  height: 24px;
  width: 24px;
  border-radius: 4px;
  border: 1px solid ${colors.gray};
  margin-right: 14px;
  cursor: pointer;
`

interface CheckmarkProps {
    isClicked: boolean;
}

const Checkmark = styled.div<CheckmarkProps>`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background: ${({isClicked}) => isClicked ? colors.primary : "none"};
`

const Text = styled.p<{xl?: boolean}>`
  width: 260px;
  font-size: ${({xl}) => xl ? '16px' : '11px'};
  text-align: left;
`

export const CheckComponent: FC<{ isClicked, handleClick, text?: string, textXl?: boolean }> =
    ({
         isClicked,
         handleClick,
         text = 'auth.noUsCitizen',
         textXl = false
     }) => {

        const {t, i18n} = useTranslation();

        return (
            <FlexRow className={'checkbox'} style={{marginBottom: 10, marginTop: 5}}>
                <CheckmarkWrapper onClick={handleClick}>
                    <Checkmark isClicked={isClicked}/>
                </CheckmarkWrapper>
                <Text xl={textXl}>{t(text)}</Text>
            </FlexRow>
        );
    };
