import {ChartComponent} from "../../DashboardComponents/ChartComponent";
import React, {FC, useEffect, useState} from "react";
import styled from "styled-components";
import {FlexColumn} from "../../../style/projectComponents";
import {useMutation} from "react-query";
import {IDashboardItemsRequest, IDashboardItemsResponse, IUserDetailsResponse} from "../../../service/user-api/models";
import UserApi from "../../../service/user-api/UserApi";
import {DataEmptyComponent} from "../../global/DataEmptyComponent";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import {useTranslation} from "react-i18next";


const BalanceChartWrapper = styled(FlexColumn)`
  width: 100%;
  max-height: 380px;
  height: 100%;
  max-width: 1100px;
  justify-content: start;
  background: #fff;
  padding: 2rem;
  border-radius: 0.75rem;

`;

const ChartTitle = styled.p`
  font-size: 24px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-top: 18px;
`;

type IBalanceUserProps = {
    user: IUserDetailsResponse
}

export const BalanceUserDashboard: FC<IBalanceUserProps> = (props) => {
    const {user} = props;
    const getDate = (date: string) => format(new Date(date), 'dd.MM.yy', {locale: ru})


    const [balanceStats, setBalanceStats] = useState<IDashboardItemsResponse>({
        items: []
    });
    const {t, i18n} = useTranslation();

    const [uId, setUId] = useState(user.wallets.find(it => it.currency === 'USDT')?.id);
    const getBalanceDashboard = useMutation((data: IDashboardItemsRequest) => UserApi.getDashboardItems(data), {
        onSuccess: data => {
            setBalanceStats({
                items: data.items.map(it => ({
                    ...it,
                    date: getDate(it.date)
                }))
            });
        }
    });

    useEffect(() => {
        setUId(user.wallets.find(it => it.currency === 'USDT')?.id);
    }, [user]);

    useEffect(() => {
        getBalanceDashboard.mutate({
            walletId: uId,
            takeLast: 100
        }, {
            onSuccess: data => {
                console.log(data);
            }
        })
    }, [uId, user]);


    return (
        <BalanceChartWrapper>
            <ChartTitle>{t('adminUserDetail.graphBalance')}</ChartTitle>
            {uId && balanceStats?.items?.length > 0
                ? <ChartComponent
                    titleKey={['operationType', t('common.startingBalance'), t('adminUserDetail.setWithdrawalBalance')]}
                    valueKey={['balance', 'efficientBalance', 'withdrawalBalance']}
                    xAxisKey={'date'}
                    yAxisKey={'balance'}
                    variant={'linear'}
                    data={balanceStats.items}/>
                : <DataEmptyComponent isLoading={getBalanceDashboard.isLoading} isChart/>
            }
        </BalanceChartWrapper>
    );
};