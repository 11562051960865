import React, {createContext, useEffect, useState} from "react";
import {AuthContent, AuthModal, AuthTitle, FlexRow, Page, StyledLink} from "../style/projectComponents";
import HeaderComponent from "../components/global/HeaderComponent";
import FirstStep from "../components/auth/Register/FirstStep";
import {SecondStep} from "../components/auth/Register/SecondStep";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {IAuthSignupRequest} from "../service/auth-api/models";

interface RegistrationPageProps {
}

export enum STAGE {
    BASE = 'BASE',
    FINAL = 'FINAL'
}

const StyledPage = styled(Page)`
  background-position: center;
  background-size: cover;
  justify-content: start;

  .header {
    position: absolute;
  }
`

export const RegisterContext = createContext(null);
export const INITIAL_STATE_REG: IAuthSignupRequest = {
    birthDate: "",
    email: "",
    firstName: "",
    lastName: "",
    middleName: "",
    password: "",
    phoneNumber: ""
}
export const RegistrationPage: React.FC<RegistrationPageProps> = () => {

    const [stage, setStage] = useState(STAGE.BASE);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [formUser, setFormUser] = useState<IAuthSignupRequest>(INITIAL_STATE_REG);

    return (
        <StyledPage style={{background: "url(/images/bg.svg)", justifyContent: 'center'}}>
            <HeaderComponent/>
            <AuthContent style={{height: 'fit-content', alignSelf: 'center'}}>
                <AuthModal style={{marginTop: 100, marginBottom: 100}}>
                    <FlexRow style={{
                        marginBottom: 20,
                        position: 'relative',
                        alignItems: 'center',
                        width: '100%',
                        height: 'fit-content'
                    }}>
                        {stage === STAGE.FINAL &&
                            <img onClick={() => setStage(STAGE.BASE)}
                                 width={20}
                                 height={20}
                                 style={{cursor: 'pointer', position: 'absolute', left: 0, zIndex: 100}}
                                 src={'images/buttons/left-arrow.png'}/>}
                        <AuthTitle>
                            {t("auth.reg_title")}
                        </AuthTitle>
                    </FlexRow>

                    <RegisterContext.Provider value={{formUser, setFormUser}}>
                        {(stage === STAGE.BASE
                                ? <FirstStep setRegStage={setStage}/>
                                : <SecondStep/>
                        )}
                    </RegisterContext.Provider>


                    <p style={{marginBottom: 10, marginTop: 20, fontSize: 16}}>
                        {t("auth.already_has")}
                    </p>

                    <StyledLink to="/login">
                        {t("auth.login")}
                    </StyledLink>
                </AuthModal>
            </AuthContent>
        </StyledPage>
    )
        ;
};
