import axios from "axios";
import {ITokens} from "../service/auth-api/models";

const isLocatedToTest = window.location.host.toLowerCase().includes("localhost") || window.location.href.toLowerCase().includes("defence.investments")
export const SERVER_URL = isLocatedToTest ? "https://kanna.af-dev.ru" : "https://kanna.capital"

export const apiClient = axios.create({
    baseURL: SERVER_URL,
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        'Access-Control-Allow-Headers': '*',
    }
});

apiClient.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${JSON.parse(localStorage.accessToken)}`;
    return config;
});

apiClient.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && error.config && !error.config._isRetry) {
            originalRequest._isRetry = true;
            try {
                const response = await axios.post<ITokens>(`${SERVER_URL}/api/Auth/refreshToken`, {
                    accessToken: JSON.parse(localStorage.accessToken),
                    refreshToken: JSON.parse(localStorage.refreshToken),
                });
                localStorage.setItem('accessToken', JSON.stringify(response.data.accessToken));
                localStorage.setItem('refreshToken', JSON.stringify(response.data.refreshToken));
                return apiClient.request(originalRequest);
            } catch (e) {
                console.log('НЕ АВТОРИЗОВАН');
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('user');
                window.location.href = '/';
            }
        }
        throw error;
    })
