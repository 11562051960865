import HeaderComponent from "../../components/global/HeaderComponent";
import {FlexColumn, FlexRow} from "../../style/projectComponents";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {WithdrawAdminReqComponent} from "../../components/AdminComponents/WithdrawAdminReqComponent";
import {useMutation} from "react-query";
import WithdrawalApi from "../../service/withdrawal-api/WithdrawalApi";
import {IGetWithdrawalRequest, IGetWithdrawalResponse, IWithdrawalStatus} from "../../service/withdrawal-api/models";
import {DataEmptyComponent} from "../../components/global/DataEmptyComponent";
import {useTranslation} from "react-i18next";

const StyledBg = styled(FlexColumn)`
  width: 100vw;
  padding: 24px;
  height: 100%;
  margin-top: 110px;
  align-items: center;
`;

interface PageButtonProps {
    isSelected: boolean;
}

const PageButton = styled.button<PageButtonProps>`
  margin: 4px;
  margin-top: 20px;
  background: ${({isSelected}) => isSelected ? "#ecf1f7" : "none"};
  width: 36px;
  height: 36px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 100px;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;


const WithdrawReqsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: auto;
  gap: 2rem;
  align-items: start;

  transition: all 0.3s ease;

  @media (max-width: 1235px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 780px) and (max-width: 1100px) {
    gap: 1.49rem;
  }
`

export const AdminWithdraw = () => {
    const getWithdrawalQuery = useMutation((data: IGetWithdrawalRequest) => WithdrawalApi.getWithdrawal(data));
    const [withdrawals, setWithdrawals] = useState<IGetWithdrawalResponse>({
        items: [],
        totalCount: 0
    });

    const ITEMS_PER_PAGE = 10;
    const [skip, setSkip] = useState(0);
    const notApprovedStatuses = [IWithdrawalStatus.notApproved, IWithdrawalStatus.undefined]
    const [pages, setPages] = useState([]);
    const [maxPages, setMaxPages] = useState(0);
    const divideIntoPages = (start, itemsPerPage) => {
        const searchParams: IGetWithdrawalRequest = {
            statuses: notApprovedStatuses,
            skip: skip,
            take: ITEMS_PER_PAGE
        }
        getWithdrawalQuery.mutate(searchParams, {
            onSuccess: data => {
                setWithdrawals(() => data);
                setPages(() => Array.from(Array(Math.ceil(data.totalCount / ITEMS_PER_PAGE)), (_, i) => i + 1));
                setMaxPages(() => Math.ceil(data.totalCount / ITEMS_PER_PAGE));
            }
        });
    };

    useEffect(() => {
        divideIntoPages(skip, ITEMS_PER_PAGE);
    }, [skip]);

    const [pageId, setPageId] = useState(1);
    const {t, i18n} = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <FlexColumn style={{width: "100%"}}>
            <HeaderComponent/>
            <StyledBg>
                <Title>{t('adminWithdraw.withdrawRequests')}</Title>
                {withdrawals.items.length > 0
                    ? <WithdrawReqsWrapper>
                        {withdrawals.items.map((it, idx) =>
                            <WithdrawAdminReqComponent
                                withdrawals={withdrawals}
                                setWithdrawals={setWithdrawals}
                                {...it}/>
                        )}
                    </WithdrawReqsWrapper>
                    : <FlexColumn>
                        <DataEmptyComponent text={'adminWithdraw.transactionEmpty'}/>
                    </FlexColumn>
                }
                {pages.length > 1 && <FlexRow>
                    {pageId !== 1 &&
                        <PageButton
                            style={{marginRight: 30}}
                            onClick={() => {
                                setPageId(() => pageId - 1);
                                setSkip(() => ITEMS_PER_PAGE * (pageId - 1))
                            }}
                            isSelected={pageId === 1}>
                            {t("common.prev")}
                        </PageButton>
                    }
                    {pages
                        .filter((page, idx) => Math.abs(page - pageId) <= (
                            (pageId < 2 || pageId > maxPages - 2)
                                ? 3
                                : 2
                        ))
                        .map((page, idx) =>
                            <PageButton
                                isSelected={page === pageId}
                                onClick={() => {
                                    setPageId(() => page);
                                    setSkip(() => ITEMS_PER_PAGE * (page - 1))
                                }}>
                                {page}
                            </PageButton>
                        )}
                    {pageId < maxPages &&
                        <PageButton
                            onClick={() => {
                                setPageId(() => pageId + 1);
                                setSkip(() => ITEMS_PER_PAGE * (pageId - 1))
                            }}
                            isSelected={pageId === withdrawals.totalCount}>
                            {t("common.next")}
                        </PageButton>
                    }
                </FlexRow>
                }
            </StyledBg>
        </FlexColumn>
    );
};