import {
    IAproveRequest,
    IGetWithdrawalRequest, IGetWithdrawalResponse,
    IRejectRequest,
    IWithdrawalInterface,
    IWithdrawalRequest
} from "./models";
import {apiClient} from "../../config/serverConstants";

class WithdrawalService implements IWithdrawalInterface {
    async approveWithdrawal(data: IAproveRequest) {
        const response = await apiClient.post('/api/Withdrawal/approveWithdrawalRequest', data);
        return response.data as string;
    }

    async cancelWithdrawal(withdrawalId: string) {
        const response = await apiClient.post('/api/Withdrawal/cancelWithdrawalRequest', {
            withdrawalId: withdrawalId
        });
        return response.data as string;
    }

    async createWithdrawal(data: IWithdrawalRequest) {
        const response = await apiClient.post('/api/Withdrawal/createWithdrawalRequest', data);
        return response.data as string;
    }

    async getWithdrawal(data: IGetWithdrawalRequest) {
        const response = await apiClient.post('/api/Withdrawal/getWithdrawalRequests', data);
        return response.data as IGetWithdrawalResponse;
    }

    async rejectWithdrawal(data: IRejectRequest) {
        const response = await apiClient.post('/api/Withdrawal/rejectWithdrawalRequest', data);
        return response.data as string;
    }

}

export default new WithdrawalService();