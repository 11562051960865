import React, {useState} from "react";
import styled, {css} from "styled-components";
import {FlexColumnStart, FlexRow, FlexRowBetween} from "../../style/projectComponents";
import {colors} from "../../style/colors";
import {useTranslation} from "react-i18next";
import {OperationItem} from "./OperationItem";
import {useLocation} from "react-router-dom";


const Table = styled(FlexColumnStart)<{ scrollAllSizes?: boolean }>`
  width: 100%;
  margin-bottom: 40px;
  @media (min-width: 768px) {
    ${({scrollAllSizes}) => scrollAllSizes
            ? css`

            `
            : css`
              max-width: calc(100% - 50px);
            `}
  }
  ${({scrollAllSizes}) => scrollAllSizes
          ? css`
            position: relative;
            width: 100%;
            max-width: available;
            overflow: auto;
            padding: 0;
            margin: 0;
          `
          : css`
            @media (max-width: 768px) {
              position: relative;
              width: 100%;
              max-width: available;
              overflow: auto;
              padding: 0;
              margin: 0;
            }
          `}
}`;

const Block = styled(FlexRowBetween)<{ scrollAllSizes?: boolean }>`
  background: ${colors.pageBg};
  padding: 0 12px;
  height: 45px;
  align-items: center;
  border: 1px solid ${colors.pageBg};
  border-radius: 4px;

  ${({scrollAllSizes}) => scrollAllSizes
          ? css`
            width: auto;
            gap: 30px;
            min-width: 100%;
          `
          : css`
            width: 100%;
          `};

  @media (max-width: 768px) {
    width: auto;
    gap: 30px;
    min-width: 100%;
  }

  p {
    font-size: 16px;
    text-align: left;
    font-weight: 300;
    margin: 0;
    ${({scrollAllSizes}) => scrollAllSizes
            ? css`
              font-size: 14px;
            `
            : css`
              font-size: 16px;
            `};
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;

interface PageButtonProps {
    isSelected: boolean;
}

const PageButton = styled.button<PageButtonProps>`
  margin: 4px;
  margin-top: 20px;
  background: ${({isSelected}) => isSelected ? "#ecf1f7" : "none"};
  min-width: 36px;
  width: auto;
  height: 36px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 8px;
`;

const Content = styled(FlexColumnStart)<{ scrollAllSizes?: boolean }>`

  ${({scrollAllSizes}) => scrollAllSizes
          ? css`
            min-width: 100%;
            width: auto;
            `
          : css`
            width: 100%;
          `};
  
  @media (max-width: 768px) {
    //overflow: auto;
    min-width: 100%;
    width: auto;
  }
`;

const PageWrapper = styled(FlexRow)<{ scrollAllSizes?: boolean }>`

  ${({scrollAllSizes}) => scrollAllSizes
          ? css`
            align-self: center;
            width: 100%;
            position: sticky;
            bottom: 0;
            left: 0;
            `
          : css`
            
          `};
  
  @media (min-width: 768px) {
    align-self: center;
    width: 100%;
    position: sticky;
    bottom: 0;
    left: 0;
  }
`;

interface ChartComponentProps {
    scrollAllSizes?: boolean;
    operations: any;
    onClickItem?: (data: any) => void;
    keys?: any[];
    isReport?: boolean;
}

export const OperationsComponent: React.FC<ChartComponentProps> = (
    {
        operations,
        keys = [
            "dashboard.date",
            "dashboard.amount",
            "dashboard.operation",
            "status",
        ],
        onClickItem = () => {
        },
        scrollAllSizes,
        isReport
    }
) => {


    const {t} = useTranslation();


    const itemsPerPage = 10;
    const [pageId, setPageId] = useState(0);

    const divideIntoPages = (array, itemsPerPage) => {
        const pages = [];
        for (let i = 0; i < array.length; i += itemsPerPage) {
            const page = array.slice(i, i + itemsPerPage);
            pages.push(page);
        }
        if (pages.length < pageId) {
            setPageId(0);
        }
        return pages;
    };

    const [maxPages, setMaxPages] = useState(Math.ceil(operations?.length / itemsPerPage));

    const pages = operations && divideIntoPages(operations, itemsPerPage) || null;

    const [openErrorMessageId, setOpenErrorMessageId] = useState(null);
    const [openErrorMessage, setOpenErrorMessage] = useState(false);

    const location = useLocation();
    const pagesNum = Array.from(Array(maxPages), (_, i) => i + 1);

    return (
        <Table scrollAllSizes={scrollAllSizes}>
            <Content scrollAllSizes={scrollAllSizes}>
                <Block scrollAllSizes={scrollAllSizes}>
                    {keys.map((it, idx) =>
                        <p
                            key={it}
                            style={{
                                width: isReport ? 120 : 160,
                                opacity: 0.7,
                                textAlign: idx === keys.length - 1 ? 'right' : 'left',
                            }}>
                            {t(`common.${it}`)}
                        </p>
                    )}
                </Block>
                {pages && pages[pageId]?.map((operation, id) =>
                    <OperationItem
                        isReport={isReport}
                        style={{cursor: location.pathname === '/admin/analytics' && 'pointer'}}
                        keys={keys}
                        onClick={() => onClickItem(operation)}
                        openErrorMessage={openErrorMessage}
                        setOpenErrorMessage={setOpenErrorMessage}
                        setOpenErrorMessageId={setOpenErrorMessageId}
                        openErrorMessageId={openErrorMessageId}
                        key={id}
                        operation={operation}
                        id={id}/>
                )}

                {pagesNum.length > 1 && <PageWrapper scrollAllSizes={scrollAllSizes}>
                    {pageId !== 0 &&
                        <PageButton
                            style={{marginRight: 30}}
                            onClick={() => {
                                setPageId(() => pageId - 1);
                            }}
                            isSelected={pageId === 0}>
                            {t("common.prev")}
                        </PageButton>
                    }

                    {pagesNum
                        .filter((page, idx) => Math.abs(page - pageId) <= (
                            (pageId < 2 || pageId > maxPages - 2)
                                ? 3
                                : 2
                        ))
                        .map((page, id) =>
                            <PageButton
                                isSelected={page === pageId + 1}
                                onClick={() => {
                                    setPageId(() => page - 1);
                                    setOpenErrorMessageId(() => null);
                                    setOpenErrorMessage(() => false);
                                }}>
                                {page}
                            </PageButton>)}

                    {pageId < maxPages - 1 &&
                        <PageButton
                            onClick={() => {
                                setPageId(() => pageId + 1);
                            }}
                            isSelected={pageId === operations.length}>
                            {t("common.next")}
                        </PageButton>
                    }
                </PageWrapper>}
            </Content>
        </Table>
    );
};
