import {FlexColumnStart, FlexRow} from "../../../../style/projectComponents";
import {LeftCircle, RightCircle} from "./utils";
import React, {useRef} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useIsVisible} from "../../../../hooks/useIsVisible";

const Circle = styled.div`
  width: 9.375rem;
  height: 9.375rem;
  z-index: -10;
  position: absolute;
  left: 0;
  bottom: 0;

  @media (max-width: 1500px) {
    display: none;
  }
`

const Wrapper = styled(FlexRow)`
  position: relative;
  width: 100%;
  border-top: 0.0625rem solid #DEDDD8;
  margin: 0;
  padding: 0;

  @media (max-width: 480px) {
    border-bottom: none;
  }
`

const Content = styled(FlexRow)`
  border-left: 0.0625rem solid #DEDDD8;
  border-right: 0.0625rem solid #DEDDD8;
  width: 100%;
  max-width: 120rem;
  position: relative;
  box-sizing: border-box;
`

const Inner = styled(FlexRow)`
  z-index: 10;
  background: #FCFBF5;
  width: 100%;
  position: relative;
  max-width: 81.25rem;
  box-sizing: content-box;
  margin: 0 auto;
  height: 100%;
  border-left: 0.0625rem solid #DEDDD8;
  border-right: 0.0625rem solid #DEDDD8;
  align-items: center;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: start;
  }
`

const TextContainer = styled(FlexColumnStart)`
  width: 25.9375rem;
  text-align: start;
  background: #FCFBF5;
  height: 21.8rem;
  padding-left: 0.625rem;
  padding-right: 20px;


  h3 {
    font-size: 2.125rem;
    font-weight: 300;
    margin: 0;
    padding-bottom: 20px;
  }

  p {
    font-size: 1.125rem;
    font-weight: 300;
    max-width: 300px;
  }

  @media (max-width: 1000px) {
    padding: 2.5rem;
    border: none;
    height: auto;

    h3 {
      font-size: 1.9rem;
    }
  }
`

const AnotherTextContainer = styled(FlexColumnStart)<{ lng?: string }>`
  border-left: ${props => props.lng.toLowerCase() === 'ar' ? '' : '0.0625rem solid #DEDDD8'};
  border-right: ${props => props.lng.toLowerCase() === 'ar' ? '0.0625rem solid #DEDDD8' : ''};
  text-align: start;
  padding-left: ${props => props.lng.toLowerCase() === 'ar' ? '' : '7.5rem'};
  padding-right: ${props => props.lng.toLowerCase() === 'ar' ? '7.5rem' : ''};
  background: #FCFBF5;
  height: 21.8rem;
  max-width: 43rem;

  h3 {
    font-size: 1.5rem;
    font-weight: 300;
    margin: 0;
    padding-bottom: 20px;
  }

  p {
    font-size: 1rem;
    font-weight: 300;
  }

  @media (max-width: 1000px) {
    padding: 2.5rem;
    border: none;
    height: auto;
  }
`

const FigureContainer = styled.div`
  z-index: -10 !important;
  position: absolute;
  top: 0;
  max-height: 22.097625rem;
  height: 100%;
  width: 13.4375rem;
  padding-right: 4rem;
  left: 100%;

  //.area {
  //  margin-top: -10px;
  //  width: 100%;
  //  height: 0.0625rem;
  //  background-color: #DEDDD8;
  //}

  @media (max-width: 480px) {
    display: none;
  }
`
const StyledHr = styled.hr`
  position: absolute;
  top: 0;
  left: 33.375rem;
  width: 0.0625rem;
  height: 100%;
  margin: 0;
  border: none;
  background-color: #DEDDD8;

  @media (max-width: 1000px) {
    width: 100%;
    height: 0.0625rem;
    position: static;
  }
`

export const Second = () => {
    const {t, i18n} = useTranslation();

    const ref = useRef();
    const isVisible = useIsVisible(ref);

    return (
        <Wrapper>
            <Content>
                <Circle>
                    {LeftCircle}
                </Circle>
                <Inner>
                    <TextContainer>
                        <h3>
                            {t("landing.third.2.title")}
                        </h3>
                        <p>
                            {t("landing.third.2.subtitle")}
                        </p>
                    </TextContainer>
                    <AnotherTextContainer lng={i18n.language}>
                        <h3>
                            {t("landing.third.2.rightTitle")}
                        </h3>
                        <p>
                            {t("landing.third.2.rightSubtitle")}
                        </p>
                    </AnotherTextContainer>
                    <FigureContainer ref={ref} className={isVisible ? "element-show" : ""}>
                        {RightCircle}
                        <div className={'area'}></div>
                    </FigureContainer>
                </Inner>
            </Content>
        </Wrapper>
    );
};
